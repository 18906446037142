import { createSvgIcon } from "@mui/material";

const functionIcon = createSvgIcon(
  <path
    d="M10.42 2.29C9.32 2.19 8.35 3 8.25 4.11L8 7H10.82V9H7.82L7.38 14.07C7.18 16.27 5.24 17.9 3.04 17.7C1.79 17.59 0.66 16.9 0 15.83L1.5 14.33C1.83 15.38 2.96 15.97 4 15.63C4.78 15.39 5.33 14.7 5.4 13.89L5.82 9H2.82V7H6L6.27 3.93C6.46 1.73 8.39 0.1 10.6 0.28C11.86 0.39 13 1.09 13.66 2.17L12.16 3.67C11.91 2.9 11.23 2.36 10.42 2.29ZM20 10.65L18.59 9.24L15.76 12.07L12.93 9.24L11.5 10.65L14.35 13.5L11.5 16.31L12.93 17.72L15.76 14.89L18.59 17.72L20 16.31L17.17 13.5L20 10.65Z"
    fill="currentColor"
  />,
  "functionIcon"
);

export default functionIcon;
