import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import EditIcon from "@mui/icons-material/Edit";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { msg } from "../../messages";
import IdentifierIcon from "../icons/IdentifierIcon";
import useHandleCopy from "./menu/handlers/useHandleCopy";
import PropListItem from "./PropListItem";

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: "15px",
    marginBottom: "10px",
  },
}));

const TabContentGeneral = (props) => {
  const { item, type, dashboardId, groupId, widgetId } = props;
  const classes = useStyles({
    listItem: {
      minHeight: "56px",
    },
  });

  // group properties by groupName (array of properties -> array of groupNames)
  const grouping = (arr, key) =>
    (arr || []).reduce(
      (acc, x = {}) => ({ ...acc, [x[key]]: [...(acc[x[key]] || []), x] }),
      {}
    );

  const groups = grouping(item.objectProperties, "groupName");
  const groupNames = Object.keys(groups);

  const handleCopy = useHandleCopy();

  const linkedObject = (key) =>
    key === "valueValue" ? item.objectsToObjectsByObject1Id : "";

  return (
    <>
      <List>
        {groupNames.map((groupName) => {
          if (groupName !== "Value") return false;

          const headline = (
            <ListSubheader
              color="primary"
              key={groupName}
              className={classes.listSubheader}
            >
              <Typography variant="subtitle2">{groupName}</Typography>
            </ListSubheader>
          );

          return [
            headline,
            groups[groupName].map((item, index) => (
              <PropListItem
                linkedObjects={linkedObject(item.key)}
                data-test-value={groupName}
                tab="general"
                key={index}
                index={index}
                obj={props.item}
                item={item}
                dashboardId={dashboardId}
                widgetId={widgetId}
                groupId={groupId}
                type={type}
              />
            )),
          ];
        })}
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">
            {msg.sideCard.serviceInfo}
          </Typography>
        </ListSubheader>
        {item.schemaTags[3] === "device" && (
          <ListItem button onClick={() => {}}>
            <ListItemIcon>
              <SubtitlesIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1">{item.schema.name}</Typography>
              }
              onClick={(e) => {}}
            />
          </ListItem>
        )}
        {item.schemaTags[3] !== "device" && (
          <ListItem button onClick={() => {}}>
            <ListItemIcon>
              <SubtitlesIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Tooltip title={item.schemaTags.join(" / ")}>
                  <Typography variant="body1">{item.schema.name}</Typography>
                </Tooltip>
              }
            />
          </ListItem>
        )}
        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <IdentifierIcon />
          </ListItemIcon>
          <ListItemText
            onClick={(e) => {
              handleCopy({ object: item });
            }}
            primary={<Typography variant="body1">{item.id}</Typography>}
          />
        </ListItem>
        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <AlternateEmailIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">{item.name}</Typography>}
          />
        </ListItem>
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">{msg.sideCard.access}</Typography>
        </ListSubheader>
        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {item.userGroupByEditorgroup.groupName}
              </Typography>
            }
          />
        </ListItem>
        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <PlayCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {item.userGroupByUsergroup.groupName}
              </Typography>
            }
          />
        </ListItem>
        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <RemoveRedEyeIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {item.userGroupByReadergroup.groupName}
              </Typography>
            }
          />
        </ListItem>
        {item.description && (
          <React.Fragment>
            <ListSubheader color="primary" className={classes.listSubheader}>
              <Typography variant="subtitle2">
                {msg.sideCard.description}
              </Typography>
            </ListSubheader>
            <ListItem button onClick={() => {}}>
              <ListItemText
                primary={
                  <Typography variant="body1">{item.description}</Typography>
                }
              />
            </ListItem>
          </React.Fragment>
        )}
      </List>
    </>
  );
}; //TabContentGeneral

export default TabContentGeneral;
