import { format } from "date-fns";
import { getNotificationsHistory } from "../media-server";
import downloadBlob from "./downloadBlob";

const downloadNotifications = ([startDate, endDate], ids) => {
  const data = {
    ids,
    from: format(startDate, "yyyy-MM-dd HH:mm"),
    to: format(endDate, "yyyy-MM-dd HH:mm"),
  };

  return getNotificationsHistory(data).then((res) => {
    downloadBlob(
      res.data,
      `PixelBoard_Notifications_${ids}_${data.from}-${data.to}.csv`,
      res.data.type
    );
  });
};

export default downloadNotifications;
