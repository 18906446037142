import { useState } from "react";
import { ModalContext } from "./ModalContext";

export const ModalState = ({ children }) => {
  const [popups, openPopup] = useState([]);
  const [actions, setActions] = useState([]);

  const open = (popup) => {
    openPopup((a) => {
      return [...popups, popup];
    });
  };

  const close = () => {};

  const passActions = (action) => {
    setActions(() => {
      return [...actions, action];
    });
  };

  return (
    <ModalContext.Provider value={{ popups, open, passActions, actions }}>
      {children}
    </ModalContext.Provider>
  );
};
