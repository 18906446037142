import { createSvgIcon } from "@mui/material";

const LinkIcon = createSvgIcon(
  <path
    d="M10.59 13.4099C11 13.7999 11 14.4399 10.59 14.8299C10.2 15.2199 9.56001 15.2199 9.17001 14.8299C7.22001 12.8799 7.22001 9.70995 9.17001 7.75995L12.71 4.21995C14.66 2.26995 17.83 2.26995 19.78 4.21995C21.73 6.16995 21.73 9.33995 19.78 11.2899L18.29 12.7799C18.3 11.9599 18.17 11.1399 17.89 10.3599L18.36 9.87995C19.54 8.70995 19.54 6.80995 18.36 5.63995C17.19 4.45995 15.29 4.45995 14.12 5.63995L10.59 9.16995C9.41001 10.3399 9.41001 12.2399 10.59 13.4099ZM13.41 9.16995C13.8 8.77995 14.44 8.77995 14.83 9.16995C16.78 11.1199 16.78 14.2899 14.83 16.2399L11.29 19.7799C9.34001 21.7299 6.17001 21.7299 4.22001 19.7799C2.27001 17.8299 2.27001 14.6599 4.22001 12.7099L5.71001 11.2199C5.70001 12.0399 5.83001 12.8599 6.11001 13.6499L5.64001 14.1199C4.46001 15.2899 4.46001 17.1899 5.64001 18.3599C6.81001 19.5399 8.71001 19.5399 9.88001 18.3599L13.41 14.8299C14.59 13.6599 14.59 11.7599 13.41 10.5899C13 10.1999 13 9.55995 13.41 9.16995Z"
    fill="#686868"
  />,
  "LinkIcon"
);

export default LinkIcon;
