import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HistoryIcon from "@mui/icons-material/History";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ListItemSecondaryAction } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { useContext } from "react";
import { ModalContext } from "../../context/modal/ModalContext";
import ExecuteControl from "../modals/ExecuteControl";
import useMoreMenu from "../useMoreMenu";

const useStyles = makeStyles((theme) => ({
  itemToHideOrShow: {
    visibility: "hidden",
    opacity: 0,
    transition: "opacity 0.2s linear",
    [theme.breakpoints.down("sm")]: {
      visibility: "visible",
      opacity: 1,
    },
  },
  itemToHover: {
    "&:hover $itemToHideOrShow": {
      visibility: "visible",
      opacity: 1,
      transition: "opacity 0.2s linear",
    },
  },
}));

const TabControlItem = ({ control, controls, objId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const modal = useContext(ModalContext);

  const controlArguments = () => {
    return controls
      .filter((item) => item.rpc !== item.argument)
      .filter((item) => item.rpc === control.rpc);
  };

  const { MoreMenu, openMoreMenu, closeMoreMenu } = useMoreMenu();
  return (
    <>
      <MoreMenu
        items={[
          {
            icon: <EditIcon />,
            title: "Execute",
            id: "execute",
            disabled: false,
            handleAction: () => {
              ExecuteControl({
                control: control,
                controls: controlArguments(),
                objId: objId,
              })
                .then()
                .catch(() => {});
            },
          },
          {
            icon: <HistoryIcon />,
            title: "Show history",
            id: "show_history_controls",
            disabled: true,
            handleAction: () => {},
          },
        ]}
        handleMenuItemClick={() => {
          closeMoreMenu();
        }}
      />
      <ListItem
        alignItems="center"
        button
        key={control.id}
        style={{ height: "56px" }}
        classes={{
          container: classes.itemToHover,
        }}
      >
        <ListItemIcon>
          {control.ack === false && <AccessTimeIcon />}

          {control.ack === true && control.done === false && <RefreshIcon />}

          {control.ack === true &&
            control.done === true &&
            control.error === null && <CheckCircleOutlineIcon />}

          {control.ack === true &&
            control.done === true &&
            control.error === true && <ErrorOutlineIcon />}

          {control.ack === undefined &&
            control.done === undefined &&
            control.error === undefined && (
              <AccessTimeIcon style={{ color: theme.palette.wTransparent }} />
            )}
        </ListItemIcon>

        <ListItemText
          onClick={() => {}}
          primary={
            <Typography variant="body1">{control.description}</Typography>
          }
        />
        <ListItemSecondaryAction className={classes.itemToHideOrShow}>
          <IconButton edge="end" onClick={openMoreMenu} size="large">
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

export default TabControlItem;
