import { Toolbar } from "@mui/material";
import { styled } from "@mui/system";

export const Root = styled("div")(({ theme }) => ({
  position: "fixed",
  top: "0",
  background: "white",
  width: "411px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  paddingTop: "0",
  height: "100%",
  zIndex: "3",
  display: "flex",
  flexDirection: "column",
}));

export const Content = styled("div")({
  height: "100%",
  overflowY: "auto",
});

export const ItemToHideOrShow = styled("div")(({ theme }) => ({
  visibility: "hidden",
  opacity: 0,
  transition: "opacity 0.2s linear",
  [theme.breakpoints.down("sm")]: {
    visibility: "visible",
    opacity: 1,
  },
}));

export const ItemToHover = styled("div")(() => ({
  ":hover .item-to-hide": {
    visibility: "visible",
    opacity: 1,
    transition: "opacity 0.2s linear",
  },
}));

export const Header = styled(Toolbar)({
  paddingRight: "4px",
  paddingLeft: "0",
  paddingTop: "4px",
  paddingBottom: "4px",
  marginLeft: "8px",
});
