import { Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { create } from "react-modal-promise";
import { msg } from "../../../messages";
import CommonModal from "../../CommonModal";
import CustomInput from "../../CustomInput";
import FormField from "../../FormField";

const EditFunction = (props) => {
  const [loading, setLoading] = useState(false);
  const [parametersFunction, setParametersFunction] = useState(
    props.properties.parametersFunction.value || null
  );
  const [parametersFunctionUnits, setParametersFunctionUnits] = useState(
    props.properties.parametersFunctionUnits?.value || null
  );

  const submit = () => props.onResolve();

  const reject = () => props.onReject();

  const handleSave = async () => {
    setLoading(true);
    const values = [
      {
        propertyKey: "parametersFunction",
        value: parametersFunction,
      },
      {
        propertyKey: "parametersFunctionUnits",
        value: parametersFunctionUnits,
      },
    ];

    props.handleSave(values).finally(() => {
      setLoading(false);
      submit();
    });
  };

  return (
    <CommonModal
      key="EditFunction"
      modalOpen={props.isOpen}
      title="Edit function"
      handleClose={reject}
      buttons={
        <>
          <Button onClick={reject}>{msg.default.cancel}</Button>
          <Button color="primary" disabled={loading} onClick={handleSave}>
            {loading ? <CircularProgress size={23} /> : msg.default.save}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormField
            values={{
              [props.properties.parametersFunction.spec.key]:
                parametersFunction,
            }}
            field={props.properties.parametersFunction.spec}
            handleInputChange={(e) => {
              setParametersFunction(e.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <CustomInput
            name="units"
            label="Units"
            clearFieldIcon={true}
            value={parametersFunctionUnits}
            onChange={(e) => {
              setParametersFunctionUnits(e.target.value);
            }}
            type="string"
          />
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default create(EditFunction);
