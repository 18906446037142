const GridHighlight = ({ isMinimap }: { isMinimap: boolean }) => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundImage: isMinimap
            ? "url('/gridPattern.svg')"
            : "url('/gridPatternGroup.svg')",
          backgroundRepeat: "repeat",
          opacity: 0.5,
          backgroundSize: isMinimap ? "25px" : "50px",
        }}
      ></div>
    </>
  );
};

export default GridHighlight;
