import { useLazyQuery } from "@apollo/client";
import AppsIcon from "@mui/icons-material/Apps";
import {
  ButtonBase,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Fab from "@mui/material/Fab";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/system";
import jwt_decode from "jwt-decode";
import { Fragment, useRef, useState } from "react";
import { ReactComponent as ImgAdmin } from "../../assets/icon_admin.svg";
import { ReactComponent as ImgBoard } from "../../assets/icon_board.svg";
import { ReactComponent as ImgMonitor } from "../../assets/icon_monitor.svg";
import { gql } from "../../__generated__";

const APPS_QUERY = gql(/*GraphQL*/ `
    query userApps($id: UUID!) {
        user(id: $id) {
            userProfiles(filter: { object: { id: { isNull: false }  } }) {
                object {
                    id
                    enabled
                    name: property(propertyName:"Program/Name")
                    icon: property(propertyName:"Program/Icon")
                    url: property(propertyName:"Program/URL")
                }
            }
        }
    }
`);

const AppsModal = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLInputElement>(null);

  const tokenDecoded: {
    user_id?: string;
  } = localStorage.getItem("authToken")
    ? jwt_decode(localStorage.getItem("authToken"))
    : null;

  const [load, { data, loading }] = useLazyQuery(APPS_QUERY, {
    variables: {
      id: tokenDecoded?.user_id,
    },
    fetchPolicy: "network-only",
  });

  const theme = useTheme();

  const handleClose = ({ target }: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(target as Node)) {
      return;
    }

    setOpen(false);
  };

  const handleOpen = () => {
    if (!open) {
      load()
        .then(() => {
          setOpen(true);
        })
        .catch(() => {});
    }
  };

  const handleClick = (url) => {
    setOpen(false);
    window.open(
      `${url}/from-app?token=${localStorage.getItem(
        "refreshToken"
      )}&tokenId=${localStorage.getItem("tokenId")}`,
      "_blank"
    );
  };

  const icons = {
    PixelAdmin: <ImgAdmin />,
    PixelBoard: <ImgBoard />,
    PixelMonitor: <ImgMonitor />,
  };

  return (
    <Fragment>
      <Fab
        data-test-app-switcher
        ref={anchorRef}
        onClick={handleOpen}
        size="small"
        style={{
          marginLeft: "16px",
          backgroundColor: theme.palette.background.default,
        }}
        aria-label="apps"
      >
        {loading && <CircularProgress size={25} />}
        {!loading && <AppsIcon />}
      </Fab>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 8],
            },
          },
        ]}
        placement="bottom-end"
        disablePortal={true}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper elevation={8}>
              <ClickAwayListener onClickAway={handleClose}>
                <div
                  style={{
                    width: "412px",
                    display: "grid",
                    gap: "12px",
                    padding: "12px 0",
                    gridTemplateColumns: "1fr 1fr 1fr",
                  }}
                >
                  {data?.user.userProfiles.map(({ object }) => {
                    return (
                      <ButtonBase
                        data-test-app-switch={object.name}
                        key={object.id}
                        sx={{
                          opacity: object.enabled ? "1" : "0.5",
                        }}
                        disabled={!object.enabled}
                        focusRipple
                        onClick={() => handleClick(object.url)}
                      >
                        <span>
                          {icons[object.name]}
                          <Typography variant="body1">{object.name}</Typography>
                        </span>
                      </ButtonBase>
                    );
                  })}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default AppsModal;
