import TodayIcon from "@mui/icons-material/Today";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { compareAsc, format, parseISO, sub } from "date-fns";
import { useEffect, useState } from "react";
import { onChangeDatePicker, YYYYMMdd } from "../../modules/reports/components/isValidDate";

const DateRangePicker = (props) => {
  const { helperText } = props;
  const [startDateHelperText, endDateHelperText] = helperText?.value || helperText || ["", ""];
  const theme = useTheme();

  const formatStart = props.formatStart || YYYYMMdd;
  const formatEnd = props.formatEnd || YYYYMMdd;

  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);

  const [startDate, setStartDate] = useState(sub(Date.now(), { weeks: 1 }));
  const [endDate, setEndDate] = useState(Date.now());

  const handleSave = () => {
    if (!startDate && !endDate) {
      props.onChange([YYYYMMdd, YYYYMMdd]);
    } else if (startDate && endDate) {
      props.onChange([format(startDate, formatStart), format(endDate, formatEnd)]);
    } else if (startDate && !endDate) {
      props.onChange([format(startDate, formatStart), YYYYMMdd]);
    } else if (!startDate && endDate) {
      props.onChange([YYYYMMdd, format(endDate, formatEnd)]);
    }
  };

  useEffect(() => {
    if (props?.selected && props.selected?.length === 2) {
      setStartDate(parseISO(props.selected[0]));

      setEndDate(parseISO(props.selected[1]));
    } else {
      handleSave();
    }
  }, []);

  useEffect(() => {
    handleSave();

    if (startDate && endDate) {
      if (compareAsc(startDate, endDate) === 1) {
        setEndDate(null);
      }
    }
  }, [startDate, endDate]);

  return (
    <>
      <Grid item>
        <div style={{ display: "flex" }}>
          <div style={{ padding: "16px 16px 16px 0px" }}>
            <TodayIcon style={{ color: theme.palette.gray1 }} />
          </div>
          <DatePicker
            disableOpenPicker
            open={openStart}
            onOpen={() => setOpenStart(true)}
            onClose={() => setOpenStart(false)}
            slotProps={{
              textField: {
                helperText: startDateHelperText,
              },
            }}
            renderInput={(props) => {
              return (
                <TextField
                  {...props}
                  InputProps={{
                    ...props.inputProps,
                    readOnly: true,
                  }}
                  onClick={() => setOpenStart(true)}
                  style={{ width: "100%" }}
                  variant="standard"
                  label="From"
                  readOnly
                  helperText={startDateHelperText}
                />
              );
            }}
            disableFuture={true}
            inputFormat="dd-MM-yyyy"
            variant="inline"
            value={startDate}
            onChange={(date) => {
              onChangeDatePicker(date, setStartDate);
            }}
          />
        </div>
      </Grid>
      <Grid item>
        <div style={{ display: "flex" }}>
          <div style={{ padding: "16px 16px 16px 0px" }}>
            <TodayIcon style={{ color: theme.palette.gray1 }} />
          </div>
          <DatePicker
            disableFuture={true}
            disableOpenPicker
            open={openEnd}
            onOpen={() => setOpenEnd(true)}
            onClose={() => setOpenEnd(false)}
            slotProps={{
              textField: {
                helperText: endDateHelperText,
              },
            }}
            renderInput={(props) => (
              <TextField
                onClick={() => setOpenEnd(true)}
                style={{ width: "100%" }}
                {...props}
                InputProps={{
                  ...props.inputProps,
                  readOnly: true,
                }}
                variant="standard"
                label="To"
                helperText={endDateHelperText}
              />
            )}
            inputFormat="dd-MM-yyyy"
            variant="inline"
            value={endDate}
            onChange={(date) => {
              onChangeDatePicker(date, setEndDate);
            }}
          />
        </div>
      </Grid>
    </>
  );
};

export default DateRangePicker;
