import React, { useContext } from "react";
import { ModalContext } from "../../context/modal/ModalContext";

export const ModalRoot = () => {
  const { popups } = useContext(ModalContext);

  return popups.map((component, index) =>
    React.cloneElement(component, { key: index })
  );
};
