import { FormControl } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import lightOrDark from "../../utils/lightOrDark";

const useStyles = makeStyles((theme) => ({
  icon: {
    right: 30,
  },
  selectSelect: {
    paddingLeft: "0px",
  },
}));

const SelectColor = (props) => {
  const classes = useStyles();
  const [bgColor, setBgColor] = useState(props.value || "#ffffff");
  const [color, setColor] = useState("#333333");
  const handleInputChangeCasted = (event) => {
    let { value } = event.target;
    setBgColor(value);
    props.onChange(event);
  };

  useEffect(() => {
    if (props.value) {
      setBgColor(props.value);
      setColor(computeColor(props.value));
    }
  }, [props.value]);

  const computeColor = (color) => {
    if (!color || color === "transparent") return "#000";
    if (lightOrDark(color) === "light") {
      return "#000";
    } else {
      return "#fff";
    }
  };

  return (
    <>
      <FormControl fullWidth className={classes.root} variant={"standard"}>
        <InputLabel id={`${props.name}-label`}>{props.label}</InputLabel>
        <Select
          data-test-select-color
          style={{ backgroundColor: bgColor, color: color }}
          name={props.name}
          value={bgColor}
          labelId={`${props.name}-label`}
          label={props.label}
          disabled={Boolean(props.disabled)}
          onChange={(e) => {
            handleInputChangeCasted(e, props.propType);
            setColor(computeColor(e.target.value));
          }}
        >
          {props.list.map((item, index) => (
            <MenuItem
              style={{
                height: "48px",
                backgroundColor: item.value,
                color: computeColor(item.value),
              }}
              data-test-select-color-value={item.title}
              value={item.value}
              {...(props.autoKey ? { key: index } : { key: item.value })}
            >
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectColor;
