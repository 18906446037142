import TodayIcon from "@mui/icons-material/Today";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { onChangeDatePicker } from "../../modules/reports/components/isValidDate";

const DatePickerSingle = (props) => {
  const theme = useTheme();

  const formatStart = props.formatStart || "yyyy-MM-dd";

  const [helperText] = props.helperText || [""];

  const [openStart, setOpenStart] = useState(false);
  const [startDate, setStartDate] = useState(Date.now);

  const handleSave = () => {
    if (!startDate) {
      props.onChange(["yyyy-MM-dd"]);
    } else {
      props.onChange([format(startDate, formatStart), format(startDate, formatStart)]);
    }
  };

  useEffect(() => {
    if (props?.selected && props.selected?.length === 2) {
      setStartDate(parseISO(props.selected[0]));
    } else {
      handleSave();
    }
  }, []);

  useEffect(() => {
    handleSave();
  }, [startDate]);

  return (
    <>
      <Grid item>
        <div style={{ display: "flex" }}>
          <div style={{ padding: "16px 16px 16px 0px" }}>
            <TodayIcon style={{ color: theme.palette.gray1 }} />
          </div>
          <DatePicker
            disableOpenPicker
            open={openStart}
            onOpen={() => setOpenStart(true)}
            onClose={() => setOpenStart(false)}
            slotProps={{
              textField: {
                helperText,
              },
            }}
            renderInput={(props) => (
              <TextField
                {...props}
                InputProps={{
                  ...props.inputProps,
                  readOnly: true,
                }}
                onClick={() => setOpenStart(true)}
                style={{ width: "100%" }}
                variant="standard"
                label="Date"
                helperText={helperText}
              />
            )}
            disableFuture={true}
            inputFormat="dd-MM-yyyy"
            variant="inline"
            value={startDate}
            onChange={(date) => {
              onChangeDatePicker(date, setStartDate);
            }}
          />
        </div>
      </Grid>
    </>
  );
};

export default DatePickerSingle;
