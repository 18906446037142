import { GridOff, GridOn, Save } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SettingsOverscanIcon from "@mui/icons-material/SettingsOverscan";
import Fab from "@mui/material/Fab";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useRoute from "../../hooks/useRoute";
import UpdateReport from "../../modules/reports/components/UpdateReport";
import { setSettings } from "../../store/settingsSlice";
import AddGroupModal from "../AddGroupModal";
import ReloadIcon from "../icons/reloadIcon";
import SplitView from "../icons/splitView";
import ConfirmExitEditMode from "../modals/ConfirmExitEditMode";

const useStyles = makeStyles((theme) => ({
  toolbarButtons: {
    marginLeft: "auto",
  },
  screenModeButton: {
    marginRight: "16px",
    color: theme.palette.white,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  iconFullScreen: {
    height: "35px",
    width: "auto",
  },
}));

const DashboardControls = ({ saveBoardCb }) => {
  const { isReport } = useRoute();
  const params = useParams();
  const classes = useStyles();
  const isFullScreen = useSelector((state) => state.settings.isFullScreen);
  const isMayEdit = useSelector((state) => state.settings.isMayEdit);
  const isEditMode = useSelector((state) => state.settings.isEditMode);
  const isBoardSaved = useSelector((state) => state.settings.isBoardSaved);
  const isShowGrid = useSelector((state) => state.settings.isShowGrid);

  const dispatch = useDispatch();

  return (
    <div
      style={{ position: "fixed", bottom: "12px", right: "8px", zIndex: 1 }}
      data-test-is-edit={isEditMode}
    >
      {/*Add container*/}
      {isEditMode && (
        <Fab
          data-test-board-control="addGroup"
          className={classes.screenModeButton}
          color="primary"
          onClick={(e) => {
            saveBoardCb(e, true);
            AddGroupModal()
              .then()
              .catch(() => {});
          }}
        >
          <AddIcon />
        </Fab>
      )}

      {/*Grid on/off*/}
      {isEditMode && (
        <Fab
          data-test-board-control="gridToggle"
          className={classes.screenModeButton}
          color="primary"
          onClick={() => {
            dispatch(setSettings({ isShowGrid: !isShowGrid }));
          }}
        >
          {!isShowGrid && <GridOn />}
          {isShowGrid && <GridOff />}
        </Fab>
      )}

      {/*Save*/}
      {isEditMode && (
        <Fab
          data-test-board-control="saveBoard"
          className={classes.screenModeButton}
          color="primary"
          onClick={saveBoardCb}
        >
          <Save />
        </Fab>
      )}

      {/*Exit from edit mode*/}
      {isEditMode && (
        <Fab
          data-test-board-control="exitEditMode"
          className={classes.screenModeButton}
          color="primary"
          onClick={() => {
            if (isBoardSaved) {
              dispatch(setSettings({ isEditMode: false }));
            } else {
              ConfirmExitEditMode({
                handleSave: saveBoardCb,
              })
                .then()
                .catch(() => {});
            }
          }}
        >
          <RemoveRedEyeIcon />
        </Fab>
      )}

      {/* Update reports */}
      {!isEditMode && isReport() && (
        <Fab
          data-test-board-control="reloadReport"
          className={classes.screenModeButton}
          color="primary"
          onClick={() => {
            UpdateReport({
              reportId: params.reportId,
            })
              .then()
              .catch(() => {});
          }}
        >
          <ReloadIcon />
        </Fab>
      )}
      {/*Enter to edit mode */}
      {!isEditMode && isMayEdit && (
        <Fab
          data-test-board-control="enterEditMode"
          className={classes.screenModeButton}
          color="primary"
          onClick={() => {
            window.getSelection().removeAllRanges(); // deselect everything that was selected occasionally
            dispatch(setSettings({ isEditMode: true }));
          }}
        >
          <EditIcon />
        </Fab>
      )}

      {/* Enter to fullsreen */}
      {!isFullScreen && (
        <Fab
          data-test-board-control="enterFullscreen"
          className={classes.screenModeButton}
          color="primary"
          onClick={() => {
            dispatch(setSettings({ isSideBar: false }));
            dispatch(setSettings({ isFullScreen: true }));
          }}
        >
          <SettingsOverscanIcon />
        </Fab>
      )}
      {/*Exit fullscreen*/}
      {isFullScreen && (
        <Fab
          data-test-board-control="exitFullscreen"
          className={classes.screenModeButton}
          color="primary"
          onClick={() => {
            dispatch(setSettings({ isSideBar: true }));
            dispatch(setSettings({ isFullScreen: false }));
          }}
        >
          <SplitView />
        </Fab>
      )}
    </div>
  );
};

export default DashboardControls;
