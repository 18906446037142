function structuralClone<T>(obj: T) {
  return new Promise((resolve) => {
    const { port1, port2 } = new MessageChannel();
    port2.onmessage = (ev) => resolve(ev.data);
    port1.postMessage(obj);
  });
}

export function cloneObject<T>(object: T) {
  if (window.structuredClone) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return window.structuredClone(object);
  } else {
    return structuralClone(object);
  }
}
