import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import HistoryProperty from "./history/HistoryProperty";
import PropListItem from "./PropListItem";

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: "15px",
    marginBottom: "10px",
  },
}));

const TabContentProperties = (props) => {
  const { item, type, handleMenuActionClick, dashboardId, groupId, widgetId } =
    props;

  const classes = useStyles();
  const isShowHistoryProperty = useSelector(
    (state) => state.settings.isShowHistoryProperty
  );

  const [idProperty, setIdProperty] = useState(null);

  // group properties by groupName (array of properties -> array of groupNames)
  const grouping = (arr, key) =>
    (arr || []).reduce(
      (acc, x = {}) => ({ ...acc, [x[key]]: [...(acc[x[key]] || []), x] }),
      {}
    );

  const groups = grouping(item.objectProperties, "groupName");
  const groupNames = Object.keys(groups);

  return (
    <>
      {isShowHistoryProperty && (
        <HistoryProperty id={idProperty} object={props.item} />
      )}
      <List className={classes.root}>
        {groupNames.map((groupName) => {
          //hide group "Value" and any group without non-hidden properties
          if (
            groupName === "Value" ||
            groups[groupName].filter((item) => !item.spec?.hidden).length === 0
          )
            return false;
          const headline = (
            <ListSubheader
              color="primary"
              key={groupName}
              className={classes.listSubheader}
            >
              <Typography variant="subtitle2">{groupName}</Typography>
            </ListSubheader>
          );

          return [
            headline,
            groups[groupName].map((item, index) => (
              <React.Fragment key={index}>
                {!item.spec?.hidden && (
                  <PropListItem
                    tab="properties"
                    index={index}
                    obj={props.item}
                    item={item}
                    dashboardId={dashboardId}
                    widgetId={widgetId}
                    groupId={groupId}
                    type={type}
                  />
                )}
              </React.Fragment>
            )),
          ];
        })}
      </List>
    </>
  );
};

export default TabContentProperties;
