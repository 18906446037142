import { gql } from "@apollo/client";
import { ColorLens, LinkOff, Notes } from "@mui/icons-material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import EditIcon from "@mui/icons-material/Edit";
import ExtensionIcon from "@mui/icons-material/Extension";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { msg } from "../../../messages";
import IdentifierIcon from "../../icons/IdentifierIcon";
import LabelIcon from "../../icons/labelIcon";
import LinkIcon from "../../icons/LinkIcon";
import useHandleCopy from "../menu/handlers/useHandleCopy";
const CREATE_EXECUTION = gql`
  mutation createControlsExecution($input: CreateControlExecutionInput!) {
    createControlExecution(input: $input) {
      clientMutationId
    }
  }
`;

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: "15px",
    marginBottom: "10px",
  },
  itemToHideOrShow: {
    visibility: "hidden",
    opacity: 1,
    transition: "opacity 0.2s linear",
    [theme.breakpoints.down("sm")]: {
      visibility: "visible",
      opacity: 1,
    },
  },
  itemToHover: {
    "&:hover $itemToHideOrShow": {
      visibility: "visible",
      opacity: 1,
      transition: "opacity 0.2s linear",
    },
  },
}));

const ColorChartGeneralTab = (props) => {
  const { item, type, dashboardId, groupId, widgetId } = props;

  const classes = useStyles({
    listItem: {
      minHeight: "56px",
    },
  });

  const getPropertyByKey = (key) => {
    return item.objectProperties.find((item) => item.key === key);
  };
  // Chart 1
  const chart1Property = () => getPropertyByKey("chart1Property");
  const chart1Source = () => getPropertyByKey("chart1Source");
  const chart1ReadyTimeseries = () =>
    getPropertyByKey("chart1UseReady-MadeTimeseries");
  const styleChart1Color = () => getPropertyByKey("styleChart1Color");
  const styleChart1Label = () => getPropertyByKey("styleChart1Label");

  // Chart 2
  const chart2Property = () => getPropertyByKey("chart2Property");
  const chart2Source = () => getPropertyByKey("chart2Source");
  const chart2ReadyTimeseries = () =>
    getPropertyByKey("chart2UseReady-MadeTimeseries");
  const styleChart2Color = () => getPropertyByKey("styleChart2Color");
  const styleChart2Label = () => getPropertyByKey("styleChart2Label");

  // Chart 3
  const chart3Property = () => getPropertyByKey("chart3Property");
  const chart3Source = () => getPropertyByKey("chart3Source");
  const chart3ReadyTimeseries = () =>
    getPropertyByKey("chart3UseReady-MadeTimeseries");
  const styleChart3Color = () => getPropertyByKey("styleChart3Color");
  const styleChart3Label = () => getPropertyByKey("styleChart3Label");

  const handleCopy = useHandleCopy();

  return (
    <>
      <List>
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Chart 1</Typography>
        </ListSubheader>

        {/*Chart 1*/}

        {!chart1ReadyTimeseries().value && (
          <>
            <ListItem style={{ height: "48px" }}>
              <ListItemIcon>
                {chart1Source().value.value === null && <LinkOff />}
                {chart1Source().value.value !== null && <ExtensionIcon />}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    noWrap
                    title={chart1Source().value.title}
                    variant="body1"
                  >
                    {chart1Source().value.title}
                  </Typography>
                }
              />
            </ListItem>

            <ListItem style={{ height: "48px" }}>
              <ListItemIcon>
                <LabelIcon></LabelIcon>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography noWrap variant="body1">
                    {chart1Property().value.title}
                  </Typography>
                }
              />
            </ListItem>
          </>
        )}

        {chart1ReadyTimeseries().value && (
          <ListItem style={{ height: "48px" }}>
            <ListItemIcon>
              {chart1Source().value.value === null && <LinkOff />}
              {chart1Source().value.value !== null && <LinkIcon />}
            </ListItemIcon>
            <ListItemText
              noWrap
              title={chart1Source().value.title}
              primary={
                <Typography variant="body1">
                  {chart1Source().value.title}
                </Typography>
              }
            />
          </ListItem>
        )}

        <ListItem style={{ height: "48px" }}>
          <ListItemIcon>
            <ColorLens></ColorLens>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {styleChart1Color().value}
              </Typography>
            }
          />
        </ListItem>

        <ListItem style={{ height: "48px", paddingRight: "96px" }}>
          <ListItemIcon>
            <Notes></Notes>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {styleChart1Label().value || "n/a"}
              </Typography>
            }
          />
        </ListItem>
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">
            {msg.sideCard.serviceInfo}
          </Typography>
        </ListSubheader>

        {item.schemaTags[3] === "device" && (
          <ListItem button onClick={() => {}}>
            <ListItemIcon>
              <SubtitlesIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1">{item.schema.name}</Typography>
              }
              onClick={(e) => {}}
            />
          </ListItem>
        )}

        {item.schemaTags[3] !== "device" && (
          <ListItem button onClick={() => {}}>
            <ListItemIcon>
              <SubtitlesIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Tooltip title={item.schemaTags.join(" / ")}>
                  <Typography variant="body1">{item.schema.name}</Typography>
                </Tooltip>
              }
            />
          </ListItem>
        )}

        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <IdentifierIcon />
          </ListItemIcon>
          <ListItemText
            onClick={(e) => {
              handleCopy({ object: item });
            }}
            primary={<Typography variant="body1">{item.id}</Typography>}
          />
        </ListItem>
        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <AlternateEmailIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">{item.name}</Typography>}
          />
        </ListItem>

        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">{msg.sideCard.access}</Typography>
        </ListSubheader>
        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {item.userGroupByEditorgroup.groupName}
              </Typography>
            }
          />
        </ListItem>

        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <PlayCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {item.userGroupByUsergroup.groupName}
              </Typography>
            }
          />
        </ListItem>

        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <RemoveRedEyeIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {item.userGroupByReadergroup.groupName}
              </Typography>
            }
          />
        </ListItem>

        {item.description && (
          <React.Fragment>
            <ListSubheader color="primary" className={classes.listSubheader}>
              <Typography variant="subtitle2">
                {msg.sideCard.description}
              </Typography>
            </ListSubheader>
            <ListItem button onClick={() => {}}>
              <ListItemText
                primary={
                  <Typography variant="body1">{item.description}</Typography>
                }
              />
            </ListItem>
          </React.Fragment>
        )}
      </List>
    </>
  );
}; //TabContentGeneral

export default ColorChartGeneralTab;
