import { gql, useSubscription } from "@apollo/client";
import { Button, circularProgressClasses } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { create } from "react-modal-promise";
import { useBeforeUnload } from "react-use";
import ProgressLoader from "../common/ProgressLoader";
import CommonModal from "../CommonModal";

const RPC_SUBSCRIBE = gql`
  subscription RpcSubscribe($objectId: UUID!) {
    ControlExecutions(filterA: { object_id: [$objectId], type: REPORT }) {
      relatedNode {
        ... on ControlExecution {
          linkedControlId
          createdAt
          done
          error
          name
          params
          id
        }
      }
    }
  }
`;

const RpcProcessModal = (props) => {
  const submit = () => props.onResolve();
  const [loaderType, setLoaderType] = useState("determinate");
  const [progress, setProgress] = useState(0);
  const [isError, setError] = useState(false);
  const [action, setAction] = useState("Process started...");
  const [canClose, setCanClose] = useState(true);
  const [controlExecutionId, setControlExectionId] = useState(null);

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return false;
    } else {
      submit();
    }
  };

  useBeforeUnload(canClose, "You have not finished RPC, are you sure?");

  useSubscription(RPC_SUBSCRIBE, {
    variables: { objectId: props.objectId },
    onSubscriptionData: ({ subscriptionData }) => {
      const latestReport = subscriptionData.data.ControlExecutions.relatedNode;

      if (+latestReport.linkedControlId === controlExecutionId) {
        if (latestReport && latestReport.params) {
          if (latestReport.params.progress !== undefined) {
            try {
              const progress = latestReport.params.progress || 0;
              const action = latestReport.params.action || "";

              setProgress(progress);

              if (action) {
                setAction(action);
              }
            } catch {
              setProgress(+latestReport.params.match(/([0-9]+)/)[0]);
            }
          } else {
            setLoaderType("indeterminate");
          }
        }

        if (latestReport.done && latestReport.error !== "true") {
          setCanClose(false);

          setTimeout(() => {
            if (props.successCb) {
              try {
                const params = latestReport.params;

                if (params?.action.includes("dashboardId")) {
                  props.successCb(params.action.split("_")[1]);
                } else if (params?.action.includes("reportId")) {
                  props.successCb(params.action.split("_")[1]);
                } else if (params?.action.includes("groupId")) {
                  props.successCb(params.action.split("_")[1]);
                } else {
                  props.successCb();
                }
              } catch (e) {
                props.successCb();
              }
            }

            handleClose();
          }, 1000);
        }
      } else if (latestReport.done && latestReport.error === "true") {
        setError(true);
        setCanClose(false);
        const params = latestReport.params;

        if (params?.action.includes("dashboardId")) {
          props.successCb(params.action.split("_")[1]);
        } else if (params?.action.includes("reportId")) {
          props.successCb(params.action.split("_")[1]);
        } else if (params?.action.includes("groupId")) {
          props.successCb(params.action.split("_")[1]);
        } else {
          props.successCb();
        }
      }
    },
  });

  useEffect(() => {
    const timer = setTimeout(async () => {
      await props.callRpc().then(({ data }) => {
        if (data.createControlExecution.controlExecution.id) {
          setControlExectionId(data.createControlExecution.controlExecution.id);
        }
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [props.objectId]);
  return (
    <CommonModal
      width={"288px"}
      key="RpcProcessModal"
      modalOpen={props.isOpen}
      forceTitle={false}
      handleClose={handleClose}
      buttons={
        <>
          <Button
            fullWidth={true}
            color="error"
            variant={"outlined"}
            onClick={handleClose}
          >
            Ok
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Grid item style={{ textAlign: "center" }}>
            {loaderType === "determinate" && (
              <ProgressLoader
                progress={progress}
                size={120}
                color={isError ? "error" : "primary"}
                variant="determinate"
              />
            )}

            {loaderType !== "determinate" && (
              <CircularProgress
                variant="indeterminate"
                size={120}
                thickness={4}
                color={isError ? "warning" : "primary"}
                sx={{
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: "round",
                  },
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Typography
            variant="caption"
            style={{
              wordBreak: "break-word",
              textAlign: "center",
              display: "block",
              paddingBottom: "10px",
            }}
          >
            {action}
          </Typography>
          <Divider />
          <Typography
            variant="body1"
            style={{ wordBreak: "break-word", paddingTop: "10px" }}
          >
            Your request is being executed. Please do not reload the page before
            it is completed to avoid data loss.
          </Typography>
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default create(RpcProcessModal);
