import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { msg } from "../../messages";
import CommonModal from "../CommonModal";

import { create } from "react-modal-promise";

const PropertyInfo = (props) => {
  const submit = () => props.onResolve();

  const handleClose = () => submit();

  return (
    <CommonModal
      key="PropertyInfo"
      modalOpen={props.isOpen}
      title={props.property.humanReadable}
      handleClose={handleClose}
      buttons={
        <>
          <Button color="primary" onClick={handleClose}>
            {msg.default.ok}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="body1">
            Property: {props.property.raw.property}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            Long name: {props.property.humanReadable}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" style={{ wordBreak: "break-word" }}>
            Value: {`${props.property.value}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" style={{ wordBreak: "break-word" }}>
            Time: {props.property.time}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" style={{ wordBreak: "break-word" }}>
            Author: {props.property.author}
          </Typography>
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default create(PropertyInfo);
