import { useTheme } from "@mui/material/styles";

const useColors = () => {
  const theme = useTheme();
  const getColorBasedOnStyle = (style, currentColor) => {
    const mapping = {
      darkonlight: {
        red: {
          fg: theme.palette.wRed,
          bg: theme.palette.wWhite,
        },
        green: {
          fg: theme.palette.wGreen,
          bg: theme.palette.wWhite,
        },
        orange: {
          fg: theme.palette.wOrange,
          bg: theme.palette.wWhite,
        },
        yellow: {
          fg: theme.palette.wYellow,
          bg: theme.palette.wWhite,
        },
        blue: {
          fg: theme.palette.wBlue,
          bg: theme.palette.wWhite,
        },
        black: {
          fg: theme.palette.wBlack,
          bg: theme.palette.wWhite,
        },
        white: {
          fg: theme.palette.wWhite,
          bg: theme.palette.wWhite,
        },
        transparent: {
          fg: theme.palette.wTransparent,
          bg: theme.palette.wWhite,
        },
        default: {
          bg: theme.palette.wWhite,
          fg: theme.palette.wBlue,
        },
      },
      lightondark: {
        red: {
          bg: theme.palette.wRed,
          fg: theme.palette.wWhite,
        },
        green: {
          bg: theme.palette.wGreen,
          fg: theme.palette.wWhite,
        },
        orange: {
          bg: theme.palette.wOrange,
          fg: theme.palette.wWhite,
        },
        yellow: {
          bg: theme.palette.wYellow,
          fg: theme.palette.wWhite,
        },
        blue: {
          bg: theme.palette.wBlue,
          fg: theme.palette.wWhite,
        },
        black: {
          bg: theme.palette.wBlack,
          fg: theme.palette.wWhite,
        },
        gray4: {
          bg: theme.palette.gray4,
          fg: theme.palette.wWhite,
        },
        white: {
          bg: theme.palette.wWhite,
          fg: theme.palette.wWhite,
        },
        transparent: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wWhite,
        },
        default: {
          bg: theme.palette.wBlue,
          fg: theme.palette.wWhite,
        },
      },
      darkontransparent: {
        red: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wRed,
        },
        green: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wGreen,
        },
        orange: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wOrange,
        },
        yellow: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wYellow,
        },
        blue: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wBlue,
        },
        black: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wBlack,
        },
        white: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wWhite,
        },
        transparent: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wTransparent,
        },
        default: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wBlue,
        },
      },
      lightontransparent: {
        red: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wRed,
        },
        green: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wGreen,
        },
        orange: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wOrange,
        },
        yellow: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wYellow,
        },
        blue: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wBlue,
        },
        black: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wBlack,
        },
        white: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wWhite,
        },
        transparent: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wTransparent,
        },
        default: {
          bg: theme.palette.wTransparent,
          fg: theme.palette.wWhite,
        },
      },
    };

    if (style && currentColor) {
      return mapping[style][currentColor];
    } else if (style && !currentColor) {
      return mapping[style].default;
    } else {
      return {
        bg: theme.palette.wWhite,
        fg: theme.palette.wBlue,
      };
    }
  };

  return {
    getColorBasedOnStyle,
  };
};

export default useColors;
