import { NOTIFICATION_TYPES } from "../constants";

export default function getNotificationType(notificationTags = []) {
  if (notificationTags.includes("alert")) {
    if (notificationTags.includes("triggered")) {
      return NOTIFICATION_TYPES.ALERT_TRIGGERED;
    }
    return NOTIFICATION_TYPES.ALERT;
  } else if (notificationTags.includes("message")) {
    return NOTIFICATION_TYPES.MESSAGE;
  } else {
    return NOTIFICATION_TYPES.NOTICE;
  }
}
