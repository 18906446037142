import { gql, useLazyQuery } from "@apollo/client";
import { useState } from "react";

export const OBJECTS_QUERY = gql`
  query listDevices($filter: ObjectFilter) {
    objects(orderBy: NAME_ASC, filter: $filter) {
      id
      name
    }
  }
`;

const GET_PROPERTIES_BY_OBJECT = gql`
  query getObject($objectId: UUID!) {
    object(id: $objectId) {
      objectProperties(orderBy: GROUP_NAME_ASC) {
        id
        spec {
          description
          property
          groupName
        }
      }
    }
  }
`;

const GET_OBJECT = gql`
  query getObject($objectId: UUID!) {
    object(id: $objectId) {
      objectsToObjectsByObject1Id {
        object2 {
          id
          name
        }
      }
    }
  }
`;

const useObjectProperty = () => {
  const [propertiesGql, setPropertiesGql] = useState(GET_PROPERTIES_BY_OBJECT);
  const [objects, setObjects] = useState([]);
  const [properties, setProperties] = useState([]);

  const [objectsQuery, objectsResult] = useLazyQuery(OBJECTS_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: ({ objects }) => {
      setObjects(objects.map((item) => ({ title: item.name, value: item.id })));
    },
  });

  const [objectQuery, objectResult] = useLazyQuery(GET_OBJECT, {
    fetchPolicy: "network-only",
    onCompleted: ({ object }) => {
      setObjects(
        object.objectsToObjectsByObject1Id.map((item) => ({
          title: item.object2.name,
          value: item.object2.id,
        }))
      );
    },
  });

  const [propertiesQuery, { loading: propertiesLoading }] = useLazyQuery(
    propertiesGql,
    {
      fetchPolicy: "network-only",
      onCompleted: (e) => {
        setProperties(
          e.object.objectProperties.map((item) => ({
            title: `${item.spec?.groupName}/${
              item.spec?.description || item.spec.property
            }`,
            value: item.id,
          }))
        );
      },
    }
  );

  return {
    objectsLoading: objectsResult.loading || objectResult.loading,
    propertiesLoading,
    objects,
    propertiesQuery,
    objectsQuery,
    objectQuery,
    properties,
    setPropertiesGql,
  };
};
export default useObjectProperty;
