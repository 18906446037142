import ExtensionIcon from "@mui/icons-material/Extension";
import MemoryIcon from "@mui/icons-material/Memory";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ReactComponent as ImgBoard } from "../../assets/board.svg";
import useRoute from "../../hooks/useRoute";
import { msg } from "../../messages";
import useCustomNavigate from "../hooks/useCustomNavigate";
import ApplicationCog from "../icons/applicationCog";
import useMoreMenu from "../useMoreMenu";
import useUniversalMenu from "./menu/useUniversalMenu";

const useStyles = makeStyles((theme) => ({
  itemToHideOrShow: {
    visibility: "hidden",
    opacity: 0,
    transition: "opacity 0.2s linear",
    [theme.breakpoints.down("sm")]: {
      visibility: "visible",
      opacity: 1,
    },
  },
  itemToHover: {
    "&:hover $itemToHideOrShow": {
      visibility: "visible",
      opacity: 1,
      transition: "opacity 0.2s linear",
    },
  },
}));

const ObjectListItem = (props) => {
  const { item } = props;
  const theme = useTheme();
  const { getTypeByRoute, isReport, entityId } = useRoute();
  const type = getTypeByRoute();
  const { dashboardId, groupId, widgetId } = useParams();
  const classes = useStyles();
  const history = useCustomNavigate();
  const dispatch = useDispatch();

  // to highlight current object
  const handleMouseOver = (e) => {
    // dispatch(setContext({ objectHoveredId: e.currentTarget.id }));
  };
  // to highlight current object
  const handleMouseLeave = (e) => {
    // dispatch(setContext({ objectHoveredId: null }));
  };

  const { menuBasedOnType } = useUniversalMenu();

  const { MoreMenu, openMoreMenu, closeMoreMenu } = useMoreMenu();

  const handleMenuItemClick = (item, obj) => {
    closeMoreMenu();
  };

  const handleClickItem = (e) => {
    const section = isReport() ? "reports" : "boards";

    if (type === "widget") {
      history(
        `/${section}/${entityId()}/${groupId}/${widgetId}/${e.currentTarget.id}`
      );
    } else if (type === "group") {
      history(`/${section}/${entityId()}/${groupId}/${e.currentTarget.id}`);
    }
  };

  return (
    <>
      <MoreMenu
        items={() => menuBasedOnType(item, props.type)}
        handleMenuItemClick={handleMenuItemClick}
      />
      <ListItem
        data-test-obj-list-item={item.name}
        key={item.id}
        id={item.id}
        button
        alignItems="center"
        classes={{
          container: classes.itemToHover,
        }}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        {type === "group" && (
          <ListItemAvatar>
            <Avatar>
              <ImgBoard />
            </Avatar>
          </ListItemAvatar>
        )}

        {type !== "group" && (
          <ListItemIcon>
            {item.schemaType === "dataset" && <ExtensionIcon />}
            {item.schemaType === "device" && <MemoryIcon />}
            {item.schemaType === "application" && <ApplicationCog />}
          </ListItemIcon>
        )}

        <ListItemText
          id={item.id}
          onClick={handleClickItem}
          primary={<Typography variant="body1">{item.name}</Typography>}
          secondary={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="body2"
                noWrap={true}
                style={{ color: theme.palette.gray1, paddingRight: "6px" }}
              >
                {item.enabled ? (
                  <span>{msg.sideCard.enabled}</span>
                ) : (
                  <span>{msg.sideCard.disabled}</span>
                )}
              </Typography>
              {item.objectProperties.find((obj) => obj.key === "statusAlarm")
                ?.value === "on" && (
                <NotificationsIcon
                  fontSize={"small"}
                  style={{ color: theme.palette.gray1, fontSize: "16px" }}
                />
              )}
              {item.objectProperties.find((obj) => obj.key === "statusAlarm")
                ?.value === "triggered" && (
                <NotificationsActiveIcon
                  fontSize={"small"}
                  style={{ color: theme.palette.red, fontSize: "16px" }}
                />
              )}
            </Box>
          }
        />

        <ListItemSecondaryAction className={classes.itemToHideOrShow}>
          <IconButton
            edge="end"
            data-test-obj-list-item={`contextMenu_${item.name}`}
            aria-label="more"
            onClick={(e) => {
              e.preventDefault();
              openMoreMenu(e);
            }}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

export default ObjectListItem;
