import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const ChartTitleContainer = styled("div")({
  height: "48px",
  width: "100%",
});

const ChartTitleContainerTypography = styled(Typography)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "inherit",
  pointerEvents: "none",
  userSelect: "none",
});

const ChartSubTitle = (props: { children: string; fgColor: string }) => {
  return (
    <ChartTitleContainer>
      <ChartTitleContainerTypography sx={{ color: props.fgColor }} variant="subtitle1">
        {props.children}
      </ChartTitleContainerTypography>
    </ChartTitleContainer>
  );
};

export default ChartSubTitle;
