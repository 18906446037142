import { gql } from "../../../__generated__";

const ADD_REPORT = gql(/* GraphQL */ `
  mutation createDashboard(
    $name: String!
    $description: String!
    $values: [PropertyByKeyInput]
    $editorGroup: UUID
    $readerGroup: UUID
    $userGroup: UUID
  ) {
    createObjectWithProperties(
      input: {
        name: $name
        enabled: true
        description: $description
        schemaTags: ["application", "board", "report"]
        keyedProperties: $values
        parents: []
        childs: []
        editorGroup: $editorGroup
        readerGroup: $readerGroup
        userGroup: $userGroup
      }
    ) {
      clientMutationId
      uuid
    }
  }
`);

export { ADD_REPORT };
