import AddIcon from "@mui/icons-material/Add";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, ListItemSecondaryAction } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { dispatch as dispatchBus } from "use-bus";
import { msg } from "../../../messages";
import IdentifierIcon from "../../icons/IdentifierIcon";
import useHandleCopy from "../menu/handlers/useHandleCopy";
import PropListItem from "../PropListItem";

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: "15px",
    marginBottom: "10px",
  },
  itemToHideOrShow: {
    visibility: "hidden",
    opacity: 1,
    transition: "opacity 0.2s linear",
    [theme.breakpoints.down("sm")]: {
      visibility: "visible",
      opacity: 1,
    },
  },
  itemToHover: {
    "&:hover $itemToHideOrShow": {
      visibility: "visible",
      opacity: 1,
      transition: "opacity 0.2s linear",
    },
  },
}));

const HistoryTableGeneral = (props) => {
  const { item, type, dashboardId, groupId, widgetId } = props;
  const dispatch = useDispatch();

  const classes = useStyles({
    listItem: {
      minHeight: "56px",
    },
  });

  // group properties by groupName (array of properties -> array of groupNames)
  const grouping = (arr, key) =>
    (arr || []).reduce(
      (acc, x = {}) => ({ ...acc, [x[key]]: [...(acc[x[key]] || []), x] }),
      {}
    );

  const groups = grouping(item.objectProperties, "groupName");
  const groupNames = Object.keys(groups);

  const [fakeLoader, setFakeLoader] = useState(false);
  const fakeTrigger = () => {
    setFakeLoader(true);
    setTimeout(() => {
      setFakeLoader(false);
      dispatchBus({ type: "@@iwow/FAKE_UPDATE_HISTORY", id: item.id });
    }, 1000);
  };

  const handleCopy = useHandleCopy();

  return (
    <>
      <List>
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Columns</Typography>
        </ListSubheader>
        {[
          "Property 1",
          "Property 2",
          "Property 3",
          "Property 4",
          "Property 5",
        ].map((item, index) => {
          return (
            <ListItem
              key={index}
              classes={{
                container: classes.itemToHover,
              }}
              style={{ height: "48px", paddingRight: "96px" }}
              button
              onClick={() => {}}
            >
              <ListItemIcon />
              <ListItemText
                primary={<Typography variant="body1">{item}</Typography>}
                onClick={(e) => {}}
              />
              <ListItemSecondaryAction className={classes.itemToHideOrShow}>
                <IconButton size="small" onClick={() => {}}>
                  <MoreVertIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}

        <ListItem style={{ height: "48px" }} button onClick={() => {}}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Add column</Typography>}
          />
        </ListItem>

        <Box m={1} mb={4}>
          <LoadingButton
            loading={fakeLoader}
            onClick={fakeTrigger}
            fullWidth={true}
            variant={"outlined"}
          >
            Get data
          </LoadingButton>
        </Box>

        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">
            {msg.sideCard.serviceInfo}
          </Typography>
        </ListSubheader>

        {item.schemaTags[3] === "device" && (
          <ListItem button onClick={() => {}}>
            <ListItemIcon>
              <SubtitlesIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1">{item.schema.name}</Typography>
              }
              onClick={(e) => {}}
            />
          </ListItem>
        )}

        {item.schemaTags[3] !== "device" && (
          <ListItem button onClick={() => {}}>
            <ListItemIcon>
              <SubtitlesIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Tooltip title={item.schemaTags.join(" / ")}>
                  <Typography variant="body1">{item.schema.name}</Typography>
                </Tooltip>
              }
            />
          </ListItem>
        )}

        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <IdentifierIcon />
          </ListItemIcon>
          <ListItemText
            onClick={(e) => {
              handleCopy({ object: item });
            }}
            primary={<Typography variant="body1">{item.id}</Typography>}
          />
        </ListItem>
        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <AlternateEmailIcon />
          </ListItemIcon>
          <ListItemText
            onClick={(e) => {
              handleCopy({ object: item });
            }}
            primary={<Typography variant="body1">{item.name}</Typography>}
          />
        </ListItem>

        {groupNames.map((groupName) => {
          if (groupName !== "Value") return false;

          const headline = (
            <ListSubheader
              color="primary"
              key={groupName}
              className={classes.listSubheader}
            >
              <Typography variant="subtitle2">{groupName}</Typography>
            </ListSubheader>
          );

          return [
            headline,
            groups[groupName].map((item, index) => (
              <PropListItem
                tab="general"
                key={index}
                index={index}
                obj={props.item}
                item={item}
                dashboardId={dashboardId}
                widgetId={widgetId}
                groupId={groupId}
                type={type}
              />
            )),
          ];
        })}

        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">{msg.sideCard.access}</Typography>
        </ListSubheader>
        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {item.userGroupByEditorgroup.groupName}
              </Typography>
            }
          />
        </ListItem>

        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <PlayCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {item.userGroupByUsergroup.groupName}
              </Typography>
            }
          />
        </ListItem>

        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <RemoveRedEyeIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {item.userGroupByReadergroup.groupName}
              </Typography>
            }
          />
        </ListItem>

        {item.description && (
          <React.Fragment>
            <ListSubheader color="primary" className={classes.listSubheader}>
              <Typography variant="subtitle2">
                {msg.sideCard.description}
              </Typography>
            </ListSubheader>
            <ListItem button onClick={() => {}}>
              <ListItemText
                primary={
                  <Typography variant="body1">{item.description}</Typography>
                }
              />
            </ListItem>
          </React.Fragment>
        )}
      </List>
    </>
  );
}; //TabContentGeneral

export default HistoryTableGeneral;
