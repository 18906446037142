import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import { getIn, useFormik } from "formik";
import { loader } from "graphql.macro";
import { useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import * as yup from "yup";
import CommonModal from "../../../components/CommonModal";
import CustomSelect from "../../../components/CustomSelect";
import { msg } from "../../../messages";
import { WIDGET_QUERY } from "../../../queries";
import { urlRegex } from "../../../utils/ulrRegex";
import CustomAutocomplete from "../../CustomAutocomplete";
import CustomInput from "../../CustomInput";

const UPdATE_OBJECT_PROPERTY = loader(
  "../../../graphql/UpdateObjectWithProperties.graphql"
);
const DASHBOARDS_QUERY = gql`
  query getDashboards {
    dashboards: objects(
      filter: {
        objectsToObjectsByObject1IdConnectionExist: true
        schemaTags: { contains: ["application", "board", "dashboard"] }
      }
      orderBy: NAME_ASC
    ) {
      value: id
      title: name
      groups: objectsToObjectsByObject1Id(
        filter: { object2: { schemaTags: { contains: ["group"] } } }
      ) {
        id
        group: object2 {
          value: id
          title: name
        }
      }
    }
  }
`;

const styles = (theme) => ({
  stateHeadline: {
    marginTop: "15px",
  },
});

export const CONTAINERS_QUERY = gql`
  query listContainers {
    containers: objects(
      orderBy: NAME_ASC
      filter: { schemaTags: { contains: ["application", "board", "group"] } }
    ) {
      value: id
      title: name
    }
  }
`;

const TYPES = {
  CONTAINER: "container",
  URL: "url",
};

const LINK_TYPES = [
  { value: "container", title: "Container" },
  { value: "url", title: "URL" },
];

const LinkTitleModal = (props) => {
  const [dashboardsLazyQuery] = useLazyQuery(DASHBOARDS_QUERY);
  const [updateProperty, { loading: isSaving }] = useMutation(
    UPdATE_OBJECT_PROPERTY
  );
  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const [containers, setContainers] = useState([]);
  const [boards, setBoards] = useState([]);
  const [currentBoard, setCurrentBoard] = useState(null);

  const [values, setValues] = useReducer(
    (prev, updated) => ({ ...prev, ...updated }),
    {
      settingsLinkType: TYPES.URL,
      settingsUuid: "",
      settingsUrl: "",
    }
  );

  const { loading: isLoadingInit, error: errorInit } = useQuery(WIDGET_QUERY, {
    variables: { objId: props.widgetId },
    onError: () => {},
    onCompleted: ({ object }) => {
      const getPropValue = (o = object, prop) =>
        o.objectProperties.find((obj) => obj.key === prop)?.value;

      formik.setValues({
        settingsLinkType: getPropValue(object, "settingsLinkType"),
        settingsUuid: getPropValue(object, "settingsUuid"),
        settingsUrl: getPropValue(object, "settingsUrl"),
      });
      setValues({
        settingsLinkType: getPropValue(object, "settingsLinkType"),
        settingsUuid: getPropValue(object, "settingsUuid"),
        settingsUrl: getPropValue(object, "settingsUrl"),
      });

      if (getPropValue(object, "settingsLinkType") === TYPES.CONTAINER) {
        if (!isLoadingContainers) {
          getContainers();
        }
      }
    },
    fetchPolicy: "network-only",
  });

  const [queryContainers, { loading: isLoadingContainers }] = useLazyQuery(
    CONTAINERS_QUERY,
    {
      variables: { objId: props.widgetId },
      fetchPolicy: "network-only",
    }
  );

  const validationSchema = yup.object({
    settingsLinkType: yup.string().trim(),
    settingsUuid: yup.string().nullable().when("settingsLinkType", {
      is: TYPES.CONTAINER,
      then: yup.string().nullable(),
      otherwise: yup.string().nullable(),
    }),
    settingsUrl: yup
      .string()
      .trim()
      .nullable()
      .when("settingsLinkType", {
        is: TYPES.URL,
        then: yup.string().nullable().matches(urlRegex, "Incorrect url"),
        otherwise: yup.string().nullable(),
      }),
  });

  const formik = useFormik({
    initialValues: {
      settingsLinkType: values.settingsLinkType,
      settingsUuid: values.settingsUuid,
      settingsUrl: values.settingsUrl,
    },
    validationSchema: validationSchema,
    onSubmit: (valuesForm, actions) => {
      const valuesReady = [
        {
          propertyKey: "settingsLinkType",
          value: valuesForm.settingsLinkType,
        },
        {
          propertyKey: "settingsUuid",
          value: valuesForm.settingsUuid,
        },
        {
          propertyKey: "settingsUrl",
          value: valuesForm.settingsUrl,
        },
      ];

      toast
        .promise(
          updateProperty({
            variables: {
              input: {
                detailedObject: [
                  {
                    objectId: props.widgetId,
                    keyedProperties: valuesReady,
                  },
                ],
              },
            },
          }),
          {
            loading: "Updating title ...",
            success: () => `Title updated`,
            error: (err) => `${err.toString()}`,
          },
          {
            position: "bottom-center",
          }
        )
        .then((result) => {
          actions.resetForm();
          submit();
        });
    },
  });

  const settingsLinkType = getIn(formik.values, "settingsLinkType");
  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    if (checked) value = checked;

    setValues({ [name]: value });
  };

  const getContainers = () => {
    dashboardsLazyQuery().then(({ data }) => {
      setBoards(data.dashboards);

      if (values.settingsUuid) {
        data.dashboards.forEach((dashboard) => {
          const ids = dashboard.groups
            .map((item) => item.group)
            .map((group) => group.value);

          if (ids.includes(values.settingsUuid)) {
            setCurrentBoard(dashboard.value);
            setContainers(dashboard.groups.map((item) => item.group));
          }
        });
      }
    });
  };

  useEffect(() => {
    formik.setTouched({}, false);
    if (values.settingsLinkType === TYPES.CONTAINER) {
      formik.setValues({ ...formik.values, settingsUrl: "" });

      if (!isLoadingContainers) {
        getContainers();
      }
    } else {
      formik.setValues({ ...formik.values, settingsUuid: "" });
    }
  }, [settingsLinkType]);

  return (
    <>
      <CommonModal
        loading={isLoadingInit}
        modalOpen={props.isOpen}
        title={"Edit link"}
        handleClose={reject}
        buttons={
          <>
            <Button onClick={handleClose}>
              {msg.editWidgetModal.buttonCancel}
            </Button>
            <Button
              color="primary"
              disabled={isSaving || isLoadingContainers}
              onClick={formik.handleSubmit}
            >
              {isSaving ? <CircularProgress size={23} /> : msg.default.save}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <CustomSelect
              name="settingsLinkType"
              label={"Link type"}
              list={LINK_TYPES}
              onChange={(e) => {
                handleInputChange(e);
                formik.handleChange(e);
              }}
              value={formik.values.settingsLinkType}
            />
          </Grid>

          {formik.values.settingsLinkType === TYPES.URL && (
            <Grid item>
              <CustomInput
                name="settingsUrl"
                label={"URL"}
                clearFieldIcon={true}
                value={formik.values.settingsUrl}
                onChange={formik.handleChange}
                error={
                  formik.touched.settingsUrl &&
                  Boolean(formik.errors.settingsUrl)
                }
                helperText={
                  formik.touched.settingsUrl && formik.errors.settingsUrl
                }
              />
            </Grid>
          )}

          {formik.values.settingsLinkType === TYPES.CONTAINER && (
            <>
              <Grid item>
                <CustomAutocomplete
                  label={"Board name"}
                  loading={isLoadingContainers}
                  disabled={isLoadingContainers}
                  list={boards}
                  clearFieldIcon={true}
                  value={currentBoard}
                  onChange={(e) => {
                    if (e.target.value) {
                      setCurrentBoard(e.target.value);
                      setContainers(
                        e.target.rawValue.groups.map((item) => item.group)
                      );
                    } else {
                      formik.setValues({
                        ...formik.values,
                        settingsUuid: null,
                      });
                      setContainers([]);
                      setCurrentBoard(null);
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <CustomAutocomplete
                  label={"Container"}
                  loading={isLoadingContainers}
                  disabled={!currentBoard}
                  list={containers}
                  name={"settingsUuid"}
                  clearFieldIcon={true}
                  value={formik.values.settingsUuid}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.settingsUuid &&
                    Boolean(formik.errors.settingsUuid)
                  }
                  helperText={
                    formik.touched.settingsUuid && formik.errors.settingsUuid
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(LinkTitleModal);
