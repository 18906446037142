import { useMutation } from "@apollo/client";
import { Button } from "@mui/material";
import { loader } from "graphql.macro";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import useCustomNavigate from "../../../components/hooks/useCustomNavigate";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import { msg } from "../../../messages";

const DELETE_OBJECTS = loader("../../../graphql/DeleteObjectsMutation.graphql");

const DeleteCollectionModal = (props) => {
  const [deleteObjects] = useMutation(DELETE_OBJECTS);

  const type = props.type || "object";

  const history = useCustomNavigate();
  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleSave = () => {
    const promises = Promise.all([
      deleteObjects({ variables: { objects: props.ids } }),
    ]);

    submit();
    history(`/collections`);
    toast
      .promise(
        promises,
        {
          loading: props.toast.loading,
          success: () => props.toast.success,
          error: (err) => `${err.toString()}`,
        },
        {
          position: "bottom-center",
        }
      )
      .then(() => {});
  };

  return (
    <ConfirmationModal
      modalOpen={props.isOpen}
      title={msg.deleteGroupModal.deleteGroup}
      handleClose={handleClose}
      buttons={
        <>
          <Button onClick={handleClose}>
            {msg.deleteWidgetModal.buttonCancel}
          </Button>
          <Button color="primary" onClick={handleSave}>
            {msg.deleteWidgetModal.buttonDelete}
          </Button>
        </>
      }
    >
      {props.text}
    </ConfirmationModal>
  );
};

export default create(DeleteCollectionModal);
