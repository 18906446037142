import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
export const computeRpcStatus = (execution, preFilled) => {
  // 2. запрошено выполнение
  const rpcRequested = () => {
    preFilled.icon.name = AccessTimeOutlinedIcon;
    preFilled.icon.color = "primary";
  };
  // 3. вызов получен модулем (выполняется)
  const rpcInProgress = () => {
    preFilled.icon.name = ReplayOutlinedIcon;
    preFilled.icon.color = "info";
  };
  // 4. выполнение закончено с ошибкой
  const doneWithError = () => {
    preFilled.icon.name = ErrorOutlineOutlinedIcon;
    preFilled.icon.color = "error";
  };
  // 5. выполнение закончено без ошибки
  const doneWithoutError = () => {
    preFilled.icon.name = CheckCircleOutlineIcon;
    preFilled.icon.color = "success";
  };

  if (execution.ack) {
    if (execution.done && (execution.error === false || execution.error === "false" || execution.error === null)) {
      doneWithoutError();
      return;
    }

    if (execution.done && (execution.error === true || execution.error === "true" || execution.error !== null)) {
      doneWithError();
      return;
    }

    if (!execution.done) {
      rpcInProgress();
    }
  } else {
    rpcRequested();
  }
};
