import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import makeStyles from "@mui/styles/makeStyles";
import { castToType } from "../utils/misc.js";

// <CustomSwitch name="compactMode" label={msg.addDashboardModal.compactMode} value={values.compactMode} propType="bool" onChange={handleInputChange} />
// propType = bool|number|string (string - default). forces type casting of returned value

const styles = (theme) => ({});

const useStyles = makeStyles((theme) => styles(theme));

const CustomSwitch = (props) => {
  const handleInputChangeCasted = (event, type) => {
    let { name, value, checked } = event.target;
    const e = {
      target: { name: name, value: castToType(value, type), checked: checked },
    };
    //handleInputChange(e);
    props.onChange(e);
  };

  return (
    <>
      <InputLabel
        htmlFor={`${props.name}-label`}
        style={{ display: "inline", color: "#212121" }}
      >
        {props.label}
      </InputLabel>
      <Switch
        name={props.name}
        id={`${props.name}-label`}
        value={props.value === "true" ? "on" : ""}
        checked={Boolean(props.value)}
        disabled={Boolean(props.disabled)}
        onChange={(e) => {
          handleInputChangeCasted(e, props.propType);
        }}
        color="primary"
      />
    </>
  );
};

export default CustomSwitch;
