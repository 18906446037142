import AddIcon from "@mui/icons-material/Add";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import { orderBy } from "lodash";
import PropTypes from "prop-types";
import useRoute from "../../hooks/useRoute";
import { msg } from "../../messages";
import { isGroup, isStatistics, isWidget } from "../../utils/objectType";
import AddNewObjectModal from "../AddNewObjectModal";
import AddWidgetModal from "../AddWidgetModal";
import LinkWidgetModal from "../LinkWidgetModal";
import ObjectListItem from "./ObjectListItem";

const TabContentObjects = (props) => {
  const { item } = props;

  const { getParamsByRoute, getTypeByRoute } = useRoute();

  const type = getTypeByRoute();
  const { dashboardId, groupId, widgetId, objectId, collectionId } =
    getParamsByRoute();

  return (
    <>
      <List>
        {orderBy(
          item.objectsToObjectsByObject1Id,
          ["object2.name"],
          ["asc"]
        ).map((object, index) => (
          <ObjectListItem
            key={index}
            index={index}
            item={{ ...object.object2, linkId: object.id }}
            dashboardId={dashboardId}
            widgetId={widgetId}
            groupId={groupId}
            type={type}
          />
        ))}
        {/* show "+ Add existing object" only for widgets' tab "Objects" */}
        {(isStatistics(item.schemaTags) || isWidget(item.schemaTags)) && (
          <>
            <ListItem
              data-test-add-existing-object="addExistingObject"
              button
              onClick={() => {
                LinkWidgetModal({
                  widgetId: item.id,
                  refetch: props.refetch,
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <ListItemIcon>
                <IconButton size="small">
                  <AddIcon />
                </IconButton>
              </ListItemIcon>
              <ListItemText primary={msg.sideCard.addExistingObject} />
            </ListItem>

            <ListItem
              data-test-add-object="addNewObject"
              button
              onClick={() => {
                AddNewObjectModal({
                  refetch: props.refetch,
                  widgetId: item.id,
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <ListItemIcon>
                <IconButton size="small">
                  <AddIcon />
                </IconButton>
              </ListItemIcon>
              <ListItemText primary={msg.sideCard.addNewObject} />
            </ListItem>
          </>
        )}

        {isGroup(item.schemaTags) && (
          <>
            <ListItem
              data-test-new-widget
              button
              onClick={(e) => {
                AddWidgetModal({
                  group: item,
                  groupId: item.id,
                })
                  .then()
                  .catch((e) => {});
              }}
            >
              <ListItemIcon>
                <IconButton size="small">
                  <AddIcon />
                </IconButton>
              </ListItemIcon>
              <ListItemText primary="Add new widget" />
            </ListItem>
          </>
        )}
      </List>
    </>
  );
}; //TabContentObjects

TabContentObjects.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export default TabContentObjects;
