import AddIcon from "@mui/icons-material/Add";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TodayIcon from "@mui/icons-material/Today";
import LinearProgress from "@mui/material/LinearProgress";
import { orderBy } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AddGroupModal from "../../../components/AddGroupModal";
import DeleteGroupsModal from "../../../components/modals/DeleteGroupsModal";
import SideList from "../../../components/SideList";
import AddReportModal from "./AddReportModal";
import ReportIntervalSettings from "./report-interval-settings/ReportIntervalSettings";

const SideListReports = ({ dashboardLoading, dashboardData }) => {
  let { reportId } = useParams();
  const [itemsChecked, setItemsChecked] = useState([]);
  const isMayEdit = useSelector((state) => state.settings.isMayEdit);

  const dashboardName = () => {
    if (!dashboardLoading && dashboardData?.dashboard) {
      return dashboardData.dashboard.name || "n/a";
    }

    return "Loading...";
  };

  const getList = (sortType) => {
    if (!dashboardLoading && dashboardData?.dashboard) {
      const groups = dashboardData.dashboard.groups.map((item) => item.group);
      return orderBy(groups, [sortType], ["asc"]).map((item) => ({
        ...item,
        link: `/reports/${dashboardData?.dashboard.id}/${item.id}`,
      }));
    }
    return [];
  };

  const headerMenu = [
    {
      icon: <AddIcon />,
      title: "Add new container",
      id: "add_new_group",
      disabled: !isMayEdit,
      handleAction: () => {
        AddGroupModal({})
          .then()
          .catch(() => {});
      },
    },
    { isDivider: true, name: "", id: "divider" },
    {
      icon: <EditIcon />,
      title: "Edit report",
      id: "edit_report",
      handleAction: () => {
        AddReportModal({
          reportId: reportId,
        })
          .then()
          .catch(() => {});
      },
      disabled: false,
    },
    {
      icon: <TodayIcon />,
      title: "Report interval settings",
      id: "reportIntervalSettings",
      handleAction: () => {
        ReportIntervalSettings({
          reportId: reportId,
        })
          .then()
          .catch(() => {});
      },
      disabled: false,
    },
  ];

  const headerGroupMenu = [
    {
      icon: <BlockIcon />,
      title: "Disable",
      id: "disable",
      disabled: true,
    },
    {
      icon: <DeleteIcon />,
      title: "Delete",
      id: "delete-group",
      handleAction: () => {
        DeleteGroupsModal({
          ids: itemsChecked,
          dashboardId: reportId,
          setItemsChecked,
        })
          .then()
          .catch(() => {});
      },
      disabled: false,
    },
  ];

  return (
    <>
      {dashboardLoading && !dashboardData && <LinearProgress />}
      {!dashboardLoading && dashboardData && (
        <SideList
          sideBarWidth={412}
          title={dashboardName()}
          getList={getList}
          itemsChecked={itemsChecked}
          setItemsChecked={setItemsChecked}
          headerMenu={headerMenu}
          headerGroupMenu={headerGroupMenu}
        />
      )}
    </>
  );
};

export default SideListReports;
