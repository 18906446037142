import { createSvgIcon } from "@mui/material";

const tableArrowDown = createSvgIcon(
  <path
    d="M4 3H18C19.11 3 20 3.9 20 5V12.08C18.45 11.82 16.92 12.18 15.68 13H12V17H13.08C12.97 17.68 12.97 18.35 13.08 19H4C2.9 19 2 18.11 2 17V5C2 3.9 2.9 3 4 3ZM4 7V11H10V7H4ZM12 7V11H18V7H12ZM4 13V17H10V13H4ZM15.94 18.5H17.94V14.5H19.94V18.5H21.94L18.94 21.5L15.94 18.5Z"
    fill="currentColor"
  />,
  "tableArrowDown"
);

export default tableArrowDown;
