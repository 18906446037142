import { useMutation } from "@apollo/client";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import { Button, FormControl, LinearProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { loader } from "graphql.macro";
import { useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import { useDispatch, useSelector } from "react-redux";
import imgUpload from "../assets/upload.svg";
import CommonModal from "../components/CommonModal";
import Subtitle from "../components/Subtitle";
import { msg } from "../messages";
import { updateProfile } from "../store/profileSlice";
import { setSettings } from "../store/settingsSlice";
import useMedia from "../utils/useMedia";
import FileUpload from "./common/FileUpload";
import useCustomNavigate from "./hooks/useCustomNavigate";
import useHandleCopy from "./side-card/menu/handlers/useHandleCopy";

const styles = (theme) => ({
  imgUpload: {
    width: "100%",
  },
  iconButton: {
    backgroundColor: "#000000",
    opacity: "0.44",
    height: "40px",
    width: "40px",
    color: "#ffffff",
    marginLeft: "16px",
    "&:hover": {
      backgroundColor: "#000000",
      opacity: "0.6",
    },
  },
});

const UPDATE_OBJECT_PROPERTY = loader(
  "../graphql/UpdateObjectWithProperties.graphql"
);

const SettingsModal = (props) => {
  const [updateProperty] = useMutation(UPDATE_OBJECT_PROPERTY);

  const theme = useTheme();

  const useStyles = makeStyles((theme) => styles(theme));

  const classes = useStyles();
  const settings = useSelector((state) => state.settings);
  const profile = useSelector((state) => state.profile);
  const lang = useSelector((state) => state.settings.lang);
  const dispatch = useDispatch();
  const history = useCustomNavigate();

  const { getImageById } = useMedia();
  const handleCopy = useHandleCopy();

  const [programCompanylogouid, setProgramCompanylogouid] = useState(null);
  const [fileIsUploading, setFileIsUploading] = useState(false);

  useEffect(() => {
    setProgramCompanylogouid(profile?.programCompanylogoimageuid);
  }, []);

  const handleUploaded = (result_uid, fileName) => {
    setProgramCompanylogouid(result_uid); // should be passed to useFileUpload->handleFileUpload
    setValues({
      programCompanylogoimageuid: result_uid,
      programCompanylogoimagename: fileName,
    });
  };

  const handleError = (message) => {
    toast.error(message, {
      position: "bottom-center",
    });
  };

  const handleDelete = async (e) => {
    setProgramCompanylogouid(null);

    setValues({
      programCompanylogoimageuid: null,
      programCompanylogoimagename: null,
    });
  };

  let defaultValues = {
    programAppTitle: profile.programAppTitle,
  };

  const submit = () => props.onResolve();

  const reject = () => props.onReject();

  const [values, setValues] = useReducer(
    (prev, updated) => ({ ...prev, ...updated }),
    defaultValues
  );

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    setValues({ [name]: value });
    //    if (checked) value = checked;
    if (typeof checked !== "undefined") value = checked;

    //TODO: better make all state changes ONLY after press of "Save" button
    dispatch(setSettings({ [name]: value })); // update global state

    if (name === "lang")
      history.replace(history.location.pathname.replace(lang, value));
  };

  const handleUpdateWidgetProps = () => {
    const valuesReady = Object.keys(values).map((key) => {
      return { propertyKey: key, value: values[key] };
    });

    toast
      .promise(
        updateProperty({
          variables: {
            input: {
              detailedObject: [
                { objectId: profile.id, keyedProperties: valuesReady },
              ],
            },
          },
        }),
        {
          loading: "Updating...",
          success: () => "Updated",
          error: (err) => `${err.toString()}`,
        },
        {
          position: "bottom-center",
        }
      )
      .then(() => {
        dispatch(
          updateProfile({
            programCompanylogoimageuid: programCompanylogouid,
            programAppTitle: values.programAppTitle,
          })
        );
        submit();
      });
  };

  const companyLogoUrl = getImageById(programCompanylogouid);
  const companyLogoBlankUrl = imgUpload;

  //TODO: replace Input to CustomInput ?
  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={msg.settingsModal.settings}
        handleClose={handleClose}
        buttons={
          <>
            <Button onClick={handleClose}>
              {msg.settingsModal.buttonCancel}
            </Button>
            <Button color="primary" onClick={handleUpdateWidgetProps}>
              {msg.editNotificationsSettingsModal.buttonUpdate}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Subtitle>{msg.settingsModal.general}</Subtitle>
          </Grid>
          {/*
        <Grid item>
          <InputLabel htmlFor="settings-theme-label">
            {msg.settingsModal.defaultTheme}
          </InputLabel>
          <Select
            name="theme"
            id="settings-theme-label"
            value={settings.theme}
            labelId="settings-theme-label"
            onChange={handleInputChange}
            fullWidth
          >
            <MenuItem value="dark">{msg.settingsModal.themeDark}</MenuItem>
            <MenuItem value="light">{msg.settingsModal.themeLight}</MenuItem>
          </Select>
        </Grid>
*/}
          <Grid item>
            <FormControl variant={"standard"} fullWidth={true}>
              <InputLabel id="settings-lang-label">
                {msg.settingsModal.defaultLang}
              </InputLabel>
              <Select
                name="lang"
                value={settings.lang}
                labelId="settings-lang-label"
                onChange={handleInputChange}
              >
                <MenuItem value="en">{msg.settingsModal.langEnglish}</MenuItem>
                <MenuItem value="ru">{msg.settingsModal.langRussian}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl variant={"standard"} fullWidth={true} id="app-title">
              <InputLabel htmlFor="app-title">
                {msg.settingsModal.appTitle}
              </InputLabel>
              <Input
                labelId="app-title"
                name="programAppTitle"
                value={values.programAppTitle}
                onChange={handleInputChange}
                fullWidth
                inputProps={{
                  style: {
                    WebkitTextFillColor: theme.palette.foreground,
                  },
                }}
              />
            </FormControl>
          </Grid>

          <Grid item>
            <InputLabel>
              <Typography
                variant={"subtitle2"}
                style={{ fontWeight: "normal" }}
              >
                {msg.settingsModal.companyLogo}
              </Typography>
            </InputLabel>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            alignItems="flex-start"
            style={{ position: "relative" }}
          >
            <div style={{ position: "relative" }}>
              <div style={{ width: "100%", position: "absolute" }}>
                {fileIsUploading && (
                  <LinearProgress style={{ width: "100%" }} />
                )}
              </div>
              <img
                alt="logo"
                src={`${
                  programCompanylogouid ? companyLogoUrl : companyLogoBlankUrl
                }`}
                style={{ width: "100%" }}
              />
            </div>

            {/* buttons upload/copy/delete */}
            <div style={{ position: "absolute", right: "16px", top: "16px" }}>
              {programCompanylogouid && (
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    handleCopy({
                      message: msg.settingsModal.copied,
                      text: programCompanylogouid,
                    });
                  }}
                  size="large"
                >
                  <FilterNoneIcon />
                </IconButton>
              )}

              {!programCompanylogouid && (
                <IconButton
                  variant="contained"
                  component="label"
                  className={classes.iconButton}
                  size="large"
                >
                  <FileUpload
                    file_uid={programCompanylogouid}
                    maxSize={1000000}
                    setLoading={setFileIsUploading}
                    handleUploaded={handleUploaded}
                    handleError={handleError}
                  />
                  <CloudUploadIcon />
                </IconButton>
              )}

              {programCompanylogouid && (
                <IconButton
                  className={classes.iconButton}
                  onClick={handleDelete}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(SettingsModal);
