import { useDispatch, useSelector } from "react-redux";
import { useParams, useResolvedPath } from "react-router-dom";
import useCustomNavigate from "../components/hooks/useCustomNavigate";
import { setContext } from "../store/contextSlice";
import { setSettings } from "../store/settingsSlice";

const useRoute = () => {
  const resolvedPath = useResolvedPath();
  const navigate = useCustomNavigate();

  const { collectionId, reportId, dashboardId, groupId, widgetId, objectId } =
    useParams();

  const dispatch = useDispatch();
  const isShowHistory = useSelector((state) => state.settings.isShowHistory);
  const isShowHistoryProperty = useSelector(
    (state) => state.settings.isShowHistoryProperty
  );
  const isShowControlsHistory = useSelector(
    (state) => state.settings.isShowControlsHistory
  );

  const getTypeByRoute = () => {
    if (objectId) return "object";
    if (widgetId) return "widget";
    if (groupId) return "group";
    if (dashboardId) return "dashboard";
    if (reportId) return "dashboard";
    if (collectionId) return "collection";
  };

  const getParamsByRoute = () => {
    return {
      collectionId,
      reportId,
      dashboardId,
      groupId,
      widgetId,
      objectId,
    };
  };

  const handleGoStart = () => {
    if (isShowHistory || isShowHistoryProperty || isShowControlsHistory) {
      dispatch(setSettings({ isShowHistory: false }));
      dispatch(setSettings({ isShowHistoryProperty: false }));
      dispatch(setSettings({ isShowControlsHistory: false }));
    }

    dispatch(setContext({ objectHoveredId: null }));

    if (collectionId) {
      navigate(`/collections`);
    } else if (reportId) {
      navigate(`/reports/${entityId()}`);
    } else {
      navigate(`/boards/${entityId()}`);
    }
  };

  const handleGoBack = () => {
    if (isShowHistory || isShowHistoryProperty || isShowControlsHistory) {
      dispatch(setSettings({ isShowHistory: false }));
      dispatch(setSettings({ isShowHistoryProperty: false }));
      dispatch(setSettings({ isShowControlsHistory: false }));
      return;
    }

    const pathParts = resolvedPath.pathname.split("/");
    pathParts.splice(pathParts.length - 1, 1);

    dispatch(setContext({ objectHoveredId: null }));
    navigate(pathParts.join("/"));
  };

  const isBoard = () => {
    return dashboardId;
  };

  const isReport = () => {
    return reportId;
  };

  const entityId = () => {
    return dashboardId || reportId;
  };

  return {
    getParamsByRoute,
    getTypeByRoute,
    handleGoStart,
    handleGoBack,
    isBoard,
    isReport,
    entityId,
  };
};
export default useRoute;
