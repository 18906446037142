import { gql, useApolloClient, useMutation } from "@apollo/client";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { highlightSelectedStyle } from "../../constants";
import { RPC_PARAMS_QUERY } from "../../queries";
import useColors from "../../utils/useColors";
import CustomInput from "../CustomInput";
import RpcSubscribeWrapper from "../side-card/static-table/RpcSubscribeWrapper";
import WidgetEditControls from "../WidgetEditControls";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "4px",
      background: "#fff",
    },
  },
}));

const CREATE_EXECUTION = gql`
  mutation createControlsExecution($input: CreateControlExecutionInput!) {
    createControlExecution(input: $input) {
      controlExecution {
        params
        name
        nodeId
        linkedControlId
        objectId
        id
      }
      clientMutationId
    }
  }
`;

const GET_DATA_SUBSCRIPTION = gql`
  subscription Objects($objId: UUID!) {
    Objects(filterA: { id: [$objId] }) {
      event
      relatedNode {
        ... on ObjectProperty {
          id
          linkedPropertyId
          groupName
          property
          value
          key
        }
      }
      relatedNodeId
    }
  }
`;

const W_AdvancedButton = (props) => {
  const classes = useStyles();
  const [createExecution, { loading }] = useMutation(CREATE_EXECUTION);

  const { id, objectProperties, selected } = props;
  const [listOfParams, setListOfParams] = useState([]);
  const theme = useTheme();

  const getPropValue = (prop) =>
    objectProperties.find((obj) => obj.key === prop)?.value;
  const action = getPropValue("valueValue");
  const buttonText = getPropValue("valueText");

  let defaultValues = {};

  const [valuesParams, setValuesParams] = useReducer(
    (prev, updated) =>
      updated["action"] === "reset" ? defaultValues : { ...prev, ...updated },
    defaultValues
  );

  const handleInputParamsChange = (e) => {
    let { name, value } = e.target;
    setValuesParams({ [name]: value });
  };

  const loadRpcParams = async (rpc) => {
    const localAction = getPropValue("valueValue");
    if (rpc?.schemaId || action?.schemaId) {
      try {
        const result = await client.query({
          query: RPC_PARAMS_QUERY,
          variables: {
            schemaId: rpc?.schemaId || localAction.schemaId,
            rpc: rpc?.value || localAction.value,
          },
          fetchPolicy: "network-only",
        });

        setListOfParams(
          result.data.schemaControls.map((item) => {
            return {
              value: item.argument,
              title: item.description || item.argument,
              raw: item,
            };
          })
        );
      } catch (err) {
        toast.error(err.toString(), {
          position: "bottom-center",
        });
      }
    }
  };
  const client = useApolloClient();

  const title = getPropValue("settingsTitle");
  const size = getPropValue("settingsSize");

  const style = getPropValue("settingsStyle");

  const { getColorBasedOnStyle } = useColors();

  const valueCurrentColor = getPropValue("valueCurrentColor");

  const [colors, setColors] = useState(
    getColorBasedOnStyle(style, valueCurrentColor)
  );

  const isEditMode = useSelector((state) => state.settings.isEditMode);

  const [bgColor, setBgColor] = useState(
    style !== "lightondark" ? colors.bg : "#fff"
  );

  const handleExecuteControl = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            objectId: action.objectId,
            params: valuesParams,
            name: action.value,
          },
        },
      },
    });
  };

  useEffect(() => {
    const observer = client.subscribe({
      query: GET_DATA_SUBSCRIPTION,
      variables: { objId: id },
    });

    const subscription = observer.subscribe(({ data }) => {
      if (data.Objects.relatedNode?.key === "valueValue") {
        loadRpcParams(data.Objects.relatedNode.value);
      } else if (data.Objects.relatedNode?.key === "valueCurrentColor") {
        setColors(getColorBasedOnStyle(style, data.Objects.relatedNode?.value));
      } else if (data.Objects.relatedNode?.key === "settingsStyle") {
        setColors(
          getColorBasedOnStyle(
            data.Objects.relatedNode?.value,
            valueCurrentColor
          )
        );
        if (data.Objects.relatedNode.value !== "lightondark") {
          setBgColor(colors.bg);
        } else {
          setBgColor("#fff");
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [id]);

  useEffect(() => {
    loadRpcParams();
  }, [id]);

  return (
    <div
      className={"force-scroll"}
      style={{
        position: "relative",
        backgroundColor: bgColor,
        pointerEvents: isEditMode ? "none" : "auto",
        filter: selected ? highlightSelectedStyle : "",
        borderRadius: "2px",
        height: "100%",
      }}
    >
      <div
        style={{
          padding: style !== "lightontransparent" ? "0 2px 2px 2px" : "0",
          gap: "0",
          rowGap: "4px",
          columnGap: "6px",
          marginTop: style !== "lightontransparent" ? "2px" : "0",
          display: "grid",
          flexGrow: 1,
          gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
          gridAutoFlow: "dense",
        }}
      >
        {listOfParams.map((item) => (
          <CustomInput
            title={item.title}
            className={classes.root}
            variant={"outlined"}
            key={item.value}
            name={item.value}
            size={"medium"}
            placeholder={item.title}
            clearFieldIcon={true}
            inputProps={{
              disableUnderline: true,
              style: {
                height: style !== "lightontransparent" ? 42 : 46,
              },
            }}
            value={valuesParams[item.value] ?? ""}
            onChange={handleInputParamsChange}
          />
        ))}

        <RpcSubscribeWrapper
          rpcName={action.value}
          objectId={action.objectId}
          object={null}
          handler={handleExecuteControl}
          title={buttonText || "n/a"}
        >
          <LoadingButton
            sx={{
              whiteSpace: "nowrap",
              display: "inline-block",
              textOverflow: "ellipsis",
              overflow: "hidden",
              height: style !== "lightontransparent" ? 42 : 46,
            }}
            variant={style !== "lightontransparent" ? "outlined" : "contained"}
            fullWidth
            disableElevation
          ></LoadingButton>
        </RpcSubscribeWrapper>
      </div>
      <WidgetEditControls {...props} />
    </div>
  );
};

export default W_AdvancedButton;
