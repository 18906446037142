import { gql } from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import { ListItemSecondaryAction } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { msg } from "../../../messages";
import { setSettings } from "../../../store/settingsSlice";
import IdentifierIcon from "../../icons/IdentifierIcon";
import EditTimerSourceModal from "../../modals/EditTimerSourceModal";
import useMoreMenu from "../../useMoreMenu";
import HistoryProperty from "../history/HistoryProperty";
import useHandleCopy from "../menu/handlers/useHandleCopy";
const CREATE_EXECUTION = gql`
  mutation createControlsExecution($input: CreateControlExecutionInput!) {
    createControlExecution(input: $input) {
      clientMutationId
    }
  }
`;

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: "15px",
    marginBottom: "10px",
  },
  itemToHideOrShow: {
    visibility: "hidden",
    opacity: 1,
    transition: "opacity 0.2s linear",
    [theme.breakpoints.down("sm")]: {
      visibility: "visible",
      opacity: 1,
    },
  },
  itemToHover: {
    "&:hover $itemToHideOrShow": {
      visibility: "visible",
      opacity: 1,
      transition: "opacity 0.2s linear",
    },
  },
}));

const TimerGeneralTab = (props) => {
  const { item, type, dashboardId, groupId, widgetId } = props;
  const { MoreMenu: ColumnMenu, openMoreMenu: openColumnMenu } = useMoreMenu();
  const dispatch = useDispatch();

  const [idProperty, setIdProperty] = useState(null);
  const isShowHistoryProperty = useSelector(
    (state) => state.settings.isShowHistoryProperty
  );

  const classes = useStyles({
    listItem: {
      minHeight: "56px",
    },
  });

  const hasAlarms = (alerts = getPropertyByKey("alarmsAlerts").value) => {
    return alerts.some((item) => {
      if (Object.values(item.condition).length) {
        if (item.condition.value && item.condition.operator) {
          return true;
        }
      }
      return false;
    });
  };

  const getPropertyByKey = (key) => {
    return item.objectProperties.find((item) => item.key === key);
  };

  const valueProperty = () => getPropertyByKey("valueValue");

  const handleCopy = useHandleCopy();

  return (
    <>
      {isShowHistoryProperty && (
        <HistoryProperty id={idProperty} object={props.item} />
      )}
      <List>
        <ColumnMenu
          items={[
            {
              icon: <EditIcon />,
              title: "Edit",
              id: "edit_prop",
              handleAction: (obj) => {
                EditTimerSourceModal({
                  object: obj,
                })
                  .then()
                  .catch(() => {});
              },
              disabled: false,
            },
            {
              icon: <HistoryIcon />,
              title: "Show history",
              id: "show_history",
              handleAction: (obj) => {
                setIdProperty(obj.propId);
                dispatch(setSettings({ isShowHistoryProperty: true }));
              },
              disabled: false,
            },
          ]}
        />
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Value</Typography>
        </ListSubheader>
        <ListItem
          classes={{
            container: classes.itemToHover,
          }}
          style={{ height: "48px", paddingRight: "96px" }}
          button
          onClick={() => {}}
        >
          <ListItemIcon>{hasAlarms() && <NotificationsIcon />}</ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">{valueProperty().value}</Typography>
            }
            onClick={(e) => {}}
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openColumnMenu(e, {
                  ...props.item,
                  item: item,
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">
            {msg.sideCard.serviceInfo}
          </Typography>
        </ListSubheader>

        {item.schemaTags[3] === "device" && (
          <ListItem button onClick={() => {}}>
            <ListItemIcon>
              <SubtitlesIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1">{item.schema.name}</Typography>
              }
              onClick={(e) => {}}
            />
          </ListItem>
        )}

        {item.schemaTags[3] !== "device" && (
          <ListItem button onClick={() => {}}>
            <ListItemIcon>
              <SubtitlesIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Tooltip title={item.schemaTags.join(" / ")}>
                  <Typography variant="body1">{item.schema.name}</Typography>
                </Tooltip>
              }
            />
          </ListItem>
        )}

        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <IdentifierIcon />
          </ListItemIcon>
          <ListItemText
            onClick={(e) => {
              handleCopy({ object: item });
            }}
            primary={<Typography variant="body1">{item.id}</Typography>}
          />
        </ListItem>

        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">{msg.sideCard.access}</Typography>
        </ListSubheader>
        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {item.userGroupByEditorgroup.groupName}
              </Typography>
            }
          />
        </ListItem>

        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <PlayCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {item.userGroupByUsergroup.groupName}
              </Typography>
            }
          />
        </ListItem>

        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <RemoveRedEyeIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {item.userGroupByReadergroup.groupName}
              </Typography>
            }
          />
        </ListItem>

        {item.description && (
          <React.Fragment>
            <ListSubheader color="primary" className={classes.listSubheader}>
              <Typography variant="subtitle2">
                {msg.sideCard.description}
              </Typography>
            </ListSubheader>
            <ListItem button onClick={() => {}}>
              <ListItemText
                primary={
                  <Typography variant="body1">{item.description}</Typography>
                }
              />
            </ListItem>
          </React.Fragment>
        )}
      </List>
    </>
  );
}; //TabContentGeneral

export default TimerGeneralTab;
