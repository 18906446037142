import { useApolloClient } from "@apollo/client";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import CommonModal from "../../../components/CommonModal";
import CustomAutocomplete from "../../../components/CustomAutocomplete";
import { msg } from "../../../messages";
import { OBJECTS_QUERY } from "../../../queries";

const FilterGroupModal = (props) => {
  const client = useApolloClient();

  const [listOfObjects, setListOfObjects] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [value, setValue] = useState(null);

  useEffect(() => {
    const queryObjects = async () => {
      setLoading(true);
      try {
        const result = await client.query({
          query: OBJECTS_QUERY,
          variables: {
            filter: {
              schemaTags: {
                contains: ["application", "monitor", "group"],
              },
            },
          },
          fetchPolicy: "network-only",
        });

        setListOfObjects(
          result.data.objects.map((item) => {
            return { value: item.id, title: item.name ? item.name : "n/a" };
          })
        );

        setValue(props.value);
      } catch (err) {
        toast.error(`${err.toString()}`);
      } finally {
        setLoading(false);
      }
    };

    queryObjects();
  }, []);

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    if (checked) value = checked;

    setValue(e.target.rawValue);
  };

  const handleLinkWidget = () => {
    props.save(value);
    submit();
  };

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={"Select group"}
        handleClose={handleClose}
        buttons={
          <>
            <Button onClick={handleClose}>
              {msg.linkWidgetModal.buttonCancel}
            </Button>
            <Button color="primary" onClick={handleLinkWidget}>
              {msg.linkWidgetModal.buttonSave}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            {/* TODO: #351 */}
            <CustomAutocomplete
              disabled={isLoading}
              loading={isLoading}
              name="group"
              label={"Group"}
              list={listOfObjects}
              value={value?.value ?? ""}
              onChange={handleInputChange}
              clearFieldIcon={true}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(FilterGroupModal);
