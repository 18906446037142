import { createSvgIcon } from "@mui/material";

const LabelIcon = createSvgIcon(
  <path
    d="M17.63 5.84C17.27 5.33 16.67 5 16 5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V17C3 17.5304 3.21071 18.0391 3.58579 18.4142C3.96086 18.7893 4.46957 19 5 19H16C16.67 19 17.27 18.66 17.63 18.15L22 12L17.63 5.84Z"
    fill="#686868"
  />,

  "LabelIcon"
);

export default LabelIcon;
