import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  Autocomplete,
  Button,
  Chip,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useReducer, useState } from "react";
import CommonModal from "../components/CommonModal";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import sixtyMinutes from "../constants/sixtyMinutes";
import twentyFourHours from "../constants/twentyFourHours";
import { msg } from "../messages";

import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import CustomSwitch from "./CustomSwitch";

import { loader } from "graphql.macro";
import { isEmpty, orderBy, sortBy } from "lodash";
import { CONTROLS_QUERY } from "../queries.js";
import CustomAutocomplete from "./CustomAutocomplete";

import { isEqual } from "lodash";

const UPDATE_PROPERTIES_BY_IDS = loader(
  "../graphql/UpdateObjectPropertiesByIdMutation.graphql"
);
const on = "on";
const off = "off";
const alarmOnOrOffCondition = (onOrOff) =>
  onOrOff === on ? "executeOnAlarmOnObject" : "executeOnAlarmOffObject";
const week = [0, 1, 2, 3, 4, 5, 6];

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const styles = (theme) => ({
  alertLabel: {
    color: theme.palette.gray1,
  },
});

const daysOfWeek = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];

function getControlsList(data) {
  let tmpControls = data.object.schema.schemaControls.map((control) => {
    const exec = data.object.controlExecutions.find(
      (exec) => exec.name === control.rpc
    );

    if (exec)
      return {
        ...control,
        ack: exec.ack,
        done: exec.done,
        error: exec.error,
      };
    else return control;
  });

  tmpControls = orderBy(tmpControls, ["rpc"], ["asc"]);

  const controlsList = tmpControls
    ?.filter((item) => item.rpc === item.argument)
    ?.map((control) => ({
      title: control.description,
      value: control.id,
      rpc: control.rpc,
    }));

  return { tmpControls, controlsList };
}

function alarmOptionsInitialValue(linkedObjectsList) {
  return {
    linkedObjectsList,
    arguments: [],
    parametersList: [],
    selectedControls: { rpc: null, value: null },
    selectedLinkedObject: {},
  };
}

const EditWidgetAlarmsModal = (props) => {
  const [updateProperties] = useMutation(UPDATE_PROPERTIES_BY_IDS);
  const [updateProperty] = useMutation(UPDATE_PROPERTY);
  const useStyles = makeStyles((theme) => styles(theme));
  const classes = useStyles();

  const [showAlarm1, setShowAlarm1] = useState(true);
  const [showAlarm2, setShowAlarm2] = useState(true);
  const [showAlarm3, setShowAlarm3] = useState(true);

  let defaultValues = {};

  const [values, setValues] = useReducer(
    (prev, updated) => ({ ...prev, ...updated }),
    defaultValues
  );

  const [alarmsWeekDays, setAlarmsWeekDays] = useState({
    alarm1: week,
    alarm2: week,
    alarm3: week,
  });

  const [loadControlsList] = useLazyQuery(CONTROLS_QUERY, {
    variables: {
      objId: null,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const { controlsList } = getControlsList(data);
      const whichAlarmUserWorkingOn = values.whichAlarmUserWorkingOn;

      setValues({
        [whichAlarmUserWorkingOn]: {
          ...values[whichAlarmUserWorkingOn],
          selectedControls: controlsList.find(
            (item) =>
              item.rpc ===
              values[whichAlarmUserWorkingOn].selectedControls.value
          ),
          controlsList,
        },
      });
    },
  });

  const [loadControls] = useLazyQuery(CONTROLS_QUERY, {
    variables: {
      objId: null,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const { tmpControls, controlsList } = getControlsList(data);

      const selectedControls = controlsList[0];

      const parametersList = tmpControls
        ?.filter(
          (control) =>
            control.rpc === selectedControls.rpc &&
            control.rpc !== control.argument
        )
        ?.map((item) => ({
          title: item.description,
          value: item.id,
          argument: item.argument,
        }));

      const tempArguments = parametersList?.map((item) => ({
        argument: item.argument,
        value: "",
      }));

      const whichAlarmUserWorkingOn = values.whichAlarmUserWorkingOn;

      if (whichAlarmUserWorkingOn === null) {
        setValues({
          executeOnAlarmOnObject: {
            ...values.executeOnAlarmOnObject,
            selectedControls,
            arguments: tempArguments,
            tmpControls,
            controlsList,
            parametersList,
          },
          executeOnAlarmOffObject: {
            ...values.executeOnAlarmOffObject,
            selectedControls,
            arguments: tempArguments,
            tmpControls,
            controlsList,
            parametersList,
          },
        });
      } else {
        setValues({
          [whichAlarmUserWorkingOn]: {
            ...values[whichAlarmUserWorkingOn],
            selectedControls,
            arguments: tempArguments,
            tmpControls,
            controlsList,
            parametersList,
          },
        });
      }
    },
  });

  useEffect(() => {
    const alarmsAlerts = props.property?.find(
      (item) => item.key === "alarmsAlerts"
    );
    const alarmOptions = props.property?.find(
      (item) => item.key === "alarmsAlertsOptions"
    );
    const getPropValue = () =>
      props.isMonitor ? props.value : alarmsAlerts?.value;

    const alarmsAlert1_timeIntervalInMinutes_from =
      getPropValue("alarmsAlerts")[0]?.timeIntervalInMinutes.from;

    const alarmsAlert1_timeIntervalInMinutes_to =
      getPropValue("alarmsAlerts")[0]?.timeIntervalInMinutes.to;

    defaultValues["alarmsAlert1_condition_value"] =
      getPropValue("alarmsAlerts")[0]?.condition?.value;
    defaultValues["alarmsAlert1_condition_operator"] =
      getPropValue("alarmsAlerts")[0]?.condition?.operator;

    if (!props.isMonitor)
      defaultValues["alarmsAlert1_timeout_units"] =
        getPropValue("alarmsAlerts")[0]?.timeout.units;
    defaultValues["alarmsAlert1_timeout_value"] =
      getPropValue("alarmsAlerts")[0]?.timeout.value;

    defaultValues["alarmsAlert1_timeIntervalInMinutes_from_h"] = isNaN(
      parseInt(alarmsAlert1_timeIntervalInMinutes_from)
    )
      ? ""
      : Math.floor(alarmsAlert1_timeIntervalInMinutes_from / 60);

    defaultValues["alarmsAlert1_timeIntervalInMinutes_from_m"] = isNaN(
      parseInt(alarmsAlert1_timeIntervalInMinutes_from)
    )
      ? ""
      : Math.floor(alarmsAlert1_timeIntervalInMinutes_from % 60);

    defaultValues["alarmsAlert1_timeIntervalInMinutes_to_h"] = isNaN(
      parseInt(alarmsAlert1_timeIntervalInMinutes_to)
    )
      ? ""
      : Math.floor(alarmsAlert1_timeIntervalInMinutes_to / 60);

    defaultValues["alarmsAlert1_timeIntervalInMinutes_to_m"] = isNaN(
      parseInt(alarmsAlert1_timeIntervalInMinutes_to)
    )
      ? ""
      : Math.floor(alarmsAlert1_timeIntervalInMinutes_to % 60);

    if (!props.isMonitor) {
      defaultValues["alarmsAlert1_timer_dismiss"] =
        getPropValue("alarmsAlerts")[0]?.timer?.dismiss || false;

      defaultValues["alarmsAlert1_timer_units"] =
        getPropValue("alarmsAlerts")[0]?.timer?.units;

      defaultValues["alarmsAlert1_timer_value"] =
        getPropValue("alarmsAlerts")[0]?.timer?.value;
    }

    // Alert 2

    const alarmsAlert2_timeIntervalInMinutes_from =
      getPropValue("alarmsAlerts")[1]?.timeIntervalInMinutes.from;
    const alarmsAlert2_timeIntervalInMinutes_to =
      getPropValue("alarmsAlerts")[1]?.timeIntervalInMinutes.to;

    defaultValues["alarmsAlert2_condition_value"] =
      getPropValue("alarmsAlerts")[1]?.condition?.value;
    defaultValues["alarmsAlert2_condition_operator"] =
      getPropValue("alarmsAlerts")[1]?.condition?.operator;

    if (!props.isMonitor) {
      defaultValues["alarmsAlert2_timeout_units"] =
        getPropValue("alarmsAlerts")[1]?.timeout.units;
      defaultValues["alarmsAlert2_timeout_value"] =
        getPropValue("alarmsAlerts")[1]?.timeout.value;
    }

    defaultValues["alarmsAlert2_timeIntervalInMinutes_from_h"] = isNaN(
      parseInt(alarmsAlert2_timeIntervalInMinutes_from)
    )
      ? ""
      : Math.floor(alarmsAlert2_timeIntervalInMinutes_from / 60);

    defaultValues["alarmsAlert2_timeIntervalInMinutes_from_m"] = isNaN(
      parseInt(alarmsAlert2_timeIntervalInMinutes_from)
    )
      ? ""
      : Math.floor(alarmsAlert2_timeIntervalInMinutes_from % 60);

    defaultValues["alarmsAlert2_timeIntervalInMinutes_to_h"] = isNaN(
      parseInt(alarmsAlert2_timeIntervalInMinutes_to)
    )
      ? ""
      : Math.floor(alarmsAlert2_timeIntervalInMinutes_to / 60);

    defaultValues["alarmsAlert2_timeIntervalInMinutes_to_m"] = isNaN(
      parseInt(alarmsAlert2_timeIntervalInMinutes_to)
    )
      ? ""
      : Math.floor(alarmsAlert2_timeIntervalInMinutes_to % 60);

    if (!props.isMonitor) {
      defaultValues["alarmsAlert2_timer_dismiss"] =
        getPropValue("alarmsAlerts")[1]?.timer?.dismiss || false;

      defaultValues["alarmsAlert2_timer_units"] =
        getPropValue("alarmsAlerts")[1]?.timer?.units;
      defaultValues["alarmsAlert2_timer_value"] =
        getPropValue("alarmsAlerts")[1]?.timer?.value;
    }

    // Alert 3
    const alarmsAlert3_timeIntervalInMinutes_from =
      getPropValue("alarmsAlerts")[2]?.timeIntervalInMinutes.from;

    const alarmsAlert3_timeIntervalInMinutes_to =
      getPropValue("alarmsAlerts")[2]?.timeIntervalInMinutes.to;

    defaultValues["alarmsAlert3_condition_value"] =
      getPropValue("alarmsAlerts")[2]?.condition?.value;
    defaultValues["alarmsAlert3_condition_operator"] =
      getPropValue("alarmsAlerts")[2]?.condition?.operator;

    if (!props.isMonitor) {
      defaultValues["alarmsAlert3_timeout_units"] =
        getPropValue("alarmsAlerts")[2]?.timeout.units;
      defaultValues["alarmsAlert3_timeout_value"] =
        getPropValue("alarmsAlerts")[2]?.timeout.value;
    }

    defaultValues["alarmsAlert3_timeIntervalInMinutes_from_h"] = isNaN(
      parseInt(alarmsAlert3_timeIntervalInMinutes_from)
    )
      ? ""
      : Math.floor(alarmsAlert3_timeIntervalInMinutes_from / 60);

    defaultValues["alarmsAlert3_timeIntervalInMinutes_from_m"] = isNaN(
      parseInt(alarmsAlert3_timeIntervalInMinutes_from)
    )
      ? ""
      : Math.floor(alarmsAlert3_timeIntervalInMinutes_from % 60);

    defaultValues["alarmsAlert3_timeIntervalInMinutes_to_h"] = isNaN(
      parseInt(alarmsAlert3_timeIntervalInMinutes_to)
    )
      ? ""
      : Math.floor(alarmsAlert3_timeIntervalInMinutes_to / 60);

    defaultValues["alarmsAlert3_timeIntervalInMinutes_to_m"] = isNaN(
      parseInt(alarmsAlert3_timeIntervalInMinutes_to)
    )
      ? ""
      : Math.floor(alarmsAlert3_timeIntervalInMinutes_to % 60);

    if (!props.isMonitor) {
      defaultValues["alarmsAlert3_timer_dismiss"] =
        getPropValue("alarmsAlerts")[2]?.timer?.dismiss || false;

      defaultValues["alarmsAlert3_timer_units"] =
        getPropValue("alarmsAlerts")[2]?.timer?.units;
      defaultValues["alarmsAlert3_timer_value"] =
        getPropValue("alarmsAlerts")[2]?.timer?.value;
    }

    const alarmAlerts1WeekDays =
      getPropValue("alarmsAlerts")[0]?.weekDays || alarmsWeekDays.alarm1;
    const alarmAlerts2WeekDays =
      getPropValue("alarmsAlerts")[1]?.weekDays || alarmsWeekDays.alarm2;
    const alarmAlerts3WeekDays =
      getPropValue("alarmsAlerts")[2]?.weekDays || alarmsWeekDays.alarm3;

    setAlarmsWeekDays({
      alarm1: alarmAlerts1WeekDays,
      alarm2: alarmAlerts2WeekDays,
      alarm3: alarmAlerts3WeekDays,
    });

    // Conditions for showing alarms

    const showAlarm1Condition = Boolean(
      getPropValue()?.length === 0
        ? false
        : alarmsAlert1_timeIntervalInMinutes_from !== "" ||
            alarmsAlert1_timeIntervalInMinutes_to !== "" ||
            getPropValue("alarmsAlerts")[0]?.timeout.value !== '' ||
            !isEqual(sortBy(alarmAlerts1WeekDays), week)
    );
    setShowAlarm1(showAlarm1Condition);
    const showAlarm2Condition = Boolean(
      getPropValue()?.length === 0
        ? false
        : alarmsAlert2_timeIntervalInMinutes_from !== "" ||
            alarmsAlert2_timeIntervalInMinutes_to !== "" ||
            getPropValue("alarmsAlerts")[1]?.timeout.value !== '' ||
            !isEqual(sortBy(alarmAlerts2WeekDays), week)
    );
    setShowAlarm2(showAlarm2Condition);
    const showAlarm3Condition = Boolean(
      getPropValue()?.length === 0
        ? false
        : alarmsAlert3_timeIntervalInMinutes_from !== "" ||
            alarmsAlert3_timeIntervalInMinutes_to !== "" ||
            getPropValue("alarmsAlerts")[2]?.timeout.value !== '' ||
            !isEqual(sortBy(alarmAlerts3WeekDays), week)
    );
    setShowAlarm3(showAlarm3Condition);

    // Options
    if (!props.isMonitor) {
      const getPropValueOptions = () => alarmOptions.value;

      const linkedObjectsList = props.linkedObjects?.map(({ object2 }) => ({
        value: object2.id,
        title: object2.name,
      }));

      const on = getPropValueOptions().on;
      const off = getPropValueOptions().off;

      const selectedLinkedObjectOn = linkedObjectsList.find(
        (item) => item.value === on.linkedObject.value
      );
      const selectedLinkedObjectOff = linkedObjectsList.find(
        (item) => item.value === off.linkedObject.value
      );

      defaultValues["executeOnAlarmOn"] = !isEmpty(selectedLinkedObjectOn);

      defaultValues["executeOnAlarmOff"] = !isEmpty(selectedLinkedObjectOff);
      defaultValues["optionalTags"] = getPropValueOptions().tags;
      defaultValues["whichAlarmUserWorkingOn"] = null;

      setValues(defaultValues); // loaded values for each field.

      // TODO: Refactor
      if (linkedObjectsList?.length === 0) {
        updateProperty({
          variables: {
            input: {
              patch: {
                value: {
                  propertyId: props.property.find(
                    (item) => item.key === "alarmsAlertsOptions"
                  ).id,
                  value: {
                    tags: values.optionalTags,
                    on: {
                      controls: {
                        rpc: null,
                        value: null,
                      },
                      arguments: [],
                      linkedObject: {
                        value: null,
                      },
                    },
                    off: {
                      controls: {
                        rpc: null,
                        value: null,
                      },
                      arguments: [],
                      linkedObject: {
                        value: null,
                      },
                    },
                  },
                },
              },
              id: props.property.find(
                (item) => item.key === "alarmsAlertsOptions"
              ).id,
            },
          },
        });
        setValues({
          executeOnAlarmOnObject: alarmOptionsInitialValue([]),
          executeOnAlarmOffObject: alarmOptionsInitialValue([]),
        });
      } else {
        setValues({
          executeOnAlarmOnObject: {
            selectedLinkedObject: selectedLinkedObjectOn || {},
            linkedObjectsList,
            selectedControls: isEmpty(selectedLinkedObjectOn)
              ? {
                  rpc: null,
                  value: null,
                }
              : on.controls,
            parametersList: isEmpty(selectedLinkedObjectOn) ? [] : on.arguments,
            arguments: isEmpty(selectedLinkedObjectOn) ? [] : on.arguments,
          },
          executeOnAlarmOffObject: {
            selectedLinkedObject: selectedLinkedObjectOff || {},
            linkedObjectsList,
            parametersList: isEmpty(selectedLinkedObjectOff)
              ? []
              : off.arguments,
            selectedControls: isEmpty(selectedLinkedObjectOff)
              ? {
                  rpc: null,
                  value: null,
                }
              : off.controls,
            arguments: isEmpty(selectedLinkedObjectOff) ? [] : off.arguments,
          },
        });

        setValues({
          whichAlarmUserWorkingOn: "executeOnAlarmOnObject",
        });
        loadControlsList({
          variables: { objId: selectedLinkedObjectOn?.value || "" },
        }).then(() => {
          setValues({
            whichAlarmUserWorkingOn: "executeOnAlarmOffObject",
          });
          loadControlsList({
            variables: { objId: selectedLinkedObjectOff?.value || "" },
          }).then();
        });
      }
    }
  }, []);

  const submit = (...params) => props.onResolve(params);
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleUpdateWidgetProps = (valuesReady, alarmsAlertsOptions) => {
    const value = props.handlePrepareData
      ? props.handlePrepareData(valuesReady[0].value)
      : valuesReady[0].value;

    //updateProperties

    const promise = props.isMonitor
      ? updateProperty({
          variables: {
            input: {
              id: props.propertyId,
              patch: {
                value: value,
              },
            },
          },
        })
      : updateProperties({
          variables: {
            input: {
              propertiesArray: [
                {
                  propertyId: props.property.find(
                    (item) => item.key === "alarmsAlerts"
                  ).id,
                  value,
                },
                alarmsAlertsOptions,
              ],
            },
          },
        });

    toast
      .promise(
        promise,
        {
          loading: "Updating...",
          success: () => "Updated",
          error: (err) => `${err.toString()}`,
        },
        {
          position: "bottom-center",
        }
      )
      .then(() => {
        submit();
      });
  };
  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    if (checked) value = checked;

    setValues({ [name]: value });
  };

  const handleLinkedObjects = async (e, onOrOff) => {
    const whichAlarm = alarmOnOrOffCondition(onOrOff);

    let { name, value } = e.target;
    let selectedLinkedObject = values[whichAlarm].linkedObjectsList.find(
      (item) => item.value === value
    );

    setValues({
      [whichAlarm]: {
        ...values[whichAlarm],
        selectedLinkedObject,
      },
      whichAlarmUserWorkingOn: whichAlarm,
    });

    await loadControls({
      variables: { objId: selectedLinkedObject.value },
    }).then(() => {});
  };

  const handleControls = (e, onOrOff) => {
    const whichAlarm = alarmOnOrOffCondition(onOrOff);
    let { name, value } = e.target;

    let selectedControls = values[whichAlarm].controlsList.find(
      (item) => item.value === value
    );

    const parametersList = values[whichAlarm].tmpControls
      ?.filter(
        (control) =>
          control.rpc === selectedControls.rpc &&
          control.rpc !== control.argument
      )
      ?.map((item) => ({
        title: item.description,
        value: item.id,
        argument: item.argument,
      }));

    const tempArguments = parametersList?.map((item) => ({
      argument: item.argument,
      value: "",
    }));

    setValues({
      [whichAlarm]: {
        ...values[whichAlarm],
        selectedControls,
        parametersList,
        arguments: tempArguments,
      },
    });
  };

  const handleParameters = (e, onOrOff) => {
    const whichAlarm = alarmOnOrOffCondition(onOrOff);
    let { name, value } = e.target;

    const tempArguments = values[whichAlarm].arguments?.map((item) => {
      if (item.argument === name) {
        return { argument: name, value };
      }
      return item;
    });

    setValues({
      [whichAlarm]: {
        ...values[whichAlarm],
        arguments: tempArguments,
      },
    });
  };

  if (Object.keys(values).length === 0) return false; //to prevent react warning ("uncontrolled components...")

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={msg.editWidgetAlarmsModal.alarms}
        handleClose={handleClose}
        buttons={
          <>
            <Button
              onClick={handleClose}
              data-test-edit-alarm="WidgetAlarmCancel"
            >
              {msg.editWidgetModal.buttonCancel}
            </Button>
            <Button
              data-test-save-alarm="WidgetAlarmSave"
              color="primary"
              onClick={() => {
                const alarmsAlert1_timeIntervalInMinutes_from =
                  parseInt(values.alarmsAlert1_timeIntervalInMinutes_from_h) *
                    60 +
                  parseInt(values.alarmsAlert1_timeIntervalInMinutes_from_m);

                const alarmsAlert1_timeIntervalInMinutes_to =
                  parseInt(values.alarmsAlert1_timeIntervalInMinutes_to_h) *
                    60 +
                  parseInt(values.alarmsAlert1_timeIntervalInMinutes_to_m);

                const alarmsAlert2_timeIntervalInMinutes_from =
                  parseInt(values.alarmsAlert2_timeIntervalInMinutes_from_h) *
                    60 +
                  parseInt(values.alarmsAlert2_timeIntervalInMinutes_from_m);

                const alarmsAlert2_timeIntervalInMinutes_to =
                  parseInt(values.alarmsAlert2_timeIntervalInMinutes_to_h) *
                    60 +
                  parseInt(values.alarmsAlert2_timeIntervalInMinutes_to_m);

                const alarmsAlert3_timeIntervalInMinutes_from =
                  parseInt(values.alarmsAlert3_timeIntervalInMinutes_from_h) *
                    60 +
                  parseInt(values.alarmsAlert3_timeIntervalInMinutes_from_m);

                const alarmsAlert3_timeIntervalInMinutes_to =
                  parseInt(values.alarmsAlert3_timeIntervalInMinutes_to_h) *
                    60 +
                  parseInt(values.alarmsAlert3_timeIntervalInMinutes_to_m);
                const executeOnAlarmOnObject = values.executeOnAlarmOnObject;
                const executeOnAlarmOffObject = values.executeOnAlarmOffObject;
                // merge some values together into one json property
                const valuesReady = [
                  {
                    propertyKey: "alarmsAlerts",

                    value: [
                      {
                        condition: {
                          operator: values.alarmsAlert1_condition_operator,
                          value: values.alarmsAlert1_condition_value,
                        },
                        timeout: {
                          units: values.alarmsAlert1_timeout_units,
                          value: isNaN(
                            parseInt(values.alarmsAlert1_timeout_value)
                          )
                            ? ""
                            : parseInt(values.alarmsAlert1_timeout_value),
                        },
                        timeIntervalInMinutes: {
                          from: isNaN(alarmsAlert1_timeIntervalInMinutes_from)
                            ? ""
                            : alarmsAlert1_timeIntervalInMinutes_from,

                          to: isNaN(alarmsAlert1_timeIntervalInMinutes_to)
                            ? ""
                            : alarmsAlert1_timeIntervalInMinutes_to,
                        },
                        weekDays: alarmsWeekDays.alarm1,
                        timer: {
                          dismiss: values.alarmsAlert1_timer_dismiss,
                          units: values.alarmsAlert1_timer_units,
                          value: isNaN(
                            parseInt(values.alarmsAlert1_timer_value)
                          )
                            ? ""
                            : parseInt(values.alarmsAlert1_timer_value),
                        },
                      },

                      {
                        condition: {
                          operator: values.alarmsAlert2_condition_operator,
                          value: values.alarmsAlert2_condition_value,
                        },
                        timeout: {
                          units: values.alarmsAlert2_timeout_units,
                          value: isNaN(
                            parseInt(values.alarmsAlert2_timeout_value)
                          )
                            ? ""
                            : parseInt(values.alarmsAlert2_timeout_value),
                        },
                        timeIntervalInMinutes: {
                          from: isNaN(alarmsAlert2_timeIntervalInMinutes_from)
                            ? ""
                            : alarmsAlert2_timeIntervalInMinutes_from,

                          to: isNaN(alarmsAlert2_timeIntervalInMinutes_to)
                            ? ""
                            : alarmsAlert2_timeIntervalInMinutes_to,
                        },
                        weekDays: alarmsWeekDays.alarm2,
                        timer: {
                          dismiss: values.alarmsAlert2_timer_dismiss,
                          units: values.alarmsAlert2_timer_units,
                          value: isNaN(values.alarmsAlert2_timer_value)
                            ? ""
                            : parseInt(values.alarmsAlert2_timer_value),
                        },
                      },
                      {
                        condition: {
                          operator: values.alarmsAlert3_condition_operator,
                          value: values.alarmsAlert3_condition_value,
                        },
                        timeout: {
                          units: values.alarmsAlert3_timeout_units,
                          value: isNaN(
                            parseInt(values.alarmsAlert3_timeout_value)
                          )
                            ? ""
                            : parseInt(values.alarmsAlert3_timeout_value),
                        },
                        timeIntervalInMinutes: {
                          from: isNaN(alarmsAlert3_timeIntervalInMinutes_from)
                            ? ""
                            : alarmsAlert3_timeIntervalInMinutes_from,

                          to: isNaN(alarmsAlert3_timeIntervalInMinutes_to)
                            ? ""
                            : alarmsAlert3_timeIntervalInMinutes_to,
                        },
                        weekDays: alarmsWeekDays.alarm3,
                        timer: {
                          dismiss: values.alarmsAlert3_timer_dismiss,
                          units: values.alarmsAlert3_timer_units,
                          value: isNaN(
                            parseInt(values.alarmsAlert3_timer_value)
                          )
                            ? ""
                            : parseInt(values.alarmsAlert3_timer_value),
                        },
                      },
                    ],
                  },
                ];

                if (!props.isMonitor) {
                  const alarmsAlertsOptions = {
                    propertyId: props.property.find(
                      (item) => item.key === "alarmsAlertsOptions"
                    ).id,
                    value: {
                      tags: values.optionalTags,
                      on: {
                        controls: {
                          rpc: executeOnAlarmOnObject.selectedControls?.rpc,
                          value: executeOnAlarmOnObject.selectedControls?.rpc,
                        },
                        arguments: executeOnAlarmOnObject.arguments,
                        linkedObject: {
                          value:
                            executeOnAlarmOnObject.selectedLinkedObject?.value,
                        },
                      },
                      off: {
                        controls: {
                          rpc: executeOnAlarmOffObject.selectedControls?.rpc,
                          value: executeOnAlarmOffObject.selectedControls?.rpc,
                        },
                        arguments: executeOnAlarmOffObject.arguments,
                        linkedObject: {
                          value:
                            executeOnAlarmOffObject.selectedLinkedObject.value,
                        },
                      },
                    },
                  };
                  handleUpdateWidgetProps(valuesReady, alarmsAlertsOptions);
                  return;
                }

                handleUpdateWidgetProps(valuesReady);
              }}
            >
              {msg.editWidgetModal.buttonUpdate}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          {/* alert 1 */}

          <Grid item>
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.alertHeadline}
            >{`${msg.editWidgetAlarmsModal.alert} 1`}</Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.alertLabel}
            >
              Alarm condition
            </Typography>
          </Grid>

          <Grid item container>
            <Grid item xs={6} style={{ paddingRight: "10px" }}>
              <CustomSelect
                clearFieldIcon={true}
                name="alarmsAlert1_condition_operator"
                list={[
                  { value: ">", title: ">" },
                  { value: "<", title: "<" },
                  { value: "=", title: "=" },
                  { value: "!=", title: "!=" },
                  {
                    value: "contains",
                    title: msg.editWidgetAlarmsModal.contains,
                  },
                ]}
                value={values["alarmsAlert1_condition_operator"] ?? ""}
                onChange={handleInputChange}
                data-test-alarm-condition="alarmConditionInput1"
              />
            </Grid>
            <Grid item container xs={6} alignContent="flex-end">
              <CustomInput
                name="alarmsAlert1_condition_value"
                label="&nbsp;"
                clearFieldIcon={true}
                value={values["alarmsAlert1_condition_value"] ?? ""}
                onChange={handleInputChange}
                type="text"
                data-test-alarm-condition="alarmConditionInput2"
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <CustomSwitch
              name="alarm1TimeIntervalToggle"
              label="Timer and schedule"
              value={showAlarm1}
              onChange={() => {
                if (showAlarm1) {
                  setValues({
                    alarmsAlert1_timeIntervalInMinutes_from_h: "",
                    alarmsAlert1_timeIntervalInMinutes_from_m: "",
                    alarmsAlert1_timeIntervalInMinutes_to_h: "",
                    alarmsAlert1_timeIntervalInMinutes_to_m: "",
                  });
                  setAlarmsWeekDays({
                    ...alarmsWeekDays,
                    alarm1: week,
                  });
                }
                setShowAlarm1((prev) => !prev);
              }}
            />
          </Grid>

          {showAlarm1 && (
            <>
              {!props.isMonitor && (
                <>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      className={classes.alertLabel}
                    >
                      {msg.editWidgetAlarmsModal.allowedTime}
                    </Typography>
                  </Grid>

                  <Grid item container>
                    <Grid
                      item
                      container
                      xs={6}
                      style={{ paddingRight: "10px", marginTop: "16px" }}
                    >
                      <CustomInput
                        name="alarmsAlert1_timeout_value"
                        clearFieldIcon={true}
                        value={values["alarmsAlert1_timeout_value"] ?? ""}
                        onChange={handleInputChange}
                        type="number"
                        data-test-alarm-timeout="alarmTimeoutInput1"
                      />
                    </Grid>
                    <Grid item container xs={6}>
                      <CustomSelect
                        clearFieldIcon={true}
                        name="alarmsAlert1_timeout_units"
                        list={[
                          {
                            value: "minutes",
                            title: msg.editWidgetAlarmsModal.minutes,
                          },
                          {
                            value: "seconds",
                            title: msg.editWidgetAlarmsModal.seconds,
                          },
                        ]}
                        value={values["alarmsAlert1_timeout_units"] ?? ""}
                        onChange={handleInputChange}
                        data-test-alarm-timeout="alarmTimeoutInput2"
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid item>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  className={classes.alertLabel}
                >
                  {msg.editWidgetAlarmsModal.alarmTimeInterval}
                </Typography>
              </Grid>

              {/* from h,m */}

              <Grid item container>
                <Grid item container xs={2} alignItems="flex-end">
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    className={classes.alertLabel}
                  >
                    {msg.editWidgetAlarmsModal.from}
                  </Typography>
                </Grid>

                <Grid item xs={5} style={{ paddingRight: "10px" }}>
                  <CustomSelect
                    clearFieldIcon={true}
                    name="alarmsAlert1_timeIntervalInMinutes_from_h"
                    label=""
                    list={twentyFourHours}
                    value={
                      values["alarmsAlert1_timeIntervalInMinutes_from_h"] ?? ""
                    }
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={5} style={{ paddingRight: "10px" }}>
                  <CustomSelect
                    clearFieldIcon={true}
                    name="alarmsAlert1_timeIntervalInMinutes_from_m"
                    label=""
                    list={sixtyMinutes}
                    value={
                      values["alarmsAlert1_timeIntervalInMinutes_from_m"] ?? ""
                    }
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>

              {/* to h,m */}

              <Grid item container>
                <Grid item container xs={2} alignItems="flex-end">
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    className={classes.alertLabel}
                  >
                    {msg.editWidgetAlarmsModal.to}
                  </Typography>
                </Grid>

                <Grid item xs={5} style={{ paddingRight: "10px" }}>
                  <CustomSelect
                    clearFieldIcon={true}
                    name="alarmsAlert1_timeIntervalInMinutes_to_h"
                    list={twentyFourHours}
                    value={
                      values["alarmsAlert1_timeIntervalInMinutes_to_h"] ?? ""
                    }
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={5} style={{ paddingRight: "10px" }}>
                  <CustomSelect
                    clearFieldIcon={true}
                    name="alarmsAlert1_timeIntervalInMinutes_to_m"
                    list={sixtyMinutes}
                    value={
                      values["alarmsAlert1_timeIntervalInMinutes_to_m"] ?? ""
                    }
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>

              <Grid item>
                <ToggleButtonGroup
                  fullWidth
                  color="primary"
                  value={alarmsWeekDays.alarm1}
                  onChange={(e) => {
                    const value = +e.target.value;
                    if (alarmsWeekDays.alarm1.includes(value)) {
                      setAlarmsWeekDays({
                        ...alarmsWeekDays,
                        alarm1: alarmsWeekDays.alarm1.filter(
                          (item) => +item !== +value
                        ),
                      });
                    } else {
                      setAlarmsWeekDays({
                        ...alarmsWeekDays,
                        alarm1: [...alarmsWeekDays.alarm1, +value],
                      });
                    }
                  }}
                >
                  {daysOfWeek.map((day, index) => (
                    <ToggleButton fullWidth value={index}>
                      {day}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
            </>
          )}

          {!props.isMonitor && (
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <CustomSwitch
                name="alarmsAlert1_timer_dismiss"
                label={"Dismiss by timer"}
                value={values["alarmsAlert1_timer_dismiss"] ?? false}
                onChange={handleInputChange}
              />
            </Grid>
          )}
          {values["alarmsAlert1_timer_dismiss"] && (
            <Grid item container>
              <Grid
                item
                container
                xs={6}
                style={{ paddingRight: "10px", marginTop: "16px" }}
                alignContent="flex-end"
              >
                <CustomInput
                  name="alarmsAlert1_timer_value"
                  clearFieldIcon={true}
                  value={values["alarmsAlert1_timer_value"] ?? ""}
                  onChange={handleInputChange}
                  type="number"
                />
              </Grid>

              <Grid item xs={6}>
                <CustomSelect
                  clearFieldIcon={true}
                  name="alarmsAlert1_timer_units"
                  list={[
                    {
                      value: "minutes",
                      title: msg.editWidgetAlarmsModal.minutes,
                    },
                    {
                      value: "seconds",
                      title: msg.editWidgetAlarmsModal.seconds,
                    },
                  ]}
                  value={values["alarmsAlert1_timer_units"] ?? ""}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          )}

          {/* alert 2 */}
          <Grid item>
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.alertHeadline}
            >
              {`${msg.editWidgetAlarmsModal.alert} 2`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.alertLabel}
            >
              Alarm condition
            </Typography>
          </Grid>
          <Grid item container>
            <Grid item xs={6} style={{ paddingRight: "10px" }}>
              <CustomSelect
                name="alarmsAlert2_condition_operator"
                clearFieldIcon={true}
                list={[
                  { value: ">", title: ">" },
                  { value: "<", title: "<" },
                  { value: "=", title: "=" },
                  { value: "!=", title: "!=" },
                  {
                    value: "contains",
                    title: msg.editWidgetAlarmsModal.contains,
                  },
                ]}
                value={values["alarmsAlert2_condition_operator"] ?? ""}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item container xs={6} alignContent="flex-end">
              <CustomInput
                name="alarmsAlert2_condition_value"
                clearFieldIcon={true}
                label="&nbsp;"
                value={values["alarmsAlert2_condition_value"] ?? ""}
                onChange={handleInputChange}
                type="text"
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <CustomSwitch
              name="alarm2TimeIntervalToggle"
              label="Timer and schedule"
              value={showAlarm2}
              onChange={() => {
                if (showAlarm2) {
                  setValues({
                    alarmsAlert2_timeIntervalInMinutes_from_h: "",
                    alarmsAlert2_timeIntervalInMinutes_from_m: "",
                    alarmsAlert2_timeIntervalInMinutes_to_h: "",
                    alarmsAlert2_timeIntervalInMinutes_to_m: "",
                  });
                  setAlarmsWeekDays({
                    ...alarmsWeekDays,
                    alarm2: week,
                  });
                }
                setShowAlarm2((prev) => !prev);
              }}
            />
          </Grid>
          {showAlarm2 && (
            <>
              {!props.isMonitor && (
                <>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      className={classes.alertLabel}
                    >
                      {msg.editWidgetAlarmsModal.allowedTime}
                    </Typography>
                  </Grid>

                  <Grid item container>
                    <Grid
                      container
                      xs={6}
                      style={{ paddingRight: "10px", marginTop: "16px" }}
                      alignContent="flex-end"
                    >
                      <CustomInput
                        name="alarmsAlert2_timeout_value"
                        label=""
                        clearFieldIcon={true}
                        value={values["alarmsAlert2_timeout_value"] ?? ""}
                        onChange={handleInputChange}
                        type="number"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <CustomSelect
                        clearFieldIcon={true}
                        name="alarmsAlert2_timeout_units"
                        label=""
                        list={[
                          {
                            value: "minutes",
                            title: msg.editWidgetAlarmsModal.minutes,
                          },
                          {
                            value: "seconds",
                            title: msg.editWidgetAlarmsModal.seconds,
                          },
                        ]}
                        value={values["alarmsAlert2_timeout_units"] ?? ""}
                        onChange={handleInputChange}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid item>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  className={classes.alertLabel}
                >
                  {msg.editWidgetAlarmsModal.alarmTimeInterval}
                </Typography>
              </Grid>
              {/* from h,m */}

              <Grid item container>
                <Grid item container xs={2} alignItems="flex-end">
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    className={classes.alertLabel}
                  >
                    {msg.editWidgetAlarmsModal.from}
                  </Typography>
                </Grid>

                <Grid item xs={5} style={{ paddingRight: "10px" }}>
                  <CustomSelect
                    clearFieldIcon={true}
                    name="alarmsAlert2_timeIntervalInMinutes_from_h"
                    label=""
                    list={twentyFourHours}
                    value={
                      values["alarmsAlert2_timeIntervalInMinutes_from_h"] ??
                      null
                    }
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={5} style={{ paddingRight: "10px" }}>
                  <CustomSelect
                    clearFieldIcon={true}
                    name="alarmsAlert2_timeIntervalInMinutes_from_m"
                    label=""
                    list={sixtyMinutes}
                    value={
                      values["alarmsAlert2_timeIntervalInMinutes_from_m"] ?? ""
                    }
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>

              {/* to h,m */}

              <Grid item container>
                <Grid item container xs={2} alignItems="flex-end">
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    className={classes.alertLabel}
                  >
                    {msg.editWidgetAlarmsModal.to}
                  </Typography>
                </Grid>

                <Grid item xs={5} style={{ paddingRight: "10px" }}>
                  <CustomSelect
                    name="alarmsAlert2_timeIntervalInMinutes_to_h"
                    label=""
                    clearFieldIcon={true}
                    list={twentyFourHours}
                    value={
                      values["alarmsAlert2_timeIntervalInMinutes_to_h"] ?? ""
                    }
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={5} style={{ paddingRight: "10px" }}>
                  <CustomSelect
                    name="alarmsAlert2_timeIntervalInMinutes_to_m"
                    label=""
                    clearFieldIcon={true}
                    list={sixtyMinutes}
                    value={
                      values["alarmsAlert2_timeIntervalInMinutes_to_m"] ?? ""
                    }
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <ToggleButtonGroup
                  fullWidth
                  color="primary"
                  value={alarmsWeekDays.alarm2}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (alarmsWeekDays.alarm2.includes(value)) {
                      setAlarmsWeekDays({
                        ...alarmsWeekDays,
                        alarm2: alarmsWeekDays.alarm2.filter(
                          (item) => +item !== +value
                        ),
                      });
                    } else {
                      setAlarmsWeekDays({
                        ...alarmsWeekDays,
                        alarm2: [...alarmsWeekDays.alarm2, +value],
                      });
                    }
                  }}
                >
                  {daysOfWeek.map((day, index) => (
                    <ToggleButton fullWidth value={index}>
                      {day}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
            </>
          )}

          {!props.isMonitor && (
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <CustomSwitch
                name="alarmsAlert2_timer_dismiss"
                label={"Dismiss by timer"}
                value={values["alarmsAlert2_timer_dismiss"] ?? false}
                onChange={handleInputChange}
              />
            </Grid>
          )}
          {values["alarmsAlert2_timer_dismiss"] && (
            <Grid item container>
              <Grid
                item
                container
                xs={6}
                style={{ paddingRight: "10px", marginTop: "16px" }}
                alignContent="flex-end"
              >
                <CustomInput
                  name="alarmsAlert2_timer_value"
                  clearFieldIcon={true}
                  value={values["alarmsAlert2_timer_value"] ?? ""}
                  onChange={handleInputChange}
                  type="number"
                />
              </Grid>

              <Grid item xs={6}>
                <CustomSelect
                  clearFieldIcon={true}
                  name="alarmsAlert2_timer_units"
                  list={[
                    {
                      value: "minutes",
                      title: msg.editWidgetAlarmsModal.minutes,
                    },
                    {
                      value: "seconds",
                      title: msg.editWidgetAlarmsModal.seconds,
                    },
                  ]}
                  value={values["alarmsAlert2_timer_units"] ?? ""}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          )}
          {/* alert 3 */}

          <Grid item>
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.alertHeadline}
            >
              {`${msg.editWidgetAlarmsModal.alert} 3`}
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.alertLabel}
            >
              {msg.editWidgetAlarmsModal.alarmCondition}
            </Typography>
          </Grid>

          <Grid item container>
            <Grid item xs={6} style={{ paddingRight: "10px" }}>
              <CustomSelect
                name="alarmsAlert3_condition_operator"
                clearFieldIcon={true}
                list={[
                  { value: ">", title: ">" },
                  { value: "<", title: "<" },
                  { value: "=", title: "=" },
                  { value: "!=", title: "!=" },
                  {
                    value: "contains",
                    title: msg.editWidgetAlarmsModal.contains,
                  },
                ]}
                value={values["alarmsAlert3_condition_operator"] ?? ""}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item container xs={6} alignContent="flex-end">
              <CustomInput
                name="alarmsAlert3_condition_value"
                label="&nbsp;"
                clearFieldIcon={true}
                value={values["alarmsAlert3_condition_value"] ?? ""}
                onChange={handleInputChange}
                type="text"
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <CustomSwitch
              name="alarm3TimeIntervalToggle"
              label="Timer and schedule"
              value={showAlarm3}
              onChange={() => {
                if (showAlarm3) {
                  setValues({
                    alarmsAlert3_timeIntervalInMinutes_from_h: "",
                    alarmsAlert3_timeIntervalInMinutes_from_m: "",
                    alarmsAlert3_timeIntervalInMinutes_to_h: "",
                    alarmsAlert3_timeIntervalInMinutes_to_m: "",
                  });
                  setAlarmsWeekDays({
                    ...alarmsWeekDays,
                    alarm3: week,
                  });
                }
                setShowAlarm3((prev) => !prev);
              }}
            />
          </Grid>

          {showAlarm3 && (
            <>
              {!props.isMonitor && (
                <>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      className={classes.alertLabel}
                    >
                      {msg.editWidgetAlarmsModal.allowedTime}
                    </Typography>
                  </Grid>
                  <Grid item container>
                    <Grid
                      container
                      xs={6}
                      style={{ paddingRight: "10px", marginTop: "16px" }}
                      alignContent="flex-end"
                    >
                      <CustomInput
                        name="alarmsAlert3_timeout_value"
                        label=""
                        clearFieldIcon={true}
                        value={values["alarmsAlert3_timeout_value"] ?? ""}
                        onChange={handleInputChange}
                        type="number"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <CustomSelect
                        clearFieldIcon={true}
                        name="alarmsAlert3_timeout_units"
                        label=""
                        list={[
                          {
                            value: "minutes",
                            title: msg.editWidgetAlarmsModal.minutes,
                          },
                          {
                            value: "seconds",
                            title: msg.editWidgetAlarmsModal.seconds,
                          },
                        ]}
                        value={values["alarmsAlert3_timeout_units"] ?? ""}
                        onChange={handleInputChange}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid item>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  className={classes.alertLabel}
                >
                  {msg.editWidgetAlarmsModal.alarmTimeInterval}
                </Typography>
              </Grid>

              {/* from h,m */}

              <Grid item container>
                <Grid item container xs={2} alignItems="flex-end">
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    className={classes.alertLabel}
                  >
                    {msg.editWidgetAlarmsModal.from}
                  </Typography>
                </Grid>

                <Grid item xs={5} style={{ paddingRight: "10px" }}>
                  <CustomSelect
                    clearFieldIcon={true}
                    name="alarmsAlert3_timeIntervalInMinutes_from_h"
                    label=""
                    list={twentyFourHours}
                    value={
                      values["alarmsAlert3_timeIntervalInMinutes_from_h"] ?? ""
                    }
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={5} style={{ paddingRight: "10px" }}>
                  <CustomSelect
                    clearFieldIcon={true}
                    name="alarmsAlert3_timeIntervalInMinutes_from_m"
                    label=""
                    list={sixtyMinutes}
                    value={
                      values["alarmsAlert3_timeIntervalInMinutes_from_m"] ?? ""
                    }
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>

              {/* to h,m */}

              <Grid item container>
                <Grid item container xs={2} alignItems="flex-end">
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    className={classes.alertLabel}
                  >
                    {msg.editWidgetAlarmsModal.to}
                  </Typography>
                </Grid>

                <Grid item xs={5} style={{ paddingRight: "10px" }}>
                  <CustomSelect
                    clearFieldIcon={true}
                    name="alarmsAlert3_timeIntervalInMinutes_to_h"
                    label=""
                    list={twentyFourHours}
                    value={
                      values["alarmsAlert3_timeIntervalInMinutes_to_h"] ?? ""
                    }
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={5} style={{ paddingRight: "10px" }}>
                  <CustomSelect
                    clearFieldIcon={true}
                    name="alarmsAlert3_timeIntervalInMinutes_to_m"
                    label=""
                    list={sixtyMinutes}
                    value={
                      values["alarmsAlert3_timeIntervalInMinutes_to_m"] ?? ""
                    }
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>

              <Grid item>
                <ToggleButtonGroup
                  fullWidth
                  color="primary"
                  value={alarmsWeekDays.alarm3}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (alarmsWeekDays.alarm3.includes(value)) {
                      setAlarmsWeekDays({
                        ...alarmsWeekDays,
                        alarm3: alarmsWeekDays.alarm3.filter(
                          (item) => +item !== +value
                        ),
                      });
                    } else {
                      setAlarmsWeekDays({
                        ...alarmsWeekDays,
                        alarm3: [...alarmsWeekDays.alarm3, +value],
                      });
                    }
                  }}
                >
                  {daysOfWeek.map((day, index) => (
                    <ToggleButton fullWidth value={index}>
                      {day}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
            </>
          )}

          {!props.isMonitor && (
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <CustomSwitch
                name="alarmsAlert3_timer_dismiss"
                label={"Dismiss by timer"}
                value={values["alarmsAlert3_timer_dismiss"] ?? false}
                onChange={handleInputChange}
              />
            </Grid>
          )}
          {values["alarmsAlert3_timer_dismiss"] && (
            <Grid item container>
              <Grid
                item
                container
                xs={6}
                style={{ paddingRight: "10px", marginTop: "16px" }}
                alignContent="flex-end"
              >
                <CustomInput
                  name="alarmsAlert3_timer_value"
                  clearFieldIcon={true}
                  value={values["alarmsAlert3_timer_value"] ?? ""}
                  onChange={handleInputChange}
                  type="number"
                />
              </Grid>

              <Grid item xs={6}>
                <CustomSelect
                  clearFieldIcon={true}
                  name="alarmsAlert3_timer_units"
                  list={[
                    {
                      value: "minutes",
                      title: msg.editWidgetAlarmsModal.minutes,
                    },
                    {
                      value: "seconds",
                      title: msg.editWidgetAlarmsModal.seconds,
                    },
                  ]}
                  value={values["alarmsAlert3_timer_units"] ?? ""}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          )}

          {/* Options */}
          {!props.isMonitor && (
            <>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  className={classes.alertHeadline}
                >
                  {msg.editWidgetAlarmsModal.options}
                </Typography>
              </Grid>
              <Grid item>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={values.optionalTags}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value?.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  defaultValue={values.optionalTags}
                  onChange={(event, newValue) => {
                    setValues({
                      optionalTags: newValue,
                    });
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Optional tags to add to notification"
                        placeholder="Favorites"
                      />
                    );
                  }}
                />
              </Grid>
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <CustomSwitch
                  name="executeOnAlarmOn"
                  label={"Execute Controls on alarm on"}
                  value={values.executeOnAlarmOn}
                  onChange={() => {
                    const object = {
                      executeOnAlarmOnObject: alarmOptionsInitialValue(
                        values.executeOnAlarmOnObject.linkedObjectsList
                      ),
                      executeOnAlarmOn: !values.executeOnAlarmOn,
                    };
                    setValues(
                      values.executeOnAlarmOn
                        ? object
                        : { executeOnAlarmOn: !values.executeOnAlarmOn }
                    );
                  }}
                />
              </Grid>
              {values.executeOnAlarmOn && (
                <>
                  <Grid item>
                    <CustomAutocomplete
                      clearFieldIcon={true}
                      name="selectedLinkedObject"
                      label="Object name"
                      list={values.executeOnAlarmOnObject?.linkedObjectsList}
                      value={
                        values.executeOnAlarmOnObject.selectedLinkedObject
                          ?.value
                      }
                      onChange={(e) => handleLinkedObjects(e, on)}
                      noOptionsText="No linked objects"
                    />
                  </Grid>
                  <Grid item>
                    <CustomSelect
                      disabled={
                        values.executeOnAlarmOnObject.controlsList?.length ===
                          0 || !values.executeOnAlarmOnObject.controlsList
                      }
                      clearFieldIcon={true}
                      name="selectedControls"
                      label="Control name"
                      list={values.executeOnAlarmOnObject.controlsList}
                      value={
                        values.executeOnAlarmOnObject.selectedControls?.value
                      }
                      onChange={(e) => handleControls(e, on)}
                    />
                  </Grid>

                  {values.executeOnAlarmOnObject.parametersList?.map(
                    (parameter) => (
                      <Grid item>
                        <CustomInput
                          name={parameter.argument}
                          label={parameter.argument}
                          clearFieldIcon={true}
                          value={
                            values.executeOnAlarmOnObject.arguments?.find(
                              (item) => item.argument === parameter.argument
                            ).value
                          }
                          onChange={(e) => handleParameters(e, on)}
                        />
                      </Grid>
                    )
                  )}
                </>
              )}
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <CustomSwitch
                  name="executeOnAlarmOff"
                  label={"Execute Controls on alarm off"}
                  value={values.executeOnAlarmOff}
                  onChange={() => {
                    const object = {
                      executeOnAlarmOffObject: alarmOptionsInitialValue(
                        values.executeOnAlarmOffObject.linkedObjectsList
                      ),
                      executeOnAlarmOff: !values.executeOnAlarmOff,
                    };
                    setValues(
                      values.executeOnAlarmOff
                        ? object
                        : { executeOnAlarmOff: !values.executeOnAlarmOff }
                    );
                  }}
                />
              </Grid>
              {values.executeOnAlarmOff && (
                <>
                  <Grid item>
                    <CustomAutocomplete
                      clearFieldIcon={true}
                      name="selectedLinkedObject"
                      label="Object name"
                      list={values.executeOnAlarmOffObject.linkedObjectsList}
                      value={
                        values.executeOnAlarmOffObject.selectedLinkedObject
                          ?.value
                      }
                      onChange={(e) => handleLinkedObjects(e, off)}
                      noOptionsText="No linked objects"
                    />
                  </Grid>
                  <Grid item>
                    <CustomSelect
                      disabled={
                        values.executeOnAlarmOffObject.controlsList?.length ===
                          0 || !values.executeOnAlarmOffObject.controlsList
                      }
                      clearFieldIcon={true}
                      name="selectedControls"
                      label="Control name"
                      list={values.executeOnAlarmOffObject.controlsList}
                      value={
                        values.executeOnAlarmOffObject.selectedControls?.value
                      }
                      onChange={(e) => handleControls(e, off)}
                    />
                  </Grid>

                  {values.executeOnAlarmOffObject.parametersList?.map(
                    (parameter) => (
                      <Grid item>
                        <CustomInput
                          name={parameter.argument}
                          label={parameter.argument}
                          clearFieldIcon={true}
                          value={
                            values.executeOnAlarmOffObject.arguments.find(
                              (item) => item.argument === parameter.argument
                            ).value
                          }
                          onChange={(e) => handleParameters(e, off)}
                        />
                      </Grid>
                    )
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(EditWidgetAlarmsModal);
