import { useRef, useState } from "react";
import { mediaServer } from "../../constants";

const FileUpload = (props) => {
  const uploadInputRef = useRef(null);
  const [fileName, setFileName] = useState(null);

  const handleFileUpload = async (
    e,
    file_uid,
    accept,
    maxSize,
    handleUploaded,
    handleError
  ) => {
    const [file] = e.target.files;

    if (file.size > maxSize) {
      handleError(`File too large (max ${maxSize} bytes)`);

      return false;
    }

    if (!file.type.includes("image")) {
      handleError("Wrong file format");
      return false;
    }

    props.setLoading(true);
    try {
      const formData = new FormData();
      formData.append("uploaded_file", file, file.name);

      const uploadUID = file_uid ? file_uid : "0";

      const response = await fetch(
        `${mediaServer}/upload/${uploadUID}/${localStorage.getItem(
          "authToken"
        )}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: formData,
        }
      );

      const result_uid = await response.text();

      setFileName(fileName);
      props.setLoading(false);

      handleUploaded(result_uid, file.name);
    } catch (e) {
      props.setLoading(false);
      if (e.response) {
        if (e.response.status === 403) {
          handleError("file upload error 403");
        }
      } else {
        handleError("file upload error");
      }
    }
  }; //handleFileUpload

  const { file_uid, accept, maxSize, handleUploaded, handleError } = props;

  return (
    <input
      ref={uploadInputRef}
      type="file"
      hidden
      multiple={false}
      onChange={(e) => {
        handleFileUpload(
          e,
          file_uid,
          accept,
          maxSize,
          handleUploaded,
          handleError
        );
      }}
    />
  );
};

export default FileUpload;
