import { HelpOutline, LinkOff } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

const WidgetInitInfo = ({ fgColor, infoText, icon, mainText = "No data" }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        textAlign: "center",
      }}
    >
      <div
        style={{
          color: fgColor,
          flexDirection: "column",
          alignItems: "center",
          display: "flex",
        }}
      >
        {icon ? icon : <LinkOff fontSize={"large"} />}
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{mainText}</span>
          {infoText && (
            <Tooltip title={infoText} placement={"top"}>
              <HelpOutline sx={{ marginLeft: "5px" }} fontSize={"small"} />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default WidgetInitInfo;
