import axios from "axios";

const useMedia = () => {
  const token = localStorage.getItem("authToken");
  const url = window.__pixelConfig.APP_MEDIA_SERVER;

  const getImageById = (id, type = "download") => {
    if (!id) {
      return null;
    }
    return `${url}/${type}/${id}/${token}`;
  };

  const uploadMedia = (file, setProgress, id = "0", fileName) => {
    const formData = new FormData();
    formData.append("uploaded_file", file, fileName);

    return axios.request({
      method: "POST",
      url: `${url}/upload/${id}/${token}`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        if (setProgress) {
          setProgress(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        }
      },
      data: formData,
    });
  };

  const computeIcon = (item) => {
    if (item.mIcon) {
      return getImageById(item.mIcon);
    }

    if (item.mPicture) {
      return getImageById(item.mPicture);
    }

    return null;
  };

  const isImage = (mediaType) => {
    return mediaType.includes("image");
  };

  const downloadMediaAsFile = (id) => {
    return axios.get(getImageById(id));
  };
  return {
    getImageById,
    computeIcon,
    uploadMedia,
    downloadMediaAsFile,
    isImage,
  };
};
export default useMedia;
