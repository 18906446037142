import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useEffect, useState } from "react";

import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import { useDebounce } from "react-use";
import { SortableItem } from "./SortableColumn";

export function ColumnContainer(props) {
  const [items, setItems] = useState([]);
  const [activeId, setActiveId] = useState(null);

  const [debouncedItems, setDebouncedItems] = useState([]);

  const [, cancel] = useDebounce(
    () => {
      if (items.length > 0) {
        setDebouncedItems(items);
      }
    },
    1000,
    [items]
  );

  useEffect(() => {
    props.handleUpdate(debouncedItems);
  }, [debouncedItems]);

  function handleDragStart(event) {
    setActiveId(items.find((item) => item.property.id === event.active.id));
  }

  useEffect(() => {
    setItems(
      props.columns.map((item, index) => ({
        ...item,
        id: item.property.id,
        order: index,
      }))
    );
  }, [props.columns]);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map((item) => (
            <SortableItem
              schema={props.schema}
              openColumnMenu={props.openColumnMenu}
              classes={props.classes}
              key={item.property.id}
              id={item.id}
              item={item}
            />
          ))}
        </SortableContext>
        <DragOverlay>
          {activeId ? (
            <SortableItem
              style={{ boxShadow: "0px 9px 27px -3px rgba(0,0,0,0.3)" }}
              classes={props.classes}
              key={activeId.id}
              id={activeId.id}
              item={activeId}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );

  function handleDragEnd(event) {
    setActiveId(null);

    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        const data = arrayMove(items, oldIndex, newIndex).map(
          (item, index) => ({ ...item, order: index })
        );

        return data;
      });
    }
  }
}
