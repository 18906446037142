import { useLazyQuery } from "@apollo/client";
import { Fade } from "@mui/material";
import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import makeStyles from "@mui/styles/makeStyles";
import { loader } from "graphql.macro";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import scrollIntoView from "scroll-into-view-if-needed";
import AdvancedButtonFallback from "../../assets/fallbacks/advanced-button.svg";
import ChartFallback from "../../assets/fallbacks/chart.svg";
import CollectionFallback from "../../assets/fallbacks/collection.svg";
import CommandButtonFallback from "../../assets/fallbacks/command-button.svg";
import ContainerFallback from "../../assets/fallbacks/container.svg";
import DatasetFallback from "../../assets/fallbacks/dataset.svg";
import DateTimeFallback from "../../assets/fallbacks/datetime.svg";
import DiagramFallback from "../../assets/fallbacks/diagram.svg";
import HistoryTableFallback from "../../assets/fallbacks/history-table.svg";
import MinimapFallback from "../../assets/fallbacks/minimap.svg";
import MonitorTableFallback from "../../assets/fallbacks/monitor-table.svg";
import ObjectFallback from "../../assets/fallbacks/object.svg";
import StaticTableFallback from "../../assets/fallbacks/static-table.svg";
import StatisticFallback from "../../assets/fallbacks/statistics.svg";
import TimerFallback from "../../assets/fallbacks/timer.svg";
import TitleFallback from "../../assets/fallbacks/title.svg";
import TrackingBox from "../../assets/fallbacks/tracking-box.svg";
import WidgetFallback from "../../assets/fallbacks/widget.svg";
import { mediaServer } from "../../constants";
import useRoute from "../../hooks/useRoute";
import { msg } from "../../messages";
import { GET_DATA_SUBSCRIPTION } from "../../queries";
import isAnyPartOfElementInViewport from "../../utils/isAnyPartOfElementInViewport";
import {
  isAdvancedButton,
  isCalculator,
  isChart,
  isColorChart,
  isHistoryTable,
  isMonitorStatusTable,
  isMonitorTable,
  isStaticTable,
  isTimer,
  isTrackingBox,
} from "../../utils/objectType";
import useMedia from "../../utils/useMedia";
import TopButton from "../TopButton";
import AdvancedButtonGeneralTab from "./advanced-button/AdvancedButtonGeneralTab";
import TabGeneralCalculatorStatistic from "./calculator-statistic/TabGeneralCalculatorStatistic";
import CardToolbar from "./CardToolbar";
import ChartGeneralTab from "./chart/ChartGeneralTab";
import ColorChartGeneralTab from "./chart/ColorChartGeneralTab";
import TabGeneralCounterStatistic from "./counter-statistic/TabGeneralCounterStatistic";
import TabGeneralSource from "./counter-statistic/TabGeneralSource";
import TabGeneralTrackingBox from "./geo-timer/TabGeneralTrackingBox";
import NormalHeader from "./header/NormalHeader";
import HistoryControlsProperties from "./history-controls/HistoryControlsProperties";
import HistoryTableGeneral from "./history-table/HistoryTableGeneral";
import HistoryProperties from "./history/HistoryProperties";
import useUniversalMenu from "./menu/useUniversalMenu";
import MonitorStatusTableGeneral from "./monitor-status-table/MonitorStatusTableGeneral";
import MonitorTableGeneral from "./monitor-table/MonitorTableGeneral";
import StaticTableGeneral from "./static-table/StaticTableGeneral";
import TabContentControls from "./TabContentControls";
import TabContentDashboards from "./TabContentDashboards";
import TabContentGeneral from "./TabContentGeneral";
import TabContentNotifications from "./TabContentNotifications";
import TabContentObjects from "./TabContentObjects";
import TabContentProperties from "./TabContentProperties";
import TabPanel from "./TabPanel";
import TimerGeneralTab from "./timer/TimerGeneralTab";
import TabGeneralTimeseriesStatistic from "./timeseries-statistic/TabGeneralTimeseriesStatistic";
import TabSourceTimeseries from "./timeseries-statistic/TabSourceTimeseries";

const GET_OBJECT = loader("../../graphql/GetObjectQuery.graphql");

const useStyles = makeStyles((theme) => ({
  tabButton: {
    minWidth: "auto",
    flexBasis: "auto",
    paddingInline: "16px",
  },
  tabs: {
    padding: 0,
    position: "sticky",
    background: theme.palette.white,
    top: "68px",
    zIndex: 2,
    borderBottom: "1px solid rgba(0,0,0,.12)",
  },
  tabsHidden: {
    minHeight: "0px",
    transition: "0.5s",
  },

  tabPanel: {
    marginTop: "0px",
  },
}));

const tabs = {
  collection: ["general", "objects", "notifications"],
  group: ["general", "widgets", "notifications"],
  object: ["general", "properties", "controls", "notifications"],
  statistics: ["general", "source", "objects", "notifications"],
  timeseries: ["general", "source", "objects", "notifications"],
  widget: ["general", "objects", "notifications"],
};

const SideCard = (props) => {
  const { objectId, widgetId, groupId, collectionId } = useParams();
  const { getTypeByRoute } = useRoute();
  const { menuBasedOnType } = useUniversalMenu();

  const isShowHistoryProperty = useSelector(
    (state) => state.settings.isShowHistoryProperty
  );
  const id = objectId || widgetId || groupId || collectionId;
  const type = getTypeByRoute();

  const [loadObject, { data, loading, refetch, subscribeToMore }] =
    useLazyQuery(GET_OBJECT, {
      variables: { objectId: id },
    });
  let svgUrl;
  const isShowHistory = useSelector((state) => state.settings.isShowHistory);
  const isShowControlsHistory = useSelector(
    (state) => state.settings.isShowControlsHistory
  );

  const classes = useStyles();
  const theme = useTheme();
  const [tabValue, setTabValue] = useState("general");
  const topRef = useRef(null);
  const sideCard = useRef(null);
  const [sFixed, setSFixed] = useState(false); // if true (onscroll), fix header
  const [sFixedFade, setSFixedFade] = useState(true); // if false (onscroll), fade in fix header
  const [sShowBack, setSshowBack] = useState(false); // if true (onscroll) , show go top button

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: GET_DATA_SUBSCRIPTION,
      variables: {
        objId: id,
      },
    });

    return () => unsubscribe();
  }, [id]);

  useEffect(() => {
    loadObject();
  }, [objectId, groupId, widgetId]);

  useEffect(() => {
    if (type === "group") {
      let node = document.querySelector(`[data-id=t-${id}]`);

      if (node && !isAnyPartOfElementInViewport(node)) {
        scrollIntoView(node, {
          behavior: "smooth",
          scrollMode: "if-needed",
          block: "center",
          inline: "center",
        });
      }
    } else {
      sideCard?.current?.scroll({ left: 0, top: 0 });
      setSFixed(false);
    }
  }, [objectId, groupId, widgetId]);

  if (!data || loading) return <LinearProgress style={{ width: "100%" }} />;
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const isCounterStatistic = (tags) => {
    return tags.includes("statistics") && tags.includes("counter");
  };

  const isTimeseriesStatistic = (tags) => {
    return tags.includes("statistics") && tags.includes("timeseries");
  };

  const getType = () => {
    if (
      data.object.schemaTags.includes("statistics") &&
      data.object.schemaTags.includes("counter")
    ) {
      return "statistics";
    }

    if (
      data.object.schemaTags.includes("statistics") &&
      data.object.schemaTags.includes("timeseries")
    ) {
      return "timeseries";
    }

    if (data.object.schemaTags.includes("collection")) {
      return "collection";
    }

    return type;
  };

  const { getImageById } = useMedia();

  const getTargetObjectId = () => {
    const objectId = data.object.objectProperties.find(
      (item) => item.key === "valueValue"
    )?.value?.objectId;


    return data.object.id;
  };
  const getImageFallback = () => {
    const bgImage = data.object.objectProperties.find(
      (item) => item.key === "customBackgroundImage"
    )?.value;

    if (bgImage) {
      return getImageById(bgImage);
    }

    if (data.object.schemaTags.includes("statistics")) {
      return StatisticFallback;
    }

    if (data.object.schemaTags.includes("diagram")) {
      return DiagramFallback;
    }

    if (data.object.schemaTags.includes("monitor table")) {
      return MonitorTableFallback;
    }

    if (data.object.schemaTags.includes("monitor status")) {
      return MonitorTableFallback;
    }

    if (data.object.schemaTags.includes("static table")) {
      return StaticTableFallback;
    }

    if (data.object.schemaTags.includes("history table")) {
      return HistoryTableFallback;
    }

    if (data.object.schemaTags.includes("datachart")) {
      return ChartFallback;
    }

    if (data.object.schemaTags.includes("color datachart")) {
      return ChartFallback;
    }

    if (data.object.schemaTags.includes("title")) {
      return TitleFallback;
    }

    if (data.object.schemaTags.includes("command button")) {
      return CommandButtonFallback;
    }

    if (data.object.schemaTags.includes("databox")) {
      return WidgetFallback;
    }

    if (data.object.schemaTags.includes("geo timer")) {
      return TrackingBox;
    }

    if (data.object.schemaTags.includes("datetime")) {
      return DateTimeFallback;
    }

    if (data.object.schemaTags.includes("timer")) {
      return TimerFallback;
    }

    if (data.object.schemaTags.includes("advanced button")) {
      return AdvancedButtonFallback;
    }

    const containerType = data.object.objectProperties.find(
      (item) => item.key === "generalContainerType"
    )?.value;


    switch (type) {
      case "group":
        if (containerType === "minimap") {
          return MinimapFallback;
        }
        return ContainerFallback;
    }

    if (type === "object") {
      switch (data.object.schemaType) {
        case "dataset":
          return DatasetFallback;
        default:
          return ObjectFallback;
      }
    }

    if (type === "collection") {
      return CollectionFallback;
    }
  };

  // const tabs = {
  //   // 'history table': <HistoryTableGeneral value={tabValue} index="general" className={classes.tabPanel}></HistoryTableGeneral>
  // }

  // header image (usually for devices, but not limited to)
  data.object.schema.mPicture
    ? (svgUrl = `${mediaServer}/download/${
        data.object.schema.mPicture
      }/${localStorage.getItem("authToken")}`)
    : (svgUrl = "");
  return (
    <>
      {isShowHistory && (
        <HistoryProperties id={data.object.id} object={data.object} />
      )}
      {isShowControlsHistory && (
        <HistoryControlsProperties id={data.object.id} object={data.object} />
      )}
      <div
        style={{
          width: props.sideBarWidth,
          height: "100%",
          overflowY: "auto",
        }}
        ref={sideCard}
        onScroll={(e) => {
          if (e.target.scrollTop > 200) {
            setSFixed(true);
          } else {
            setSFixed(false);
          }
        }}
      >
        <div ref={topRef} />
        {!isShowHistoryProperty && !isShowHistory && !isShowControlsHistory && (
          <CardToolbar
            item={data.object}
            isFixed={sFixed}
            menuItems={() => menuBasedOnType(data.object)}
            {...props}
          />
        )}
        <TopButton in={sShowBack} topRef={topRef} />
        <Fade in={sFixedFade}>
          <div
            style={{
              width: "100%",
              height: "240px",
              backgroundImage:
                svgUrl !== "" ? `url(${svgUrl})` : `url(${getImageFallback()})`,
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          ></div>
        </Fade>
        <NormalHeader
          item={data.object}
          menuItems={() => menuBasedOnType(data.object)}
          {...props}
        />
        <Tabs
          onChange={handleChangeTab}
          value={
            tabs[getType(type)].find((tab) => tab === tabValue)
              ? tabValue
              : setTabValue("general")
          }
          aria-label="tabs"
          TabIndicatorProps={{ style: { backgroundColor: theme.palette.blue } }}
          className={classes.tabs}
        >
          {tabs[getType(type)].map((tab) => (
            <Tab
              data-test-tab={tab}
              value={tab}
              label={msg.sideCard[tab]}
              className={classes.tabButton}
              aria-label={tab}
              style={{}}
              key={tab}
            />
          ))}
        </Tabs>
        <Divider style={{ position: "relative", top: "-1px" }} />
        {isCounterStatistic(data.object.schemaTags) && (
          <TabPanel
            value={tabValue}
            index="general"
            className={classes.tabPanel}
          >
            <TabGeneralCounterStatistic item={data.object} {...props} />
          </TabPanel>
        )}
        {isCalculator(data.object.schemaTags) && (
          <TabPanel
            value={tabValue}
            index="general"
            className={classes.tabPanel}
          >
            <TabGeneralCalculatorStatistic item={data.object} {...props} />
          </TabPanel>
        )}
        {isTimeseriesStatistic(data.object.schemaTags) && (
          <TabPanel
            value={tabValue}
            index="general"
            className={classes.tabPanel}
          >
            <TabGeneralTimeseriesStatistic item={data.object} {...props} />
          </TabPanel>
        )}
        {isCounterStatistic(data.object.schemaTags) && (
          <TabPanel
            value={tabValue}
            index="source"
            className={classes.tabPanel}
          >
            <TabGeneralSource item={data.object} {...props} />
          </TabPanel>
        )}
        {isTimeseriesStatistic(data.object.schemaTags) && (
          <TabPanel
            value={tabValue}
            index="source"
            className={classes.tabPanel}
          >
            <TabSourceTimeseries item={data.object} {...props} />
          </TabPanel>
        )}
        {isTrackingBox(data.object.schemaTags) && (
          <TabPanel
            value={tabValue}
            index="general"
            className={classes.tabPanel}
          >
            <TabGeneralTrackingBox item={data.object} {...props} />
          </TabPanel>
        )}
        {isHistoryTable(data.object.schemaTags) && (
          <TabPanel
            value={tabValue}
            index="general"
            className={classes.tabPanel}
          >
            <HistoryTableGeneral item={data.object} {...props} />
          </TabPanel>
        )}
        {isMonitorTable(data.object.schemaTags) && (
          <TabPanel
            value={tabValue}
            index="general"
            className={classes.tabPanel}
          >
            <MonitorTableGeneral item={data.object} {...props} />
          </TabPanel>
        )}
        {isMonitorStatusTable(data.object.schemaTags) && (
          <TabPanel
            value={tabValue}
            index="general"
            className={classes.tabPanel}
          >
            <MonitorStatusTableGeneral item={data.object} {...props} />
          </TabPanel>
        )}
        {isStaticTable(data.object.schemaTags) && (
          <TabPanel
            value={tabValue}
            index="general"
            className={classes.tabPanel}
          >
            <StaticTableGeneral item={data.object} {...props} />
          </TabPanel>
        )}
        {isChart(data.object.schemaTags) && (
          <TabPanel
            value={tabValue}
            index="general"
            className={classes.tabPanel}
          >
            <ChartGeneralTab item={data.object} {...props} />
          </TabPanel>
        )}
        {isColorChart(data.object.schemaTags) && (
          <TabPanel
            value={tabValue}
            index="general"
            className={classes.tabPanel}
          >
            <ColorChartGeneralTab item={data.object} {...props} />
          </TabPanel>
        )}
        {isTimer(data.object.schemaTags) && (
          <TabPanel
            value={tabValue}
            index="general"
            className={classes.tabPanel}
          >
            <TimerGeneralTab item={data.object} {...props} />
          </TabPanel>
        )}
        {isAdvancedButton(data.object.schemaTags) && (
          <TabPanel
            value={tabValue}
            index="general"
            className={classes.tabPanel}
          >
            <AdvancedButtonGeneralTab item={data.object} {...props} />
          </TabPanel>
        )}
        {!isChart(data.object.schemaTags) &&
          !isMonitorStatusTable(data.object.schemaTags) &&
          !isStaticTable(data.object.schemaTags) &&
          !isTimer(data.object.schemaTags) &&
          !isHistoryTable(data.object.schemaTags) &&
          !isMonitorTable(data.object.schemaTags) &&
          !isAdvancedButton(data.object.schemaTags) &&
          !isCounterStatistic(data.object.schemaTags) &&
          !isCalculator(data.object.schemaTags) &&
          !isCalculator(data.object.schemaTags) &&
          !isTrackingBox(data.object.schemaTags) &&
          !isTimeseriesStatistic(data.object.schemaTags) &&
          !isColorChart(data.object.schemaTags) && (
            <TabPanel
              value={tabValue}
              index="general"
              className={classes.tabPanel}
            >
              <TabContentGeneral item={data.object} {...props} />
            </TabPanel>
          )}
        <TabPanel
          value={tabValue}
          index="properties"
          className={classes.tabPanel}
        >
          <TabContentProperties item={data.object} topRef={topRef} {...props} />
        </TabPanel>
        <TabPanel
          value={tabValue}
          index="controls"
          className={classes.tabPanel}
        >
          <TabContentControls item={data.object} topRef={topRef} {...props} />
        </TabPanel>
        {type === "group" && (
          <TabPanel
            value={tabValue}
            index="widgets"
            className={classes.tabPanel}
          >
            <TabContentObjects
              item={data.object}
              type={type}
              refetch={refetch}
              {...props}
            />
          </TabPanel>
        )}
        {(type === "widget" || type === "object") && (
          <TabPanel
            value={tabValue}
            index="objects"
            className={classes.tabPanel}
          >
            <TabContentObjects
              item={data.object}
              refetch={refetch}
              type={type}
              {...props}
            />
          </TabPanel>
        )}
        {type === "collection" && (
          <TabPanel
            value={tabValue}
            index="objects"
            className={classes.tabPanel}
          >
            <TabContentDashboards
              item={data.object}
              refetch={refetch}
              type={type}
              {...props}
            />
          </TabPanel>
        )}
        <TabPanel
          value={tabValue}
          index="notifications"
          className={classes.tabPanel}
        >
          <TabContentNotifications id={getTargetObjectId()} item={data.object} {...props} type={type} />
        </TabPanel>
      </div>
    </>
  );
};

export default SideCard;
