import { DragIndicator } from "@mui/icons-material";

const DragHandleGroup = (props: { onClick: () => void }) => {
  return (
    <div
      onClick={props.onClick}
      className="handleGroup"
      style={{
        cursor: "grab",
        width: "24px",
        height: "24px",
        backgroundColor: "rgba(255, 255, 255, 0.44)",
        position: "absolute",
        right: "0",
        zIndex: "999",
      }}
    >
      <DragIndicator style={{ color: "#686868" }} />
    </div>
  );
};

export default DragHandleGroup;
