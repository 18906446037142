import { gql, useMutation } from "@apollo/client";
import { Label } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import TuneIcon from "@mui/icons-material/Tune";
import { ListItemSecondaryAction } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { cloneDeep } from "lodash";
import React from "react";
import { msg } from "../../../messages";
import IdentifierIcon from "../../icons/IdentifierIcon";
import MapMarkerRadius from "../../icons/mapMarkerRadius";
import EditDynamicProperty from "../../modals/EditDynamicProperty";
import useMoreMenu from "../../useMoreMenu";
import useHandleCopy from "../menu/handlers/useHandleCopy";
import FilterGeotagsModal from "./FilterGeotagsModal";
import FilterGroupModal from "./FilterGroupModal";
import MonitorStatusAddRowModal from "./MonitorStatusAddRowModal";
import MonitorStatusColumnModal from "./MonitorStatusColumnModal";

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: "15px",
    marginBottom: "10px",
  },
  itemToHideOrShow: {
    visibility: "hidden",
    opacity: 1,
    transition: "opacity 0.2s linear",
    [theme.breakpoints.down("sm")]: {
      visibility: "visible",
      opacity: 1,
    },
  },
  itemToHover: {
    "&:hover $itemToHideOrShow": {
      visibility: "visible",
      opacity: 1,
      transition: "opacity 0.2s linear",
    },
  },
}));

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const GET_SCHEMA = gql`
  query getSchema($id: UUID!) {
    schema(id: $id) {
      id
      name
      schemaProperties {
        id
        key
        groupName
        property
        description
        type {
          name
        }
        valueSet
        valueRange
      }
    }
  }
`;

const MonitorStatusTableGeneral = (props) => {
  const { item, type, dashboardId, groupId, widgetId } = props;

  const [updateProperty] = useMutation(UPDATE_PROPERTY);

  const classes = useStyles({
    listItem: {
      minHeight: "56px",
    },
  });

  const getPropertyByKey = (key) => {
    return item.objectProperties.find((item) => item.key === key);
  };

  const columns = () => {
    return getPropertyByKey("sourceColumns").value.columns;
  };

  const rows = () => {
    return getPropertyByKey("sourceRows").value.rows;
  };

  const geotagName = () => {
    return getPropertyByKey("filtersGeotagName").value?.title || "All geotags";
  };

  const groupName = () => {
    return getPropertyByKey("filtersGroupName").value?.title || "All groups";
  };

  const linkedOnly = () => {
    return getPropertyByKey("filtersLinkedOnly");
  };

  const columnsProperty = () => {
    return item.objectProperties.find((item) => item.key === "sourceColumns");
  };

  const { MoreMenu: ValueMenu, openMoreMenu: openValueMenu } = useMoreMenu();

  const { MoreMenu: ColumnMenu, openMoreMenu: openColumnMenu } = useMoreMenu();
  const { MoreMenu: RowMenu, openMoreMenu: openRowMenu } = useMoreMenu();

  const handleCopy = useHandleCopy();

  const handleUpdateProperty = (id, value) => {
    return updateProperty({
      variables: {
        input: {
          id,
          patch: {
            value,
          },
        },
      },
    });
  };

  const handleDelete = (conditionIndex) => {
    const conditionPropertyItem = getPropertyByKey("sourceColumns");
    const oldValues = cloneDeep(conditionPropertyItem.value.columns);

    oldValues.splice(conditionIndex, 1);

    let patch = {
      ...conditionPropertyItem.value,
      columns: [...oldValues],
    };

    updateProperty({
      variables: {
        input: {
          id: conditionPropertyItem.id,
          patch: {
            value: patch,
          },
        },
      },
    }).then(() => {});
  };

  const handleDeleteRow = (conditionIndex) => {
    const conditionPropertyItem = getPropertyByKey("sourceRows");
    const oldValues = cloneDeep(conditionPropertyItem.value.rows);

    oldValues.splice(conditionIndex, 1);

    let patch = {
      ...conditionPropertyItem.value,
      rows: [...oldValues],
    };

    updateProperty({
      variables: {
        input: {
          id: conditionPropertyItem.id,
          patch: {
            value: patch,
          },
        },
      },
    }).then(() => {});
  };

  return (
    <div>
      <List>
        <RowMenu
          items={[
            {
              icon: <EditIcon />,
              title: "Edit",
              id: "edit_prop",
              handleAction: (obj) => {
                MonitorStatusAddRowModal({
                  isEdit: true,
                  conditionIndex: obj.propIndex,
                  conditionProperty: getPropertyByKey("sourceRows"),
                })
                  .then()
                  .catch(() => {});
              },
              disabled: false,
            },
            {
              icon: <DeleteIcon />,
              title: "Delete",
              id: "delete",
              handleAction: (obj) => {
                handleDeleteRow(obj.propIndex);
              },
              disabled: false,
            },
          ]}
        />
        <ColumnMenu
          items={[
            {
              icon: <EditIcon />,
              title: "Edit",
              id: "edit_prop",
              handleAction: (obj) => {
                MonitorStatusColumnModal({
                  isEdit: true,
                  value: obj.item,
                  conditionProperty: getPropertyByKey("sourceColumns"),
                })
                  .then()
                  .catch(() => {});
              },
              disabled: false,
            },
            {
              icon: <DeleteIcon />,
              title: "Delete",
              id: "delete",
              handleAction: (obj) => {
                handleDelete(obj.propIndex);
              },
              disabled: false,
            },
          ]}
        />
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Filters</Typography>
        </ListSubheader>

        {/*Geotag name*/}
        <ListItem
          classes={{ container: classes.itemToHover }}
          style={{ height: "48px" }}
          button
          onClick={() => {}}
        >
          <ListItemIcon>
            <MapMarkerRadius />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">{geotagName()}</Typography>}
            onClick={(e) => {}}
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={() => {
                const target = getPropertyByKey("filtersGeotagName");
                FilterGeotagsModal({
                  value: target.value,
                  save: (object) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: target.id,
                          patch: {
                            value: object,
                          },
                        },
                      },
                    }).then(() => {});
                  },
                })
                  .then()
                  .catch(() => {});
              }}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        {/*Group name */}
        <ListItem
          classes={{ container: classes.itemToHover }}
          style={{ height: "48px" }}
          button
          onClick={() => {}}
        >
          <ListItemIcon>
            <Label />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">{groupName()}</Typography>}
            onClick={(e) => {}}
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={() => {
                const target = getPropertyByKey("filtersGroupName");
                FilterGroupModal({
                  value: target.value,
                  save: (object) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: target.id,
                          patch: {
                            value: object,
                          },
                        },
                      },
                    }).then(() => {});
                  },
                })
                  .then()
                  .catch(() => {});
              }}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          classes={{ container: classes.itemToHover }}
          style={{ height: "48px" }}
          button
          onClick={() => {}}
        >
          <ListItemIcon>
            <TuneIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {linkedOnly().value ? "Linked only" : "All"}
              </Typography>
            }
            onClick={(e) => {}}
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                EditDynamicProperty({
                  handleSave: handleUpdateProperty,
                  property: linkedOnly(),
                  id: linkedOnly().id,
                  value: linkedOnly().value,
                })
                  .then()
                  .catch(() => {});
              }}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Rows</Typography>
        </ListSubheader>
        {rows().map((item, index) => {
          return (
            <ListItem
              title={item.state?.title}
              classes={{
                container: classes.itemToHover,
              }}
              style={{ height: "48px", paddingRight: "48px" }}
              button
              onClick={() => {}}
              key={index}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText
                primary={
                  <Typography noWrap={true} variant="body1">
                    {item.type?.title}: {item.state?.title}
                  </Typography>
                }
                onClick={(e) => {}}
              />
              <ListItemSecondaryAction className={classes.itemToHideOrShow}>
                <IconButton
                  edge="end"
                  aria-label="more"
                  onClick={(e) => {
                    openRowMenu(e, {
                      ...props.item,
                      item: item,
                      propIndex: index,
                    });
                  }}
                  size="large"
                >
                  <MoreVertIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
        <ListItem
          style={{ height: "48px" }}
          button
          onClick={() => {
            MonitorStatusAddRowModal({
              isEdit: false,
              conditionProperty: getPropertyByKey("sourceRows"),
            })
              .then()
              .catch(() => {});
          }}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Add row</Typography>}
          />
        </ListItem>

        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Columns</Typography>
        </ListSubheader>
        {columns().map((item, index) => {
          return (
            <ListItem
              classes={{
                container: classes.itemToHover,
              }}
              style={{ height: "48px", paddingRight: "96px" }}
              button
              onClick={() => {}}
              key={index}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText
                primary={<Typography variant="body1">{item.title}</Typography>}
                onClick={(e) => {}}
              />
              <ListItemSecondaryAction className={classes.itemToHideOrShow}>
                <IconButton
                  edge="end"
                  aria-label="more"
                  onClick={(e) => {
                    openColumnMenu(e, {
                      ...props.item,
                      item: item,
                      propIndex: index,
                    });
                  }}
                  size="large"
                >
                  <MoreVertIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}

        <ListItem
          style={{ height: "48px" }}
          button
          onClick={() => {
            MonitorStatusColumnModal({
              isEdit: false,
              conditionProperty: columnsProperty(),
            })
              .then()
              .catch(() => {});
          }}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Add column</Typography>}
          />
        </ListItem>

        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">
            {msg.sideCard.serviceInfo}
          </Typography>
        </ListSubheader>

        {item.schemaTags[3] === "device" && (
          <ListItem button onClick={() => {}}>
            <ListItemIcon>
              <SubtitlesIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body1">{item.schema.name}</Typography>
              }
              onClick={(e) => {}}
            />
          </ListItem>
        )}

        {item.schemaTags[3] !== "device" && (
          <ListItem button onClick={() => {}}>
            <ListItemIcon>
              <SubtitlesIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Tooltip title={item.schemaTags.join(" / ")}>
                  <Typography variant="body1">{item.schema.name}</Typography>
                </Tooltip>
              }
            />
          </ListItem>
        )}

        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <IdentifierIcon />
          </ListItemIcon>
          <ListItemText
            onClick={(e) => {
              handleCopy({ object: item });
            }}
            primary={<Typography variant="body1">{item.id}</Typography>}
          />
        </ListItem>
        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <AlternateEmailIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">{item.name}</Typography>}
          />
        </ListItem>

        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">{msg.sideCard.access}</Typography>
        </ListSubheader>
        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {item.userGroupByEditorgroup.groupName}
              </Typography>
            }
          />
        </ListItem>

        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <PlayCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {item.userGroupByUsergroup.groupName}
              </Typography>
            }
          />
        </ListItem>

        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <RemoveRedEyeIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {item.userGroupByReadergroup.groupName}
              </Typography>
            }
          />
        </ListItem>

        {item.description && (
          <React.Fragment>
            <ListSubheader color="primary" className={classes.listSubheader}>
              <Typography variant="subtitle2">
                {msg.sideCard.description}
              </Typography>
            </ListSubheader>
            <ListItem button onClick={() => {}}>
              <ListItemText
                primary={
                  <Typography variant="body1">{item.description}</Typography>
                }
              />
            </ListItem>
          </React.Fragment>
        )}
      </List>
    </div>
  );
}; //TabContentGeneral

export default MonitorStatusTableGeneral;
