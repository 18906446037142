export default [
  { value: 0, title: "00 h" },
  { value: 1, title: "01 h" },
  { value: 2, title: "02 h" },
  { value: 3, title: "03 h" },
  { value: 4, title: "04 h" },
  { value: 5, title: "05 h" },
  { value: 6, title: "06 h" },
  { value: 7, title: "07 h" },
  { value: 8, title: "08 h" },
  { value: 9, title: "09 h" },
  { value: 10, title: "10 h" },
  { value: 11, title: "11 h" },
  { value: 12, title: "12 h" },
  { value: 13, title: "13 h" },
  { value: 14, title: "14 h" },
  { value: 15, title: "15 h" },
  { value: 16, title: "16 h" },
  { value: 17, title: "17 h" },
  { value: 18, title: "18 h" },
  { value: 19, title: "19 h" },
  { value: 20, title: "20 h" },
  { value: 21, title: "21 h" },
  { value: 22, title: "22 h" },
  { value: 23, title: "23 h" },
];
