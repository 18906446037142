export function getWidgetType(tags) {
  const targetTag = tags.filter(
    (tag) => !["application", "board", "widget"].includes(tag)
  )[0];

  if (targetTag) {
    return targetTag;
  } else {
    return "UNKNOWN_TYPE";
  }
}
