import { Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import makeStyles from "@mui/styles/makeStyles";

const styles = (theme) => ({
  label: {
    display: "flex",
    justifyContent: "space-between",
  },
  radio: {
    "&$checked": {
      color: theme.palette.blue,
    },
  },
  checked: {},
});

const useStyles = makeStyles((theme) => styles(theme));

const CustomRadio = (props) => {
  const classes = useStyles();

  return (
    <>
      {props.label && (
        <InputLabel
          htmlFor={`${props.name}-label`}
          style={{ marginBottom: "15px" }}
        >
          {props.label}
        </InputLabel>
      )}

      <RadioGroup
        name={props.name}
        id={`${props.name}-label`}
        value={props.value}
        onChange={(e) => {
          props.onChange({
            target: {
              name: props.name,
              value: e.target.value,
            },
          });
        }}
      >
        {props.list.map((item) => (
          <FormControlLabel
            key={item.value}
            style={{ marginLeft: 0 }}
            labelPlacement="start"
            value={item.value}
            control={
              <Radio
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            }
            label={
              <Grid container>
                {item.icon}
                <Typography style={{ marginLeft: "20px" }}>
                  {item.title}
                </Typography>
              </Grid>
            }
            classes={{ root: classes.label }}
            disabled={item.disabled}
          />
        ))}
      </RadioGroup>
    </>
  );
};

export default CustomRadio;
