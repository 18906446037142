import { useApolloClient, useLazyQuery } from "@apollo/client";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Fab from "@mui/material/Fab";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/system";
import { loader } from "graphql.macro";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Container } from "react-modal-promise";
import { useDispatch } from "react-redux";
import { Outlet, useMatch, useParams } from "react-router-dom";
import { useTitle } from "react-use";
import useCustomNavigate from "./components/hooks/useCustomNavigate";
import MainSideMenu from "./components/MainSideMenu";
import AppsModal from "./components/modals/AppsModal";
import { ModalRoot } from "./components/modals/ModalRoot";
import NotificationsModal from "./components/modals/NotificationsModal/index";
import ProfileModalNew from "./components/modals/ProfileModal";
import { ModalState } from "./context/modal/ModalState";
import { useAppSelector } from "./hooks";
import { ICONS_QUERY } from "./queries";
import { setSettings } from "./store/settingsSlice";
import { useAuth } from "./utils/useAuth";
const DASHBOARDS_QUERY = loader("./graphql/DashboardsQuery.graphql");
const USER_ID = loader("./graphql/UserIdQuery.graphql");

function MainLayout() {
  const decodedJWT = localStorage.getItem("authToken") ? jwt_decode(localStorage.getItem("authToken")) : null;
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const history = useCustomNavigate();
  const { loadUser } = useAuth();
  const navigate = useCustomNavigate();

  const isFullScreen = useAppSelector((state) => state.settings.isFullScreen);
  const isViewMode = useAppSelector((state) => state.settings.isViewMode);
  const isDrawerOpen = useAppSelector((state) => state.settings.isDrawerOpen);
  const AppTitle = useAppSelector((state) => state.profile?.programAppTitle);

  const { dashboardId } = useParams();

  const routeMatchLogin = useMatch({ path: "/login" });

  const [dashboardsLazyQuery] = useLazyQuery(DASHBOARDS_QUERY, {
    fetchPolicy: "network-only",
  });

  const [userIdLoad, userIdResult] = useLazyQuery(USER_ID, {
    fetchPolicy: "network-only",
  });

  const client = useApolloClient();

  useEffect(() => {
    if (userIdResult.data?.userId && !routeMatchLogin) {
      Promise.all([dashboardsLazyQuery(), loadUser()]).finally(() => {});
    }
  }, [dashboardId, userIdResult.data]);

  useTitle((AppTitle as string) || "PixelBoard");

  useEffect(() => {
    if (!routeMatchLogin && decodedJWT) {
      userIdLoad().finally(() => {});
    } else {
      history("/login");
    }
  }, [routeMatchLogin]);

  const getIcons = async () => {
    try {
      const result = await client.query({
        query: ICONS_QUERY,
        variables: { filter: ["application", "monitor", "icon"] },
        fetchPolicy: "network-only", // skip the cache
      });

      dispatch(setSettings({ icons: result.data.objects }));
    } catch (err) {
      //getIcons
    }
  };

  useEffect(() => {
    if (userIdResult.data?.userId) getIcons().finally(() => {});
  }, [userIdResult.data]);

  const handleDrawerToggle = () => {
    dispatch(setSettings({ isDrawerOpen: !isDrawerOpen }));
  };

  if (!userIdResult.data?.userId || userIdResult.loading) return false; // not authorized

  return (
    <>
      <ModalState>
        <ModalRoot />
        <CssBaseline />
        <Container />
        <Toaster />
      </ModalState>
      <Box
        sx={{
          display: "flex",
          height: "100%",
        }}
      >
        {!isViewMode && (
          <AppBar color="transparent" elevation={0} position="fixed">
            <Toolbar disableGutters sx={{ marginLeft: "16px", marginRight: "24px" }}>
              {!isViewMode && (
                <Fab
                  data-test="menu"
                  sx={{
                    width: "36px",
                    height: "36px",
                    backgroundColor: theme.palette.background.default,
                  }}
                  onClick={() => {
                    dispatch(setSettings({ isDrawerOpen: !isDrawerOpen }));
                  }}
                >
                  <MenuIcon />
                </Fab>
              )}
              {!isFullScreen && desktop && (
                <Box sx={{ marginLeft: "auto" }}>
                  <NotificationsModal />
                  <AppsModal />
                  <ProfileModalNew />
                </Box>
              )}
            </Toolbar>
          </AppBar>
        )}
        <Drawer
          variant="temporary"
          anchor="left"
          open={isDrawerOpen}
          onClose={handleDrawerToggle}
          PaperProps={{
            sx: {
              width: "256px",
            },
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <MainSideMenu
            account={!desktop}
            setDrawerOpen={() => {
              dispatch(setSettings({ isDrawerOpen: false }));
            }}
          />
        </Drawer>
        <Outlet></Outlet>
      </Box>
    </>
  );
}

export default MainLayout;
