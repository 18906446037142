const SUB_EVENT_TYPES = {
  insert: "insert",
  update: "update",
  delete: "delete",
};

export const processSubscriptionNode = () => ({
  merge(_, incoming, { cache }) {
    if (incoming.event) {
      const [event, type, id] = incoming.event.split(" ");
      if (event === SUB_EVENT_TYPES.delete) {
        cache.evict({
          id: `${type}:${id}`,
          broadcast: true,
        });
      }
    }
  },
});
