import { gql, useLazyQuery, useQuery } from "@apollo/client";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Fab from "@mui/material/Fab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { loader } from "graphql.macro";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useMatch, useSearchParams } from "react-router-dom";
import ContentLayout from "../../../components/ContentLayout";
import useCustomNavigate from "../../../components/hooks/useCustomNavigate";
import SplitView from "../../../components/icons/splitView";
import NoDashboards from "../../../components/NoDashboards";
import Spinner from "../../../components/Spinner";
import { setSettings } from "../../../store/settingsSlice";

const DASHBOARDS_QUERY = loader("../../../graphql/ReportsQuery.graphql");

const GET_OBJECT = gql`
  query getObject($objectId: UUID!) {
    object(id: $objectId) {
      id
    }
  }
`;

const ReportsRoot = () => {
  const { loading, error, data } = useQuery(DASHBOARDS_QUERY);
  const [getObject] = useLazyQuery(GET_OBJECT);
  const navigate = useCustomNavigate();
  const matchBoardsRoot = useMatch("/reports");
  const dispatch = useDispatch();
  const isFullScreen = useSelector((state) => state.settings.isFullScreen);
  const isViewMode = useSelector((state) => state.settings.isViewMode);
  const profile = useSelector((state) => state.profile);
  const [search, setSearch] = useSearchParams();

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (search.get("mode")) {
      dispatch(
        setSettings({
          isViewMode: true,
          isFullScreen: true,
          isDrawerOpen: false,
        })
      );
    }

    const checkBeforeLoad = async () => {
      if (profile && data) {
        if (profile?.programDefaultReport && matchBoardsRoot) {
          await getObject({
            variables: {
              objectId: profile?.programDefaultReport,
            },
            onCompleted(res) {
              if (res.object) {
                navigate(`/reports/${profile.programDefaultReport}`);
              } else {
                const ids = data.dashboards.map((item) => item.id);
                if (ids.length > 0 && matchBoardsRoot) {
                  navigate(`/reports/${ids[0]}`);
                }
              }
            },
            onError() {
              const ids = data.dashboards.map((item) => item.id);
              if (ids.length > 0 && matchBoardsRoot) {
                navigate(`/reports/${ids[0]}`);
              }
            },
            fetchPolicy: "network-only",
          });
        } else {
          const ids = data.dashboards.map((item) => item.id);
          if (ids.length > 0 && matchBoardsRoot) {
            navigate(`/reports/${ids[0]}`);
          }
        }
      }
    };

    checkBeforeLoad();
  }, [matchBoardsRoot, data, loading, profile]);

  if (!profile) {
    return null;
  }

  return (
    <>
      {loading && !data && <Spinner />}
      {error && <>{error}</>}
      {!loading && data && !data.dashboards.length && <NoDashboards />}
      {!error && data && (
        <>
          {!isFullScreen && isSm && (
            <Fab
              style={{
                right: "20px",
                position: "fixed",
                bottom: "20px",
                zIndex: 1201,
              }}
              color="primary"
              onClick={() => {
                dispatch(setSettings({ isFullScreen: true }));
              }}
            >
              <DashboardIcon />
            </Fab>
          )}
          {isFullScreen && isSm && !isViewMode && (
            <Fab
              style={{
                right: "20px",
                position: "fixed",
                bottom: "20px",
                zIndex: 1201,
              }}
              color="primary"
              onClick={() => {
                dispatch(setSettings({ isFullScreen: false }));
              }}
            >
              <SplitView />
            </Fab>
          )}
          <ContentLayout>
            <Outlet />
          </ContentLayout>
        </>
      )}
    </>
  );
};

export default ReportsRoot;
