export default [
  { value: 0, title: "00 m" },
  { value: 1, title: "01 m" },
  { value: 2, title: "02 m" },
  { value: 3, title: "03 m" },
  { value: 4, title: "04 m" },
  { value: 5, title: "05 m" },
  { value: 6, title: "06 m" },
  { value: 7, title: "07 m" },
  { value: 8, title: "08 m" },
  { value: 9, title: "09 m" },
  { value: 10, title: "10 m" },
  { value: 11, title: "11 m" },
  { value: 12, title: "12 m" },
  { value: 13, title: "13 m" },
  { value: 14, title: "14 m" },
  { value: 15, title: "15 m" },
  { value: 16, title: "16 m" },
  { value: 17, title: "17 m" },
  { value: 18, title: "18 m" },
  { value: 19, title: "19 m" },
  { value: 20, title: "20 m" },
  { value: 21, title: "21 m" },
  { value: 22, title: "22 m" },
  { value: 23, title: "23 m" },
  { value: 24, title: "24 m" },
  { value: 25, title: "25 m" },
  { value: 26, title: "26 m" },
  { value: 27, title: "27 m" },
  { value: 28, title: "28 m" },
  { value: 29, title: "29 m" },
  { value: 30, title: "30 m" },
  { value: 31, title: "31 m" },
  { value: 32, title: "32 m" },
  { value: 33, title: "33 m" },
  { value: 34, title: "34 m" },
  { value: 35, title: "35 m" },
  { value: 36, title: "36 m" },
  { value: 37, title: "37 m" },
  { value: 38, title: "38 m" },
  { value: 39, title: "39 m" },
  { value: 40, title: "40 m" },
  { value: 41, title: "41 m" },
  { value: 42, title: "42 m" },
  { value: 43, title: "43 m" },
  { value: 44, title: "44 m" },
  { value: 45, title: "45 m" },
  { value: 46, title: "46 m" },
  { value: 47, title: "47 m" },
  { value: 48, title: "48 m" },
  { value: 49, title: "49 m" },
  { value: 50, title: "50 m" },
  { value: 51, title: "51 m" },
  { value: 52, title: "52 m" },
  { value: 53, title: "53 m" },
  { value: 54, title: "54 m" },
  { value: 55, title: "55 m" },
  { value: 56, title: "56 m" },
  { value: 57, title: "57 m" },
  { value: 58, title: "58 m" },
  { value: 59, title: "59 m" },
];
