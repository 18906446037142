import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import makeStyles from "@mui/styles/makeStyles";

const ConfirmationModal = (props) => {
  const { modal = true } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const styles = (theme) => ({
    modalPaper: {
      borderRadius: "5px",
      width: "360px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        borderRadius: "0",
      },
      ...(!fullScreen ? props.paperStyles : null),
      ...props.modalPaper,
    },
    modalActions: {
      padding: "8px 16px",
    },
    modalContent: {
      paddingLeft: "24px",
      paddingRight: "24px",
      paddingTop: "22px",
      paddingBottom: "22px",
      "&:first-child": {
        paddingTop: "22px",
      },
    },
    typography: {
      color: theme.palette.gray1,
    },
  });

  const useStyles = makeStyles((theme) => styles(theme));

  const classes = useStyles();

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <>
      <Dialog
        open={props.modalOpen}
        fullScreen={fullScreen}
        onClose={handleClose}
        PaperProps={{
          classes: { root: classes.modalPaper },
          ...(!modal ? { style: { pointerEvents: "auto" } } : {}),
        }}
        {...(!modal ? { hideBackdrop: true } : {})}
        {...(!modal ? { disableEnforceFocus: true } : {})}
        {...(!modal ? { style: { pointerEvents: "none" } } : {})}
      >
        <DialogContent className={classes.modalContent}>
          <Typography variant="subtitle2" className={classes.typography}>
            {props.children}
          </Typography>
        </DialogContent>

        <Divider />
        {props.buttons && (
          <DialogActions className={classes.modalActions}>
            {props.buttons}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default ConfirmationModal;
