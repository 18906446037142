import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragHandleOutlined } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

export function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging,
    isOver,
    isSorting,
    transform,
    transition,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const hasAlarms = (alerts) => {
    return alerts.some((item) => {
      if (Object.values(item.condition).length) {
        if (item.condition.value && item.condition.operator) {
          return true;
        }
      }
      return false;
    });
  };

  return (
    <div ref={setNodeRef} style={style}>
      <ListItem
        classes={{
          container: props.classes.itemToHover,
          root: {
            "&:hover": {
              cursor: "pointer",
            },
          },
        }}
        style={{
          height: "48px",
          opacity: isDragging ? "0.5" : "1",
          backgroundColor: "white",
          ...props.style,
        }}
      >
        <ListItemIcon>
          {hasAlarms(props.item.alerts) && <NotificationsIcon />}
        </ListItemIcon>

        <ListItemText
          primary={
            <Typography variant="body1">{props.item.property.name}</Typography>
          }
          onClick={(e) => {}}
        />
        <ListItemSecondaryAction className={props.classes.itemToHideOrShow}>
          <IconButton
            {...attributes}
            {...listeners}
            edge="end"
            aria-label="more"
            style={{
              marginRight: "10px",
              touchAction: "none",
              cursor: "pointer",
            }}
          >
            <DragHandleOutlined />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="more"
            onClick={(e) => {
              props.openColumnMenu(e, {
                ...props.item,
                item: props.item,
                propKey: props.schema.key,
                propValue: props.schema.value,
                propId: props.schema.id,
                propIndex: props.index,
              });
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  );
}
