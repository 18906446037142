import { Link, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({}));

const NoDashboards = React.memo((props) => {
  const classes = useStyles();
  const colorTheme = useSelector((state) => state.settings.theme);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        position: "absolute",
        left: 0,
        backgroundColor: "#333",
        right: 0,
        margin: "0 auto",
        top: "50%",
        padding: "20px",
        height: "100vh",
        transform: "translateY(-50%)",
      }}
    >
      <Grid item style={{ textAlign: "center" }}>
        <Typography
          variant="h6"
          color={"white"}
          style={{ paddingBottom: "20px", maxWidth: "900px" }}
        >
          Welcome to the Pixel Board!
        </Typography>
        <Typography variant="h6" color={"white"} style={{ maxWidth: "900px" }}>
          Use the button in the upper left corner of the screen to open the
          navigation menu. You can use it to add new dashboards. If you have any
          questions, use the{" "}
          <Link
            underline="none"
            target="_blank"
            href="https://pixel-networks.atlassian.net/wiki/spaces/PCV3/pages/11173966/PixelBoard"
          >
            {" "}
            help
          </Link>{" "}
          or visit our{" "}
          <Link
            underline="none"
            target="_blank"
            href="https://www.youtube.com/@pixelcoreiotplatform"
          >
            YouTube channel
          </Link>
          .
        </Typography>
      </Grid>
    </Grid>
  );
});

export default NoDashboards;
