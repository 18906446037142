import { gql, useQuery } from "@apollo/client";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { create } from "react-modal-promise";
import CommonModal from "../components/CommonModal";
import { msg } from "../messages";
import { version } from "../version";

const GET_VERSION_QUERY = gql`
  query getVersion {
    getVersion {
      short
      long
    }
  }
`;
const GET_TIMEZONE_QUERY = gql`
  query getTimezone {
    getTimezone
  }
`;

type AboutModalProps = {
  onResolve: () => void;
  isOpen: boolean;
};

const AboutModal = (props: AboutModalProps) => {
  const { loading, error, data } = useQuery(GET_VERSION_QUERY, {
    variables: {},
  });

  const { data: TimeZone } = useQuery(GET_TIMEZONE_QUERY, {
    variables: {},
  });

  const submit = () => props.onResolve();

  const handleClose = () => submit();

  if (loading) return <div>Loading version...</div>;
  if (error) return <div>Version query error!</div>;

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        loading={false}
        title={msg.aboutModal.aboutProgram}
        forceTitle={true}
        // contentStyles={{
        //   padding: "0 0 0 0",
        // }}
        handleClose={handleClose}
        buttons={
          <>
            <Button data-test-about="close" onClick={handleClose}>
              {msg.aboutModal.buttonOK}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography color="primary" variant="subtitle2">
              {msg.aboutModal.application}
            </Typography>
          </Grid>
          <Grid item>
            <Box display="flex" justifyContent="space-between">
              <span>{msg.aboutModal.pixelCoreVer}</span>
              <span>{data?.getVersion.short}</span>
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" justifyContent="space-between">
              <span>{msg.aboutModal.pixelFrontVer}</span>
              <span>{version}</span>
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" justifyContent="space-between">
              <span>{msg.aboutModal.pixelCoreTimeZone}</span>
              <span>{TimeZone?.getTimezone || ""}</span>
            </Box>
          </Grid>
          <Grid item>
            <Typography color="primary" variant="subtitle2">
              {msg.aboutModal.aboutUs}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">PIXEL NETWORKS LIMITED</Typography>
          </Grid>
          <Grid item>
            <Link href="https://pixel-networks.com">
              https://pixel-networks.com
            </Link>
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(AboutModal);
