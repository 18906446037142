import EditWidgetSourceModal from "../../EditWidgetSourceModal";
import useMenu from "../menu/useMenu";

export default function useChartValueMenu({ property, object }) {
  const items = useMenu();

  return [
    items.genMenuEditItem({
      title: "Edit source",
      handleAction: () => {
        EditWidgetSourceModal({
          widgetId: object.id,
          propId: property.id,
          propKey: property.key,
          schemaTags: object.schemaTags,
        })
          .then()
          .catch(() => {});
      },
    }),
    items.genMenuShowHistory({
      disabled: true,
    }),
  ];
}
