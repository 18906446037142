import { gql, useApolloClient, useLazyQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { highlightSelectedStyle } from "../../constants";
import { msg } from "../../messages";
import { GET_DATA_SUBSCRIPTION } from "../../queries";
import useColors from "../../utils/useColors";
import useMedia from "../../utils/useMedia";
import WidgetEditControls from "../WidgetEditControls";

const useStyles = makeStyles((theme, colorBg) => ({
  root: {},
}));

const GET_PROPERTY = gql(`
query getObjectProperty($id: UUID!) {
    objectProperty(id: $id) {
        id
        spec {
          units
        }
    }
}
`);

const W_DataBox = (props) => {
  const { id, objectProperties, selected } = props;
  const { getImageById } = useMedia();
  const { getColorBasedOnStyle } = useColors();
  const theme = useTheme();
  const client = useApolloClient();
  const getPropValue = (prop) =>
    objectProperties.find((obj) => obj.key === prop)?.value;
  const mode = getPropValue("settingsFormat");
  const size = getPropValue("settingsSize");
  const newLine = getPropValue("settingsNewLine");
  let iconId = getPropValue("settingsIcon");
  let valueCurrentIcon = getPropValue("valueCurrentIcon");
  let statusAlarmInitial = getPropValue("statusAlarm");
  const valueInitial = getPropValue("valueValue") ?? msg.misc.na;
  const simulation = getPropValue("settingsSimulation");
  const title = getPropValue("settingsTitle");
  const style = getPropValue("settingsStyle");
  const valueCurrentColor = getPropValue("valueCurrentColor");
  const [value, setValue] = useState(valueInitial);
  const [unit, setUnit] = useState("");
  const [alarm, setAlarm] = useState(statusAlarmInitial);
  const [colors, setColors] = useState(
    getColorBasedOnStyle(style, valueCurrentColor)
  );
  const classes = useStyles();
  const isEditMode = useSelector((state) => state.settings.isEditMode);

  const [valueUnitsLazy] = useLazyQuery(GET_PROPERTY);

  useEffect(() => {
    const idLinkedProp = () =>
      objectProperties.find((obj) => obj.key === "valueValue")
        ?.linkedPropertyId;

    if (idLinkedProp()) {
      valueUnitsLazy({
        fetchPolicy: "no-cache",
        variables: {
          id: idLinkedProp(),
        },
      }).then(({ data }) => {
        setUnit(data.objectProperty.spec.units);
      });
    }
  }, [objectProperties.find((obj) => obj.key === "valueValue")]);

  useEffect(() => {
    const observer = client.subscribe({
      query: GET_DATA_SUBSCRIPTION,
      variables: { objId: id },
    });

    const subscription = observer.subscribe(({ data }) => {
      if (data.Objects.relatedNode?.key === "valueValue") {
        setValue(data.Objects.relatedNode?.value);
      } else if (data.Objects.relatedNode?.key === "valueCurrentColor") {
        setColors(getColorBasedOnStyle(style, data.Objects.relatedNode?.value));
      } else if (data.Objects.relatedNode?.key === "settingsStyle") {
        setColors(
          getColorBasedOnStyle(
            data.Objects.relatedNode?.value,
            valueCurrentColor
          )
        );
      } else if (data.Objects.relatedNode?.key === "statusAlarm") {
        setAlarm(data.Objects.relatedNode?.value);
      }
    });

    return () => subscription.unsubscribe();
  }, [id]);

  let total_px = 0,
    icon_px = 0,
    font_px = 0;

  if (mode === "icon_value" || mode === "icon")
    switch (size) {
      case "tiny":
        total_px = "48";
        icon_px = "24";
        font_px = "12";
        break;
      case "small":
        total_px = "96";
        icon_px = "43";
        font_px = "20";
        break;
      case "medium":
        total_px = "192";
        icon_px = "96";
        font_px = "45";
        break;
      // case "large":
      //   total_px = "384";
      //   icon_px = "90";
      //   font_px = "36";
      //   break;
      default:
    } //switch

  // shrink widget vertically if there is no icon
  if (mode === "value")
    switch (size) {
      case "tiny":
        total_px = "48";
        icon_px = "24";
        font_px = "32";
        break;
      case "small":
        total_px = "48";
        icon_px = "45"; //45
        font_px = "56";
        break;
      case "medium":
        total_px = "96";
        icon_px = "70"; //70
        font_px = "96";
        break;
      // case "large":
      //   total_px = "192";
      //   icon_px = "130"; //130
      //   font_px = "36";
      //   break;
      default:
    } //switch

  if (mode === "icon")
    switch (size) {
      case "tiny":
        total_px = "48";
        icon_px = "32";
        font_px = "32";
        break;
      case "small":
        total_px = "48";
        icon_px = "72"; //45
        font_px = "20";
        break;
      case "medium":
        total_px = "96";
        icon_px = "144"; //70
        font_px = "36";
        break;
      // case "large":
      //   total_px = "192";
      //   icon_px = "130";
      //   font_px = "36";
      //   break;
      default:
    } //switch

  useEffect(() => {
    setColors(
      getColorBasedOnStyle(
        getPropValue("settingsStyle"),
        getPropValue("valueCurrentColor")
      )
    );
  }, [props.objectProperties]);
  return (
    <>
      <Tooltip
        title={title ?? ""}
        disableTouchListener
        {...(title
          ? { disableHoverListener: false }
          : { disableHoverListener: true })}
        data-test-widget={props.object.name + title}
      >
        <div
          data-test-widget-databox={props.object.name}
          onClick={(e) => {
            props.handleClickWidget();
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            height: `100%`,
            backgroundColor: colors?.bg || "transparent",
            justifyContent: "center",
            alignItems: "center",
            pointerEvents: isEditMode ? "none" : "auto",
            filter: selected ? highlightSelectedStyle : "",
            borderRadius: "2px",
            background:
              alarm === "triggered"
                ? `linear-gradient(225deg, ${theme.palette.wRed} ${
                    size === "tiny" ? "10px" : "15px"
                  }, ${colors?.bg || "transparent"} ${
                    size === "tiny" ? "10px" : "15px"
                  } )`
                : `${colors?.bg || "transparent"}`,
          }}
        >
          {(mode === "icon_value" || mode === "icon") && (
            <div
              style={{
                display: "flex",
                alignItems: !(mode === "icon_value") ? "center" : "flex-end",
                flexGrow: 1,
              }}
            >
              <div
                data-test-icon="icon_value-icon"
                className={classes.root}
                style={{
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  WebkitMaskRepeat: `no-repeat`,
                  WebkitMaskSize: `contain`,
                  WebkitMaskImage: `url(${getImageById(
                    valueCurrentIcon || iconId
                  )})`,
                  maskImage: `url(${getImageById(valueCurrentIcon || iconId)})`,
                  maskRepeat: `no-repeat`,
                  maskSize: `contain`,
                  backgroundColor: colors?.fg || "transparent",
                  height: `${icon_px}px`,
                  width: `${icon_px}px`,
                }}
              ></div>
            </div>
          )}
          {(mode === "value" || mode === "icon_value") && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 1,
                paddingRight: "8px",
                paddingLeft: "8px",
                overflow: "hidden",
                maxWidth: "100%",
              }}
            >
              <Typography
                style={{
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  maxWidth: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: `${font_px}px`,
                  color: colors?.fg || "transparent",
                }}
              >
                {simulation ? _.random(10, 99) : value?.toString() || ""} {unit}
              </Typography>
            </div>
          )}
          <WidgetEditControls {...props} />
        </div>
      </Tooltip>
      {newLine && (
        <div
          style={{
            flexBasis: "100%",
            height: "0px",
          }}
        />
      )}
    </>
  );
};

export default W_DataBox;
