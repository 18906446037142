import { gql } from "../../../__generated__";

const CREATE_EXECUTION = gql(/* GraphQL */ `
  mutation createControlsExecution($input: CreateControlExecutionInput!) {
    createControlExecution(input: $input) {
      controlExecution {
        id
      }
      clientMutationId
    }
  }
`);

export { CREATE_EXECUTION };
