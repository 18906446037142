import { useMutation } from "@apollo/client";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { Button, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { loader } from "graphql.macro";
import { useEffect, useReducer } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import { useSelector } from "react-redux";
import CommonModal from "../components/CommonModal";
import CustomRadio from "../components/CustomRadio";
import CustomSwitch from "../components/CustomSwitch";
import { msg } from "../messages";
import { useAuth } from "../utils/useAuth";

const UPDATE_OBJECT_PROPERTY = loader(
  "../graphql/UpdateObjectWithProperties.graphql"
);

const EditNotificationsSettingsModal = (props) => {
  const { loadUser } = useAuth();
  const profile = useSelector((state) => state.profile);

  const [updateProperty] = useMutation(UPDATE_OBJECT_PROPERTY);

  let defaultValues = {};

  const theme = useTheme();
  const [values, setValues] = useReducer(
    (prev, updated) => ({ ...prev, ...updated }),
    defaultValues
  );

  useEffect(() => {
    const queryProps = async () => {
      try {
        defaultValues["notificationsGeneral"] = profile.notificationsGeneral;
        defaultValues["notificationsSmsNotifications"] =
          profile.notificationsSmsNotifications;
        defaultValues["notificationsEmailNotifications"] =
          profile.notificationsEmailNotifications;
        defaultValues["notificationsSoundInTheApp"] =
          profile.notificationsSoundInTheApp;

        defaultValues["notificationsExcludeTags"] =
          profile.notificationsExcludeTags;
        defaultValues["notificationsIncludeTags"] =
          profile.notificationsIncludeTags;
        defaultValues["notificationsWhatsappNotifications"] =
          profile.notificationsWhatsappNotifications;

        setValues(defaultValues);
      } catch (err) {
        toast.error(err.toString(), {
          position: "bottom-center",
        });
      } finally {
      }
    }; //queryProps

    queryProps();
  }, []);

  const submit = () => props.onResolve();

  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;
    if (checked !== undefined) {
      if (checked === true) {
        value = true;
      } else {
        value = false;
      }
    }
    setValues({ [name]: value });
  };

  const handleUpdateWidgetProps = (valuesReady) => {
    toast
      .promise(
        updateProperty({
          variables: {
            input: {
              detailedObject: [
                {
                  objectId: profile.id,
                  keyedProperties: valuesReady,
                },
              ],
            },
          },
        }),
        {
          loading: "Updating...",
          success: () => "Updated",
          error: (err) => `${err.toString()}`,
        },
        {
          position: "bottom-center",
        }
      )
      .then(() => {
        return loadUser();
      })
      .then(() => {
        submit();
      });
  };

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={msg.editNotificationsSettingsModal.notificationsSettings}
        handleClose={handleClose}
        buttons={
          <>
            <Button onClick={handleClose}>
              {msg.editNotificationsSettingsModal.buttonCancel}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                handleUpdateWidgetProps([
                  {
                    propertyKey: "notificationsGeneral",
                    value: values.notificationsGeneral,
                  },
                  {
                    propertyKey: "notificationsSmsNotifications",
                    value: Boolean(values.notificationsSmsNotifications),
                  },
                  {
                    propertyKey: "notificationsEmailNotifications",
                    value: Boolean(values.notificationsEmailNotifications),
                  },
                  {
                    propertyKey: "notificationsSoundInTheApp",
                    value: Boolean(values.notificationsSoundInTheApp),
                  },
                  {
                    propertyKey: "notificationsExcludeTags",
                    value: values.notificationsExcludeTags,
                  },
                  {
                    propertyKey: "notificationsIncludeTags",
                    value: values.notificationsIncludeTags,
                  },
                  {
                    propertyKey: "notificationsWhatsappNotifications",
                    value: Boolean(values.notificationsWhatsappNotifications),
                  },
                ]);
              }}
            >
              {msg.editNotificationsSettingsModal.buttonUpdate}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              {msg.editNotificationsSettingsModal.general}
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ paddingRight: "20px" }}>
            <CustomRadio
              name="notificationsGeneral"
              value={values["notificationsGeneral"] ?? ""}
              list={[
                {
                  icon: <VolumeUpIcon style={{ color: theme.palette.gray1 }} />,
                  title: "All notifications",
                  value: "all",
                },
                {
                  icon: (
                    <StarOutlineIcon style={{ color: theme.palette.gray1 }} />
                  ),
                  title: "Favorites only",
                  value: "favourites",
                },
                {
                  icon: (
                    <VolumeOffIcon style={{ color: theme.palette.gray1 }} />
                  ),
                  title: "Mute all",
                  value: "mute",
                },
              ]}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Optional tags
            </Typography>
          </Grid>

          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Autocomplete
              multiple
              fullWidth={true}
              options={[]}
              freeSolo
              onChange={(e, value) => {
                setValues({ notificationsExcludeTags: value });
              }}
              name={"notificationsExcludeTags"}
              defaultValue={values["notificationsExcludeTags"] ?? []}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="filled"
                    color={"primary"}
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Exclude tags"
                />
              )}
            />
          </Grid>

          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Autocomplete
              multiple
              fullWidth={true}
              options={[]}
              freeSolo
              name={"notificationsIncludeTags"}
              onChange={(e, value) => {
                setValues({ notificationsIncludeTags: value });
              }}
              defaultValue={values["notificationsIncludeTags"] ?? []}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="filled"
                    color={"primary"}
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Include tags"
                />
              )}
            />
          </Grid>

          <Grid item>
            <Typography variant="subtitle2" color="primary">
              {msg.editNotificationsSettingsModal.options}
            </Typography>
          </Grid>

          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <CustomSwitch
              name="notificationsSmsNotifications"
              label={msg.editNotificationsSettingsModal.sms}
              value={values["notificationsSmsNotifications"] ?? ""}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <CustomSwitch
              name="notificationsEmailNotifications"
              label={msg.editNotificationsSettingsModal.email}
              value={values["notificationsEmailNotifications"] ?? ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <CustomSwitch
              name="notificationsWhatsappNotifications"
              label="WhatsApp Notifications"
              value={values["notificationsWhatsappNotifications"] ?? ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <CustomSwitch
              name="notificationsSoundInTheApp"
              label="Sound in the app"
              value={values["notificationsSoundInTheApp"] ?? ""}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(EditNotificationsSettingsModal);
