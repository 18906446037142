import { useApolloClient } from "@apollo/client";
import List from "@mui/material/List";
import { useEffect, useState } from "react";
import { CONTROLS_QUERY } from "../../queries";
import Spinner from "../Spinner";
import TabControlItem from "./TabControlItem";

const TabContentControls = (props) => {
  const { groupId, widgetId, type, item } = props;
  const objId = type === "group" ? groupId : widgetId;
  const client = useApolloClient();
  const [controls, setControls] = useState();

  useEffect(() => {
    const query = async () => {
      try {
        const result = await client.query({
          query: CONTROLS_QUERY,
          variables: { objId: item.id },
        });

        const tmpControls = result.data.object.schema.schemaControls.map(
          (control) => {
            const exec = result.data.object.controlExecutions.find(
              (exec) => exec.name === control.rpc
            );

            if (exec)
              return {
                ...control,
                ack: exec.ack,
                done: exec.done,
                error: exec.error,
              };
            else return control;
          }
        );
        /*
        ack = false - запрошено выполнение

        ack = true, done = false  - вызов получен модулем, выполняется

        ack = true, done = true, error = false - закончено, без ошибки

        ack = true, done = true, error = true - закончено, без ошибки
        */
        setControls(tmpControls);
      } catch (err) {}
    };
    query();
  }, [objId]);

  if (!controls) return <Spinner />;

  return (
    <List>
      {controls.map((control) => {
        if (control.argument === control.rpc) {
          return (
            <TabControlItem
              control={control}
              controls={controls}
              key={control.id}
              objId={item.id}
            />
          );
        }
        return null;
      })}
    </List>
  );
};

export default TabContentControls;
