import { createSvgIcon } from "@mui/material";

const ArrowUp = createSvgIcon(
  <path
    d="M15 20.0002H9V12.0002H4.16L12 4.16016L19.84 12.0002H15V20.0002Z"
    fill="currentColor"
  />,
  "ArrowUp"
);

export default ArrowUp;
