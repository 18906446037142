import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { ChangeEvent, useEffect, useReducer, useState } from "react";
import { Renderable, toast, ValueOrFunction } from "react-hot-toast";
import { create, InstanceProps } from "react-modal-promise";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import ImageItem from "../../../components/common/image-item/ImageItem";
import CommonModal from "../../../components/CommonModal";
import CustomAutocomplete from "../../../components/CustomAutocomplete";
import CustomInput from "../../../components/CustomInput";
import DeleteReportModal from "../../../components/DeleteReportModal";
import FormField from "../../../components/FormField";
import useCustomNavigate from "../../../components/hooks/useCustomNavigate";
import useHandleCopy from "../../../components/side-card/menu/handlers/useHandleCopy";
import RpcSubscribeWrapper from "../../../components/side-card/static-table/RpcSubscribeWrapper";
import { msg } from "../../../messages";
import { setSettings } from "../../../store/settingsSlice";
import useMedia from "../../../utils/useMedia";
import { ADD_REPORT } from "../api/AddReport";
import { CREATE_EXECUTION } from "../api/CreateExecution";
import { LOAD_GROUPS } from "../api/GetGroups";
import { GET_REPORT } from "../api/GetReport";
import { GET_REPORT_PROPS } from "../api/GetReportProps";
import { REPORTS_QUERY_WITHOUT_COLLECTIONS } from "../api/ReportsList";
import { UPDATE_PROPERTIES } from "../api/UpdateProperties";

type AddReportModalProps = {
  reportId?: unknown;
};

type TValuesDefault = {
  generalBackgroundImageName?: string | null;
  generalBackgroundImageUid?: string | null;
  generalBgColor?: string;
  generalTitle?: string;
  generalTitleStyle?: string;
  [key: string]: unknown;
};

const AddReportModal = (props: AddReportModalProps & InstanceProps<{ reportId: any }>) => {
  const history = useCustomNavigate();
  const dispatch = useDispatch();
  const { uploadMedia } = useMedia();
  const handleCopy = useHandleCopy();

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const [addDashboard] = useMutation(ADD_REPORT);
  const groupsQuery = useQuery(LOAD_GROUPS);
  const [reportQuery] = useLazyQuery(GET_REPORT);
  const [updateProperties] = useMutation(UPDATE_PROPERTIES);
  const [createExecution] = useMutation(CREATE_EXECUTION);

  const [accessRights, setAccessRights] = useState({
    readerGroup: null,
    userGroup: null,
    editorGroup: null,
  });
  const [description, setDescription] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = {
    generalBackgroundImageName: null,
    generalBackgroundImageUid: null,
    generalBgColor: "#ffffff",
    generalTitle: "",
    generalTitleStyle: "dark",
  };

  const [fields, setFields] = useState([]);
  const [backgroundFileItem, setBackgroundFileItem] = useState(null);

  const valuesReducer = (prev: TValuesDefault, updated: TValuesDefault): TValuesDefault => ({
    ...prev,
    ...updated,
  });

  const [values, setValues] = useReducer(valuesReducer, defaultValues);

  const [dashboardsLazyQuery] = useLazyQuery(REPORTS_QUERY_WITHOUT_COLLECTIONS, {
    fetchPolicy: "network-only",
  });

  const validationSchema = yup.object({
    name: yup.string().trim().required("Name is required"),
  });

  const afterCreate = (id) => {
    history(`/reports/${id}`);
    dispatch(setSettings({ isEditMode: true }));
    submit();
  };

  const uploadImage = async (): Promise<string | null> => {
    if (!backgroundFileItem) {
      return null;
    }

    try {
      const { data }: { data: string } = await uploadMedia(backgroundFileItem);
      return data;
    } catch (e) {
      return null;
    }
  };

  const handleUpdateReport = async (formikValues) => {
    const generalBackgroundImageUid = await uploadImage();

    const payload = {
      objectId: props.reportId,
      name: formikValues.name,
      description,
      keyedProperties: [
        {
          propertyKey: "generalTitle",
          value: values.generalTitle,
        },
        {
          propertyKey: "generalTitleStyle",
          value: values.generalTitleStyle,
        },
        {
          propertyKey: "generalBgColor",
          value: values.generalBgColor,
        },
        {
          propertyKey: "generalBackgroundImageUid",
          value: generalBackgroundImageUid || values.generalBackgroundImageUid,
        },
        {
          propertyKey: "generalBackgroundImageName",
          value: values.generalBackgroundImageName,
        },
      ],
      editorgroup: accessRights.editorGroup,
      usergroup: accessRights.userGroup,
      readergroup: accessRights.readerGroup,
    };

    toast
      .promise(
        updateProperties({
          variables: {
            input: {
              detailedObject: [payload],
            },
          },
        }).then(() => dashboardsLazyQuery()),
        {
          loading: "Updating...",
          success: () => "Updated",
          error: (err: ValueOrFunction<Renderable, unknown>) => {
            if (typeof err === "string") {
              return err.toString();
            } else {
              return "Error appears during editing of report";
            }
          },
        },
        {
          position: "bottom-center",
        }
      )
      .then(() => {
        return dashboardsLazyQuery();
      })
      .then(() => {
        submit();
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAddDashboard = async (formikValues) => {
    const generalBackgroundImageUid = await uploadImage();

    const payload = {
      name: formikValues.name,
      values: [
        {
          propertyKey: "generalTitle",
          value: values.generalTitle,
        },
        {
          propertyKey: "generalTitleStyle",
          value: values.generalTitleStyle,
        },
        {
          propertyKey: "generalBgColor",
          value: values.generalBgColor,
        },
        {
          propertyKey: "generalBackgroundImageUid",
          value: generalBackgroundImageUid || values.generalBackgroundImageUid,
        },
        {
          propertyKey: "generalBackgroundImageName",
          value: values.generalBackgroundImageName,
        },
      ],
      editorGroup: accessRights.editorGroup,
      userGroup: accessRights.userGroup,
      readerGroup: accessRights.readerGroup,
      description,
    };

    toast
      .promise(
        addDashboard({
          variables: payload,
        }),
        {
          loading: "Creating report...",
          success: () => "Report created",
          error: (err: ValueOrFunction<Renderable, unknown>) => {
            if (typeof err === "string") {
              return err.toString();
            } else {
              return "Error appears during creation of report";
            }
          },
        },
        {
          position: "bottom-center",
        }
      )
      .then(async ({ data }) => {
        await dashboardsLazyQuery();
        afterCreate(data.createObjectWithProperties.uuid);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: { name: "" },
    validationSchema,
    onSubmit: async (formikValues) => {
      setIsLoading(true);
      if (props.reportId) {
        await handleUpdateReport(formikValues);
      } else {
        await handleAddDashboard(formikValues);
      }
    },
  });

  const [dashboardsQueryProps, { loading }] = useLazyQuery(GET_REPORT_PROPS, {
    fetchPolicy: "network-only",
    onCompleted: ({ schemata }) => {
      const localValues = { ...defaultValues };
      setFields(schemata[0].schemaProperties);

      schemata[0].schemaProperties.forEach((prop) => {
        localValues[prop.key] = prop.defaultValue;
      });

      setValues(localValues);
      formik.setValues({ name: `Report #${schemata[0].objectsCount + 1}` }).catch(() => {});
    },
  });

  const getGroups = () => {
    if (!groupsQuery.loading && groupsQuery.data) {
      return groupsQuery.data.userGroups.map((item) => ({
        value: item.id,
        title: item.groupName,
        disabled: item.groupName === "Nobody",
      }));
    }
    return [];
  };

  const handleInputChange = (e) => {
    let { value } = e.target;
    const { checked } = e.target;
    if (checked) value = checked;

    setValues({ [e.target.name]: value });
  };

  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: "CopyReport",
            objectId: props.reportId,
            params: {
              UUID: props.reportId,
              NAME: `${formik.values.name} copy`,
            },
          },
        },
      },
    });
  };

  useEffect(() => {
    if (props.reportId) {
      reportQuery({
        variables: {
          objId: props.reportId,
        },
        fetchPolicy: "network-only",
      })
        .then(({ data }) => {
          if (data) {
            data.object.objectProperties.forEach((prop) => {
              defaultValues[prop.key] = prop.value;
            });
            const localFields = data.object.objectProperties.map((prop) => {
              return {
                key: prop.key,
                description: prop.spec.description,
                type: prop.spec.type,
                valueSet: prop.spec.valueSet,
                groupName: prop.groupName,
                valueRange: prop.spec.valueRange,
                hidden: prop.spec.hidden,
              };
            });

            setFields(localFields);
            setValues(defaultValues);
            setAccessRights({
              readerGroup: data.object.readergroup,
              userGroup: data.object.usergroup,
              editorGroup: data.object.editorgroup,
            });
            setDescription(data.object.description);
            formik.setValues({ name: data.object.name }).catch(() => {});
          }
        })
        .catch(() => {});
    } else {
      dashboardsQueryProps().catch(() => {});
    }
  }, [props.reportId]);
  return (
    <>
      <CommonModal
        loading={loading || isLoading}
        modalOpen={props.isOpen}
        title={props.reportId ? "Edit report" : "Add new report"}
        forceTitle={true}
        contentStyles={{
          padding: "14px 16px 16px 14px",
        }}
        handleClose={handleClose}
        buttons={
          <>
            <Button onClick={handleClose}>{msg.addDashboardModal.buttonCancel}</Button>
            <Button color="primary" data-test="createReport" onClick={() => formik.handleSubmit()}>
              {props.reportId ? "Save" : "Add"}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomInput
              name="name"
              onBlur={formik.handleBlur}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              label="Name"
              clearFieldIcon={true}
            />
          </Grid>

          {fields
            .filter((item) => item.groupName === "General" && !item.hidden)
            .map((field) => (
              <FormField key={field.key} values={values} field={field} handleInputChange={handleInputChange} />
            ))}

          <Grid item>
            <ImageItem
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setValues({ generalBackgroundImageUid: e.target.value });
              }}
              onSelectFile={(file) => {
                setBackgroundFileItem(file);
              }}
              id={values.generalBackgroundImageUid}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Access rights
            </Typography>
          </Grid>

          <Grid item>
            <CustomAutocomplete
              label={"Editors group"}
              disabled={groupsQuery.loading}
              list={getGroups()}
              value={accessRights.editorGroup}
              onChange={(e) => {
                setAccessRights({
                  ...accessRights,
                  editorGroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              disabled={groupsQuery.loading}
              label={"Users group"}
              list={getGroups()}
              value={accessRights.userGroup}
              onChange={(e) => {
                setAccessRights({
                  ...accessRights,
                  userGroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              label={"Readers group"}
              disabled={groupsQuery.loading}
              list={getGroups()}
              value={accessRights.readerGroup}
              onChange={(e) => {
                setAccessRights({
                  ...accessRights,
                  readerGroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>

          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Description
            </Typography>
          </Grid>

          <Grid item>
            <CustomInput
              name="description"
              label={msg.addDashboardModal.description}
              clearFieldIcon={true}
              value={description}
              multiline={true}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setDescription(e.target.value);
              }}
            />
          </Grid>

          {props.reportId && (
            <>
              <Grid item>
                <Button
                  data-test="copyDashboardId"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disableElevation
                  style={{ marginTop: "20px" }}
                  onClick={() => {
                    handleCopy({
                      object: {
                        name: "",
                      },
                      message: "Report copied successfully",
                      text: props.reportId,
                    });
                  }}
                >
                  {msg.editDashboardModal.buttonCopy}
                </Button>

                <RpcSubscribeWrapper
                  rpcName={"CopyReport"}
                  objectId={props.reportId}
                  object={null}
                  handler={rpcHandler}
                  title={"Copy Report"}
                  successCb={(reportId) => {
                    window.location.href = `/reports/${reportId}`;
                  }}
                >
                  <LoadingButton
                    data-test-copy-report-rpc
                    variant="outlined"
                    color="primary"
                    fullWidth
                    disableElevation
                    style={{ marginTop: "20px" }}
                  ></LoadingButton>
                </RpcSubscribeWrapper>

                <Button
                  data-test="deleteReport"
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disableElevation
                  style={{ marginTop: "16px" }}
                  onClick={() => {
                    DeleteReportModal({
                      open: true,
                      dashboardId: props.reportId,
                      name: formik.values.name,
                    })
                      .then(() => {
                        submit();
                      })
                      .catch(() => {});
                  }}
                >
                  Delete report
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(AddReportModal);
