import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  [key: string]: unknown;
} = null;

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateProfile: (
      state,
      action: PayloadAction<{ [key: string]: unknown }>
    ) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateProfile } = profileSlice.actions;

export const profileReducer = profileSlice.reducer;
