import { createSvgIcon } from "@mui/material";

const ChartIcon = createSvgIcon(
  <path
    d="M22 21H2V3.00001H4V15.54L9.5 6.00001L16 9.78001L20.24 2.45001L21.97 3.45001L22 21Z"
    fill="#686868"
  />,
  "ChartIcon"
);

export default ChartIcon;
