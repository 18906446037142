import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GetAppIcon from "@mui/icons-material/GetApp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TodayIcon from "@mui/icons-material/Today";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { formatISO, sub } from "date-fns";
import { useContext, useState } from "react";
import { ModalContext } from "../../../context/modal/ModalContext";
import useRoute from "../../../hooks/useRoute";
import DateModal from "../../modals/DateModal";
import useMoreMenu from "../../useMoreMenu";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingRight: "4px",
    paddingLeft: "0",
    paddingTop: "4px",
    paddingBottom: "4px",
    marginLeft: "8px",
  },
  headerTitle: {
    width: "100%",
  },
}));
const HistoryToolbar = ({
  setRecordedFilter,
  setAfter,
  downloadHistory,
  downloadIds,
  ...props
}) => {
  const {
    MoreMenu: HistoryMenu,
    openMoreMenu: openHistoryMenu,
    closeMoreMenu: closeHistoryMenu,
  } = useMoreMenu();

  const { handleGoBack } = useRoute();
  const modal = useContext(ModalContext);
  const classes = useStyles();

  const [date, setDate] = useState("day");

  let menu = [
    {
      icon: <TodayIcon />,
      title: "Day",
      id: "day",
      disabled: false,
      checked: date === "day",
      handleAction: () => {
        setRecordedFilter([
          formatISO(sub(Date.now(), { days: 1 })),
          formatISO(Date.now()),
        ]);
        setAfter(null);
        setDate("day");
      },
    },
    {
      icon: <TodayIcon />,
      title: "Week",
      id: "week",
      checked: date === "week",
      disabled: false,
      handleAction: () => {
        setRecordedFilter([
          formatISO(sub(Date.now(), { weeks: 1 })),
          formatISO(Date.now()),
        ]);
        setAfter(null);
        setDate("week");
      },
    },
    {
      icon: <TodayIcon />,
      title: "Month",
      id: "month",
      checked: date === "month",
      disabled: false,
      handleAction: () => {
        setRecordedFilter([
          formatISO(sub(Date.now(), { months: 1 })),
          formatISO(Date.now()),
        ]);
        setAfter(null);
        setDate("month");
      },
    },
    {
      id: "divider",
    },
    {
      icon: <GetAppIcon />,
      title: "Download",
      id: "download history",
      disabled: false,
      handleAction: () => {
        DateModal({
          downloadHistory: downloadHistory,
          downloadIds: downloadIds,
        })
          .then()
          .catch(() => {});
      },
    },
  ];
  return (
    <>
      <HistoryMenu checkable items={menu} />
      <Toolbar variant="dense" className={classes.header}>
        <IconButton
          style={{ marginRight: "16px" }}
          onClick={handleGoBack}
          size="medium"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          noWrap={true}
          style={{ display: "block", width: "100%" }}
          variant="h6"
        >
          {props.name}
        </Typography>
        <IconButton
          aria-label="settings"
          onClick={openHistoryMenu}
          size="large"
          data-test-settings="settings"
        >
          <MoreVertIcon />
        </IconButton>
      </Toolbar>

      <Divider />
    </>
  );
};

export default HistoryToolbar;
