import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Fade } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ModalContext } from "../context/modal/ModalContext";
import { msg } from "../messages";
import DeleteWidgetModal from "./DeleteWidgetModal";
import EditWidgetModal from "./EditWidgetModal";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    background: "rgba(0,0,0,0.4)",
    zIndex: theme.zIndex.drawer + 1,
    position: "fixed",
    left: "2px",
    top: "2px",
    right: "0",
    bottom: "0",
    width: "calc(100% - 4px)",
    height: "calc(100% - 4px)",
    pointerEvents: "auto",
    justifyContent: "space-evenly",
    alignItems: "center",
    borderRadius: "2px",
  },
  iconButton: {
    padding: "0",
    pointerEvents: "auto",
  },
}));

const WidgetEditControls = (props) => {
  const { groupId, widgetId } = useParams();

  const {
    modal = true,
    id,
    order,
    handleMove,
    widgetType,
    groupNames,
    name,
    handleUpdateWidget,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isEditMode = useSelector((state) => state.settings.isEditMode);

  const modalCtx = useContext(ModalContext);

  const checkIsMayShow = () => {
    if (isEditMode && groupId) {
      if (groupId === props.groupId) {
        if (widgetId) {
          if (props.object.id === widgetId) {
            return true;
          }
          {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <Fade in={checkIsMayShow()}>
      <div className={classes.wrapper}>
        <Grid container justifyContent="center">
          <Grid item style={{ whiteSpace: "nowrap" }}>
            <Tooltip title={props.object.name}>
              <IconButton
                data-test-widget-control-edit={props.object.name}
                className={classes.iconButton}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                onClick={(e) => {
                  EditWidgetModal({
                    object: props.object,
                  })
                    .then()
                    .catch(() => {});
                }}
                size="large"
              >
                <EditIcon
                  fontSize="small"
                  style={{ color: theme.palette.white }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title={msg.widgetEdit.delete}>
              <IconButton
                data-test-widget-control-delete={props.object.name}
                className={classes.iconButton}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                onClick={(e) => {
                  DeleteWidgetModal({
                    widgetId: id,
                    groupId: groupId,
                    name,
                  })
                    .then()
                    .catch(() => {});
                }}
                size="large"
              >
                <DeleteIcon
                  fontSize="small"
                  style={{ color: theme.palette.white }}
                />
              </IconButton>
            </Tooltip>

            {/*<Tooltip title={msg.widgetEdit.add}>*/}
            {/*  <IconButton*/}
            {/*    className={classes.iconButton}*/}
            {/*    onMouseDown={(e) => {*/}
            {/*      e.stopPropagation();*/}
            {/*    }}*/}
            {/*    onClick={(e) => {*/}
            {/*      props.saveBoard(e, true);*/}
            {/*      props.setAddWidgetModalState({*/}
            {/*        open: true,*/}
            {/*        groupId: groupId,*/}
            {/*        widgetIdClicked: id,*/}
            {/*        dashboardId: dashboardId,*/}
            {/*      });*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <AddIcon fontSize="small" style={{ color: theme.palette.white }} />*/}
            {/*  </IconButton>*/}
            {/*</Tooltip>*/}
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default React.memo(WidgetEditControls);
