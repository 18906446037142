import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { useContext } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { ModalContext } from "../../../context/modal/ModalContext";
import useRoute from "../../../hooks/useRoute";
import { setSettings } from "../../../store/settingsSlice";
import downloadNotifications from "../../../utils/downloadNotifications";
import hideIfEmpty from "../../../utils/hideIfEmpty";
import useCustomNavigate from "../../hooks/useCustomNavigate";
import DateModal from "../../modals/DateModal";
import EditDevice from "../../modals/EditDevice";
import useHandleCopy from "./handlers/useHandleCopy";
import useMenu from "./useMenu";

const UNLINK_OBJECT = loader("../../../graphql/UnlinkObject.graphql");


import { REPORTS_QUERY_WITHOUT_COLLECTIONS } from "../../../modules/reports/api/ReportsList";
import { useParams } from "react-router-dom";

// TODO: Need to extract to file
const DASHBOARDS_QUERY_WITHOUT_COLLECTIONS = gql(/* GraphQL */ `
    query getDashboardsWithoutCollections {
        dashboards: objects(
            filter: {
                schemaTags: { contains: ["application", "board", "dashboard"] }
                objectsToObjectsByObject1IdConnection: {
                    every: { object2: { not: { schemaTags: { contains: ["collection"] } } } }
                }
            }
            orderBy: NAME_ASC
        ) {
            id
            name
        }
    }
`);


export default function useObjectMenu() {
  const dispatch = useDispatch();
  const modal = useContext(ModalContext);
  const items = useMenu();
  const handleCopy = useHandleCopy();
  const [unlinkObject] = useMutation(UNLINK_OBJECT);
  const navigate = useCustomNavigate();
  const { getParamsByRoute } = useRoute();
  const { collectionId } = useParams();

  const [dashboardsLazyQuery] = useLazyQuery(REPORTS_QUERY_WITHOUT_COLLECTIONS, {
    fetchPolicy: "network-only",
  });

  const [dashboardsWithoutCollectionsLazyQuery] = useLazyQuery(DASHBOARDS_QUERY_WITHOUT_COLLECTIONS, {
    fetchPolicy: "network-only",
  });

  const getMenu = (object) => {
    if (!object) {
      return [];
    }

    return [
      items.genMenuEditItem({
        handleAction: () => {
          EditDevice({
            object,
          })
            .then()
            .catch(() => {});
        },
      }),
      items.genMenuAccess({
        disabled: true,
      }),
      items.getMenuDisableItem({
        disabled: true,
      }),
      items.genMenuMuteItem({
        disabled: true,
      }),
      items.genMenuCreateCopy({
        disabled: true,
      }),
      items.genMenuSendItem({
        disabled: true,
      }),
      items.genMenuShowHistory({
        handleAction: () => {
          const { dashboardId, lang, groupId, widgetId } = getParamsByRoute();

          dispatch(setSettings({ isShowHistory: true }));
          navigate(
            `/boards/${dashboardId}/${groupId}/${widgetId}/${object.id}`
          );
        },
      }),
      items.genMenuShowControlsHistory({
        handleAction: () => {
          const { dashboardId, lang, groupId, widgetId } = getParamsByRoute();
          dispatch(setSettings({ isShowControlsHistory: true }));

          navigate(
            `/boards/${dashboardId}/${groupId}/${widgetId}/${object.id}`
          );
        },
      }),
      items.genMenuDownloadNotifications({
        handleAction: () => {
          DateModal({
            downloadHistory: downloadNotifications,
            downloadIds: [object.id],
          })
            .then()
            .catch(() => {});
        },
      }),
      items.genCopyUUID({
        handleAction: () => handleCopy({ object }),
      }),
      items.genMenuDelete({
        disabled: true,
      }),

      ...hideIfEmpty(object.linkId, [
        items.genUnlinkItem({
          handleAction: () => {
            toast.promise(
              unlinkObject({
                variables: {
                  linkId: object.linkId,
                },
              }).then(() => {
                if (collectionId) {
                  return Promise.allSettled([
                    dashboardsLazyQuery(),
                    dashboardsWithoutCollectionsLazyQuery()
                  ])
                } else {
                  return Promise.resolve()
                }
              }),
              {
                loading: "Unlinking object...",
                success: () => "Object unlinked",
                error: (err) => `${err.toString()}`,
              },
              {
                position: "bottom-center",
              }
            );
          },
        }),
      ]),
    ];
  };

  return {
    getMenu,
  };
}
