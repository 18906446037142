import { Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { create } from "react-modal-promise";
import { msg } from "../../messages";
import CommonModal from "../CommonModal";
import FormField from "../FormField";

const EditDynamicProperty = (props) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(props.value || null);

  const submit = () => props.onResolve();

  const reject = () => props.onReject();

  const handleSave = async () => {
    setLoading(true);
    props.handleSave(props.id, value).finally(() => {
      setLoading(false);
      submit();
    });
  };

  const handleInputChange = (e) => {
    let { value, checked } = e.target;
    if (checked) value = checked;

    setValue(value);
  };

  return (
    <CommonModal
      key="EditDevice"
      modalOpen={props.isOpen}
      title="Edit property"
      handleClose={reject}
      buttons={
        <>
          <Button onClick={reject}>{msg.default.cancel}</Button>
          <Button color="primary" disabled={loading} onClick={handleSave}>
            {loading ? <CircularProgress size={23} /> : msg.default.save}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormField
            values={{ [props.property.spec.key]: value }}
            field={props.property.spec}
            handleInputChange={handleInputChange}
          />
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default create(EditDynamicProperty);
