import TextField from "@mui/material/TextField";
import { castToType } from "../utils/misc.js";

const CustomDate = (props) => {
  const handleInputChangeCasted = (event, type) => {
    let { name, value, checked } = event.target;
    const e = {
      target: { name: name, value: castToType(value, type), checked: checked },
    };
    props.onChange(e);
  };

  return (
    <>
      <TextField
        variant="standard"
        label={props.label}
        inputProps={{
          min: props.min,
          max: props.max,
        }}
        name={props.name}
        id={`${props.name}-label`}
        value={props.value}
        type="date"
        disabled={Boolean(props.disabled)}
        onChange={(e) => {
          handleInputChangeCasted(e, props.propType);
        }}
        fullWidth
        maxRows={2}
        rows={2}
        multiline={props.multiline}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  );
};

export default CustomDate;
