import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { create } from "react-modal-promise";
import { msg } from "../../messages";
import { isTimeseries } from "../../utils/objectType";
import CommonModal from "../CommonModal";
import CustomAutocomplete from "../CustomAutocomplete";
import CustomInput from "../CustomInput";
import CustomSwitch from "../CustomSwitch";
import FormField from "../FormField";

const UPDATE_OBJECT = gql`
  mutation update($input: UpdateObjectInput!) {
    updateObject(input: $input) {
      clientMutationId
    }
  }
`;

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const GET_OBJECTS = gql`
  query listObjects {
    objects(
      filter: { schemaType: { in: ["device", "dataset", "application"] } }
    ) {
      name
      id
      objectProperties {
        id
        groupName
        spec {
          units
          key
          valueRange
          valueSet
          description
          property
          type {
            name
          }
        }
      }
    }
  }
`;

const EditTimeseries = (props) => {
  const defaultValues = {
    settingsPeriod: null,
  };

  const [updateObject, { loading }] = useMutation(UPDATE_OBJECT);
  const [updateProperty] = useMutation(UPDATE_PROPERTY);

  const [open, toggle] = useState(true);

  const [name, setName] = useState("");
  const [enabled, setEnabled] = useState(true);
  const [description, setDescription] = useState("");

  // settings
  const [objectId, setObjectId] = useState(null);
  const [objectProperty, setObjectProperty] = useState(null);
  const [period, setPeriod] = useState(null);

  const [loadObjects, { data, loading: loadingObjects }] = useLazyQuery(
    GET_OBJECTS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleInputChange = (e) => {
    setPeriod(e.target.value);
  };

  const list = () => {
    if (data) {
      return data.objects.map((item) => {
        return { value: item.id, title: item.name };
      });
    }
    return [];
  };

  const listProperties = () => {
    if (objectId && data?.objects) {
      const selectedObject = data.objects.find((item) => item.id === objectId);
      return selectedObject.objectProperties.map((item) => {
        return {
          value: item.id,
          title: `${item.groupName}/${
            item.spec.description || item.spec.property
          }`,
          rawProperty: item,
        };
      });
    }
    return [];
  };

  const settingsObject = () => {
    return props.object.objectProperties.find(
      (item) => item.key === "settingsObject"
    );
  };

  const settingsProperty = () => {
    return props.object.objectProperties.find(
      (item) => item.key === "settingsProperty"
    );
  };

  const settingsPeriod = () => {
    return props.object.objectProperties.find(
      (item) => item.key === "settingsPeriod"
    );
  };

  useEffect(() => {
    setName(props.object.name);
    setEnabled(props.object.enabled);
    setDescription(props.object.description || "");
    loadObjects();

    setObjectId(settingsObject().value);
    setObjectProperty(settingsProperty().value);
    setPeriod(settingsPeriod().value);
  }, [props.object.id]);

  const handleSave = async () => {
    await updateObject({
      variables: {
        input: {
          id: props.object.id,
          patch: {
            enabled,
            description,
            name,
          },
        },
      },
    })
      .then(() => {
        return Promise.allSettled([
          updateProperty({
            variables: {
              input: {
                id: settingsObject().id,
                patch: {
                  value: objectId,
                },
              },
            },
          }),
          updateProperty({
            variables: {
              input: {
                id: settingsProperty().id,
                patch: {
                  value: objectProperty,
                },
              },
            },
          }),
          updateProperty({
            variables: {
              input: {
                id: settingsPeriod().id,
                patch: {
                  value: period,
                },
              },
            },
          }),
        ]);
      })
      .then(() => {
        submit();
      });
  };

  return (
    <CommonModal
      key="EditDevice"
      modalOpen={props.isOpen}
      handleClose={reject}
      title={msg.editDeviceModal.title}
      buttons={
        <>
          <Button onClick={reject}>{msg.default.cancel}</Button>
          <Button color="primary" disabled={loading} onClick={handleSave}>
            {loading ? <CircularProgress size={23} /> : msg.default.save}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <CustomInput
            name="name"
            label={msg.addNewObjectModal.name}
            clearFieldIcon={true}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <CustomSwitch
            name="notificationsEmailNotifications"
            label={msg.addNewObjectModal.enabled}
            value={enabled}
            onChange={(e) => setEnabled(!enabled)}
          />
        </Grid>
        <Grid item>
          <Typography
            color="primary"
            variant="subtitle2"
            style={{ marginBottom: "12px" }}
          >
            {msg.addNewObjectModal.description}
          </Typography>
          <CustomInput
            name="name"
            label={msg.addNewObjectModal.description}
            clearFieldIcon={true}
            value={description}
            multiline={true}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </Grid>

        {isTimeseries(props.object.schemaTags) && (
          <>
            <Grid item>
              <Typography
                color="primary"
                variant="subtitle2"
                style={{ marginBottom: "12px" }}
              >
                Settings
              </Typography>
            </Grid>
            <Grid item>
              <CustomAutocomplete
                name="Object"
                label="Object"
                list={list()}
                loading={loadingObjects}
                value={objectId}
                onChange={(e) => setObjectId(e.target.value)}
              />
            </Grid>
            <Grid item>
              <CustomAutocomplete
                name="Property"
                label="Property"
                list={listProperties()}
                loading={loadingObjects}
                value={objectProperty}
                onChange={(e) => setObjectProperty(e.target.value)}
              />
            </Grid>
            <Grid item>
              <FormField
                values={{ settingsPeriod: period }}
                field={settingsPeriod().spec}
                handleInputChange={handleInputChange}
              />
            </Grid>
          </>
        )}
      </Grid>
    </CommonModal>
  );
};

export default create(EditTimeseries);
