// import './ReactotronConfig';
import { ApolloProvider } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import {
  ApolloClient,
  ApolloLink,
  from,
  fromPromise,
  HttpLink,
  split,
} from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import {
  getMainDefinition,
  relayStylePagination,
} from "@apollo/client/utilities";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { processSubscriptionNode } from "./apollo/utils";
import { wsLink } from "./apollo/WebSocketLink";
import AppWrapper from "./AppWrapper";
import { ACCESS_TOKEN_MUTATION } from "./queries";
import { store } from "./store";
// import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";

let httpEndpoint;

httpEndpoint = window.__pixelConfig.APP_API;

const getAuthToken = () => {
  return localStorage.getItem("authToken");
};
// loadDevMessages();
// loadErrorMessages();

export let client;

export const getNewToken = async (operation) => {
  localStorage.removeItem("authToken");

  operation.setContext(() => ({
    headers: {},
  }));

  const result_access = await client.mutate({
    mutation: ACCESS_TOKEN_MUTATION,
    variables: {
      input: {
        userRefreshToken: localStorage.getItem("refreshToken"),
        accessTokenExpiration: 24 * 60,
        profileTags: ["application", "monitor", "user profile"],
      },
    },
    fetchPolicy: "no-cache",
  });

  const authToken = result_access.data.authAccessToken.jwtToken;

  if (authToken) {
    localStorage.setItem("authToken", authToken);
    return authToken;
  } else {
    return Promise.reject(new Error("error getting token"));
  }
}; //getNewToken

const authMiddleware = new ApolloLink((operation, forward) => {
  const newHeaders = {};

  if (getAuthToken()) {
    newHeaders.authorization = `Bearer ${getAuthToken()}`;
  }

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...newHeaders,
    },
  }));

  return forward(operation);
});

const httpLink = new HttpLink({
  uri: httpEndpoint,
});

const AUTH_ERRORS = ['TokenExpiredError', 'JsonWebTokenError'];

const errorHandle = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    //    return false
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        if (operation.operationName === "getTranslations") return;
        if (AUTH_ERRORS.includes(err?.name)) {
          localStorage.removeItem("authToken");
          return fromPromise(
            getNewToken(operation)
              .then((response) => {
                operation.setContext({
                  headers: {
                    authorization: `Bearer ${response}`,
                  },
                });
                return forward(operation);
              })
              .catch((err) => {
                localStorage.removeItem("authToken");
                localStorage.removeItem("refreshToken");

                localStorage.setItem(
                  "lastUrlPathname",
                  window.location.pathname
                );
                window.location = `/login`;
              })
          )
            .filter((value) => Boolean(value))
            .flatMap((accessToken) => {
              operation.setContext({
                headers: {
                  authorization: `Bearer ${accessToken}`,
                },
              });
              return forward(operation);
            });
        } else {
          localStorage.setItem("lastUrlPathname", window.location.pathname);
        }
      }
    }
  }
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const retryLink = new RetryLink();

const defaultOptions = {
  watchQuery: {
    errorPolicy: "all",
  },
  query: {
    errorPolicy: "all",
  },
  ssrMode: false,
  ssrForceFetchDelay: 0,
  connectToDevTools: process.env.NODE_ENV === "development",
};

client = new ApolloClient({
  link: from([errorHandle, authMiddleware, retryLink, splitLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          notificationDeliveriesConnection: relayStylePagination([
            "filter",
            "orderBy",
          ]),
          objectPropertiesHistoriesConnection: relayStylePagination([
            "filter",
            ["propertyId"],
          ]),
          controlExecutionsConnection: relayStylePagination(["filter"]),
        },
      },
      Subscription: {
        fields: {
          listen: processSubscriptionNode(),
          Objects: processSubscriptionNode(),
          Object: processSubscriptionNode(),
          ObjectProperty: processSubscriptionNode(),
          ObjectsToObject: processSubscriptionNode(),
          Schemas: processSubscriptionNode(),
          UserProfile: processSubscriptionNode(),
        },
      },
    },
  }),
  defaultOptions,
});

const target = document.querySelector("#root");

const root = createRoot(target);

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <AppWrapper />
    </Provider>
  </ApolloProvider>
);
