import { useEffect, useRef } from "react";

/* Usage:
import { useTimeout, useInterval } from './utils/useTimer';

const timerResize1 = useTimeout();
const timerResize2 = useTimeout();

timerResize1.current = setTimeout(() => window.dispatchEvent(new Event('resize')), 350) ;
timerResize2.current = setTimeout(() => window.dispatchEvent(new Event('resize')), 350) ;

*/

const useTimeout = () => {
  const timeout = useRef();
  useEffect(
    () => () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    },
    []
  );
  return timeout;
};

const useInterval = () => {
  const interval = useRef();
  useEffect(
    () => () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    },
    []
  );
  return interval;
};

export { useTimeout, useInterval };
