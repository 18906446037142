import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({}));

const Spinner = React.memo((props) => {
  const classes = useStyles();
  //  const theme = useTheme();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ margin: "auto", minHeight: "100vh" }}
    >
      <CircularProgress size={80} />
    </Grid>
  );
});

export default Spinner;
