import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import toast from "react-hot-toast";
import EditWidgetPropertyModal from "../../EditWidgetPropertyModal";
import useHandleCopy from "../menu/handlers/useHandleCopy";
import useMenu from "../menu/useMenu";
const UPDATE_PROPERTIES_BY_IDS = loader(
  "../../../graphql/UpdateObjectPropertiesByIdMutation.graphql"
);

export default function useDefaultPropertyMenu({ property, object }) {
  const items = useMenu();
  const handleCopy = useHandleCopy();
  const [updateProperties] = useMutation(UPDATE_PROPERTIES_BY_IDS);

  return [
    items.genMenuEditItem({
      handleAction: () => {
        EditWidgetPropertyModal({
          widgetId: object.id,
          propId: property.id,
          propKey: property.key,
        })
          .then()
          .catch(() => {});
      },
    }),
    items.genUnlinkItem({
      title: "Set default",
      id: "set-default",
      handleAction: () => {
        toast.promise(
          updateProperties({
            variables: {
              input: {
                propertiesArray: [
                  {
                    propertyId: property.id,
                    value: property?.spec.defaultValue,
                  },
                ],
              },
            },
          }),
          {
            loading: "Updating property...",
            success: () => "Property updated",
            error: (err) => `${err.toString()}`,
          },
          {
            position: "bottom-center",
          }
        );
      },
    }),
    items.getSetNull({
      handleAction: () => {
        toast.promise(
          updateProperties({
            variables: {
              input: {
                propertiesArray: [
                  {
                    propertyId: property.id,
                    value: null,
                  },
                ],
              },
            },
          }),
          {
            loading: "Updating property...",
            success: () => "Property updated",
            error: (err) => `${err.toString()}`,
          },
          {
            position: "bottom-center",
          }
        );
      },
    }),
    items.genCopyUUID({
      handleAction: () =>
        handleCopy({ message: "UUID copied successfully", text: property.id }),
    }),
    items.genMenuShowHistory({
      disabled: true,
      handleAction: () => {
        // setIdProperty(obj.propId);
        // dispatch(setSettings({ isShowHistoryProperty: true }));
      },
    }),
  ];
}
