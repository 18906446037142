import { gql } from "../../../__generated__";

const GET_REPORT = gql(/* GraphQL */ `
  query getReport($objId: UUID!) {
    object(id: $objId) {
      id
      name
      description
      readergroup
      usergroup
      editorgroup
      objectProperties(sort: { sortBy: { field: PROPERTY } }) {
        groupName
        property
        spec {
          units
          description
          type {
            name
          }
          valueRange
          valueSet
          hidden
        }
        key
        value
      }
    }
  }
`);

export { GET_REPORT };
