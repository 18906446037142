import { gql } from "../../../__generated__";

const GET_REPORT_PROPS = gql(/* GraphQL */ `
  query reportProps {
    schemata(first: 1, filter: { mTags: { equalTo: ["application", "board", "report"] } }) {
      name
      objectsCount
      schemaProperties {
        defaultValue
        groupName
        property
        type {
          name
        }
        description
        hidden
        valueSet
        key
      }
    }
  }
`);

export { GET_REPORT_PROPS };
