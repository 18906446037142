import useChartValueMenu from "./useChartValueMenu";
import useDefaultPropertyMenu from "./useDefaultPropertyMenu";
import useValueActionMenu from "./useValueActionMenu";
import useValueMenu from "./useValueMenu";
import useValueTextMenu from "./useValueTextMenu";

export default function usePropertyMenu() {
  const getPropertyMenu = ({ property, object, linkedObjects }) => {
    if (["valueValue"].includes(property.key)) {
      return useValueMenu({ property, object, linkedObjects });
    }

    if (["valueChart1", "valueChart2", "valueChart3"].includes(property.key)) {
      return useChartValueMenu({ property, object });
    }

    if (["valueAction"].includes(property.key)) {
      return useValueActionMenu({ property, object });
    }

    if (["valueText"].includes(property.key)) {
      return useValueTextMenu({ property, object });
    }

    return useDefaultPropertyMenu({ property, object });
  };

  return { getPropertyMenu };
}
