import EditIcon from "@mui/icons-material/Edit";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import HistoryIcon from "@mui/icons-material/History";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import { ListItemSecondaryAction } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { msg } from "../../../messages";
import { setSettings } from "../../../store/settingsSlice";
import FunctionIcon from "../../icons/functionIcon";
import IdentifierIcon from "../../icons/IdentifierIcon";
import PercentIcon from "../../icons/percentIcon";
import useMoreMenu from "../../useMoreMenu";
import HistoryProperty from "../history/HistoryProperty";
import useHandleCopy from "../menu/handlers/useHandleCopy";

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: "15px",
    marginBottom: "10px",
  },
  itemToHideOrShow: {
    visibility: "hidden",
    opacity: 1,
    transition: "opacity 0.2s linear",
    [theme.breakpoints.down("sm")]: {
      visibility: "visible",
      opacity: 1,
    },
  },
  itemToHover: {
    "&:hover $itemToHideOrShow": {
      visibility: "visible",
      opacity: 1,
      transition: "opacity 0.2s linear",
    },
  },
}));

const TabGeneralCounterStatistic = (props) => {
  const { item } = props;
  const dispatch = useDispatch();

  const handleCopy = useHandleCopy();
  const [idProperty, setIdProperty] = useState(null);
  const isShowHistoryProperty = useSelector(
    (state) => state.settings.isShowHistoryProperty
  );

  const classes = useStyles({
    listItem: {
      minHeight: "56px",
    },
  });

  const {
    MoreMenu: ValueMenu,
    openMoreMenu: openValueMenu,
    closeMoreMenu: closeValueMenu,
  } = useMoreMenu();

  const getValue = (value) => {
    if (value === null) return "n/a";
    return value;
  };

  const valueProperty = () => {
    return item.objectProperties.find((item) => item.key === "valueValue");
  };

  const valuePercentageProperty = () => {
    return item.objectProperties.find((item) => item.key === "valuePercentage");
  };

  return (
    <>
      {isShowHistoryProperty && (
        <HistoryProperty id={idProperty} object={props.item} />
      )}
      <ValueMenu
        items={[
          {
            icon: <HistoryIcon />,
            title: "Show history",
            id: "show_history",
            handleAction: (obj) => {
              setIdProperty(obj.propId);
              dispatch(setSettings({ isShowHistoryProperty: true }));
            },
            disabled: false,
          },
          {
            icon: <FilterNoneIcon />,
            title: msg.default.copy,
            id: "copy",
            handleAction: (obj) => {
              handleCopy({
                text: JSON.stringify(obj.propValue),
                message: "Value copied",
              });
            },
            disabled: false,
          },
        ]}
      />

      <List>
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Value</Typography>
        </ListSubheader>

        <ListItem
          style={{ height: "48px" }}
          classes={{ container: classes.itemToHover }}
          button
          onClick={() => {}}
        >
          <ListItemIcon>
            <FunctionIcon />
          </ListItemIcon>
          <ListItemText
            onClick={(e) => {
              handleCopy({
                text: item.id,
                message: "Copied",
              });
            }}
            primary={
              <Typography variant="body1">
                {getValue(
                  item.objectProperties.find(
                    (item) => item.key === "valueValue"
                  ).value
                )}
              </Typography>
            }
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: valueProperty().key,
                  propValue: JSON.stringify(valueProperty().value),
                  propId: valueProperty().id,
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          classes={{
            container: classes.itemToHover,
          }}
          style={{ height: "48px" }}
          button
          onClick={() => {}}
        >
          <ListItemIcon>
            <PercentIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {getValue(
                  item.objectProperties.find(
                    (item) => item.key === "valuePercentage"
                  ).value
                )}
              </Typography>
            }
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: valuePercentageProperty().key,
                  propValue: JSON.stringify(valuePercentageProperty().value),
                  propId: valuePercentageProperty().id,
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">
            {msg.sideCard.serviceInfo}
          </Typography>
        </ListSubheader>

        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <SubtitlesIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">{item.schema.name}</Typography>
            }
            onClick={(e) => {}}
          />
        </ListItem>
        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <IdentifierIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">{item.id}</Typography>}
          />
        </ListItem>

        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">{msg.sideCard.access}</Typography>
        </ListSubheader>
        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {item.userGroupByEditorgroup.groupName}
              </Typography>
            }
          />
        </ListItem>

        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <PlayCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {item.userGroupByUsergroup.groupName}
              </Typography>
            }
          />
        </ListItem>

        <ListItem button onClick={() => {}}>
          <ListItemIcon>
            <RemoveRedEyeIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {item.userGroupByReadergroup.groupName}
              </Typography>
            }
          />
        </ListItem>

        {item.description && (
          <React.Fragment>
            <ListSubheader color="primary" className={classes.listSubheader}>
              <Typography variant="subtitle2">
                {msg.sideCard.description}
              </Typography>
            </ListSubheader>
            <ListItem button onClick={() => {}}>
              <ListItemText
                primary={
                  <Typography variant="body1">{item.description}</Typography>
                }
              />
            </ListItem>
          </React.Fragment>
        )}
      </List>
    </>
  );
};

export default TabGeneralCounterStatistic;
