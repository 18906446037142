import { useApolloClient } from "@apollo/client";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { NOTIFICATIONS_QUERY } from "../../queries";
import LinearProgress from "@mui/material/LinearProgress";

const TabContentNotifications = (props) => {
  const { id, item } = props;

  const theme = useTheme();

  const client = useApolloClient();

  const [notifications, setNotifications] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setNotifications([]);
    setLoading(true);
    const query = async () => {
      try {
        const result = await client.query({
          query: NOTIFICATIONS_QUERY,
          variables: { objId: item.id },
          fetchPolicy: 'network-only'
        });

        setNotifications(result.data.notifications);
      } catch (err) {
      }
      setLoading(false)
    };
    query();
  }, [id]);

  const icon = (notification) => {
    if (notification.tags.find((obj) => obj === "triggered")) {
      return <NotificationsActiveIcon style={{ color: theme.palette.red }} />;
    } else if (notification.tags.find((obj) => obj === "alert")) {
      return <NotificationsActiveIcon />;
    } else if (notification.tags.find((obj) => obj === "notice")) {
      return <VolumeUpIcon />;
    } else if (notification.tags.find((obj) => obj === "message")) {
      return <MessageIcon />;
    }
  };

  return (
    <>
      <div style={{ position: "absolute", width: "100%", top: 0 }}>
        {isLoading && <LinearProgress style={{ width: "100%" }} />}
      </div>
      <List>
        { (!notifications.length && !isLoading) && <>
          <Typography style={{ textAlign: 'center', paddingTop: '10px' }} variant="body1"> No data</Typography>
        </>}
        {notifications.map((notification) => (
          <ListItem alignItems="center" button key={notification.id}>
            <ListItemIcon>{icon(notification)}</ListItemIcon>
            <ListItemText
              onClick={() => {
              }}
              primary={
                <Typography variant="body1">{notification.message}</Typography>
              }
              secondary={
                format(
                  parseISO(notification.createdAt),
                  "MMM d, hh:mm:ss a"
                ).toString() +
                " by " +
                notification.userByBy.login
              }
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default TabContentNotifications;
