import { useApolloClient, useMutation } from "@apollo/client";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import { loader } from "graphql.macro";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import { useDispatch } from "react-redux";
import CommonModal from "../components/CommonModal";
import FormField from "../components/FormField";
import { msg } from "../messages";
import { OBJECT_PROP_QUERY } from "../queries";
const UPDATE_OBJECT_PROPERTY = loader(
  "../graphql/UpdateObjectWithProperties.graphql"
);

const styles = (theme) => ({
  stateHeadline: {
    marginTop: "15px",
  },
});

const EditWidgetPropertyModal = (props) => {
  const client = useApolloClient();
  const useStyles = makeStyles((theme) => styles(theme));

  const [updateProperty] = useMutation(UPDATE_OBJECT_PROPERTY);

  const [name, setName] = useState("");
  const [values, setValues] = useState([]);
  const [key, setKey] = useState("");
  const [field, setField] = useState(null);

  const [buttonSaveDisabled, setButtonSaveDisabled] = useState(false);

  useEffect(() => {
    const queryProps = async () => {
      try {
        const result = await client.query({
          query: OBJECT_PROP_QUERY,
          variables: {
            propId: props.propId,
          },
          fetchPolicy: "network-only",
        });

        //
        setName(result.data.objectProperty.property);
        setValues({
          [result.data.objectProperty.key]:
            result.data.objectProperty.type === "json object"
              ? JSON.stringify(result.data.objectProperty.value)
              : result.data.objectProperty.value,
        });

        setKey(result.data.objectProperty.key);
        setField({
          type: {
            name: result.data.objectProperty.type,
          },
          description: result.data.objectProperty.property,
          key: result.data.objectProperty.key,
          valueSet: {
            component: result.data.objectProperty.spec.valueSet?.component,
            list: result.data.objectProperty.spec.valueSet?.list,
            filter: result.data.objectProperty.spec.valueSet?.filter,
          },
        });
      } catch (err) {
        toast.error(err.toString(), {
          position: "bottom-center",
        });
      } finally {
      }
    }; //queryProps

    queryProps();
  }, []);

  const dispatch = useDispatch();

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleUpdate = (valuesReady) => {
    toast
      .promise(
        updateProperty({
          variables: {
            input: {
              detailedObject: [
                { objectId: props.widgetId, keyedProperties: valuesReady },
              ],
            },
          },
        }),
        {
          loading: "Updating...",
          success: () => "Updated",
          error: (err) => `${err.toString()}`,
        },
        {
          position: "bottom-center",
        }
      )
      .then(() => {
        submit();
      });
  };

  if (!field) return false;

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={msg.editWidgetPropertyModal.editProperty}
        handleClose={handleClose}
        buttons={
          <>
            <Button onClick={handleClose}>
              {msg.editWidgetPropertyModal.buttonCancel}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                handleUpdate([{ propertyKey: key, value: values[key] }]);
              }}
            >
              {msg.editWidgetPropertyModal.buttonSave}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <FormField
            key={field.key}
            values={values}
            field={field}
            handleInputChange={(e) => {
              let { name, value, checked } = e.target;
              if (checked) value = checked;
              //
              setValues({ [name]: value });
            }}
            widgetId={props.widgetId}
            propId={props.propId}
            setButtonSaveDisabled={setButtonSaveDisabled}
          />
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(EditWidgetPropertyModal);
