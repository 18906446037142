export function defineInitialValue(typeOfObject: string) {
  switch (typeOfObject) {
    case "json array":
      return [];
    case "bool":
      return false;
    case "string":
      return "";
    case "json object":
      return {};
  }
}
