import { TYPES } from "../constants";
import { WIDGETS_ENUM } from "./widgetTypes";

export function isCounter(tags) {
  if (tags.includes("statistics") && tags.includes("counter")) {
    return TYPES.COUNTER;
  }
}

export function isCalculator(tags) {
  if (tags.includes("statistics") && tags.includes("calculator")) {
    return TYPES.COUNTER;
  }
}

export function isTimeseries(tags) {
  if (tags.includes("statistics") && tags.includes("timeseries")) {
    return TYPES.TIMESERIES;
  }
}

export function isStatistics(tags) {
  if (tags.includes("statistics")) {
    return TYPES.STATISTICS;
  }
}

export function isGroup(tags) {
  if (tags.includes("group")) {
    return TYPES.GROUP;
  }
}

export function isWidget(tags) {
  if (tags.includes("widget")) {
    return TYPES.WIDGET;
  }
}

export function isMonitoringItem(tags) {
  if (tags.includes("object monitoring item")) {
    return TYPES.MONITORING_ITEM;
  }
}

export function isMonitoringObject(tags) {
  if (tags.includes("monitor")) {
    return TYPES.MONITORING_ITEM;
  }
}

export function isHistoryTable(tags) {
  if (tags.includes(WIDGETS_ENUM.HISTORY_TABLE)) {
    return WIDGETS_ENUM.HISTORY_TABLE;
  }
}

export function isAdvancedButton(tags) {
  if (tags.includes(WIDGETS_ENUM.ADVANCED_BUTTON)) {
    return WIDGETS_ENUM.ADVANCED_BUTTON;
  }
}

export function isMonitorTable(tags) {
  if (tags.includes(WIDGETS_ENUM.MONITOR_TABLE)) {
    return WIDGETS_ENUM.MONITOR_TABLE;
  }
}

export function isMonitorStatusTable(tags) {
  if (tags.includes(WIDGETS_ENUM.MONITOR_STATUS)) {
    return WIDGETS_ENUM.MONITOR_STATUS;
  }
}

export function isStaticTable(tags) {
  if (tags.includes(WIDGETS_ENUM.STATIC_TABLE)) {
    return WIDGETS_ENUM.STATIC_TABLE;
  }
}

export function isChart(tags) {
  if (tags.includes(WIDGETS_ENUM.DATACHART)) {
    return WIDGETS_ENUM.DATACHART;
  }
}

export function isColorChart(tags) {
  if (tags.includes(WIDGETS_ENUM.DATACHART_COLOR)) {
    return WIDGETS_ENUM.DATACHART_COLOR;
  }
}

export function isTrackingBox(tags) {
  if (tags.includes(WIDGETS_ENUM.GEO_TIMER)) {
    return WIDGETS_ENUM.GEO_TIMER;
  }
}

export function isDashboard(tags) {
  if (tags.includes(TYPES.DASHBOARD)) {
    return TYPES.DASHBOARD;
  }
}

export function isCollection(tags) {
  if (tags.includes(TYPES.COLLECTION)) {
    return TYPES.COLLECTION;
  }
}

export function isTimer(tags) {
  if (tags.includes(WIDGETS_ENUM.TIMER)) {
    return WIDGETS_ENUM.TIMER;
  }
}

export function getObjectType(tags, linkId) {
  if (isCounter(tags)) {
    return TYPES.COUNTER;
  }
  if (isCalculator(tags)) {
    return TYPES.CALCULATOR;
  } else if (isTimeseries(tags)) {
    return TYPES.TIMESERIES;
  } else if (isDashboard(tags)) {
    return TYPES.DASHBOARD;
  } else if (isCollection(tags)) {
    return TYPES.COLLECTION;
  } else if (isWidget(tags)) {
    return TYPES.WIDGET;
  } else if (isGroup(tags)) {
    return TYPES.GROUP;
  } else {
    return TYPES.OBJECT;
  }
}
