import { createSvgIcon } from "@mui/material";

const splitView = createSvgIcon(
  <path
    d="M13,5H21V19H13V5M3,5H11V7H3V5M3,11V9H11V11H3M3,19V17H11V19H3M3,15V13H11V15H3Z"
    fill="currentColor"
  />,
  "splitView"
);

export default splitView;
