import { lazy, Suspense } from "react";
import { WIDGETS_ENUM } from "../../../utils/widgetTypes";

type TProps = {
  id?: string;
  name?: string;
  title?: string;
  objectProperties?: { key: string; value: string }[];
  selected?: boolean;
  object: {
    schemaTags: string[];
  };
  [key: string]: unknown;
};

//   case "title":
//     w = (
//       <W_Title
//         object={widget}
//         key={widget.id}
//         widgetType="title"
//         {...widget}
//         reportId={reportId}
//         groupNames={[{ groupName: { equalTo: "Settings" } }, { groupName: { equalTo: "Value" } }]}
//         groupId={group.id}
//         order={group.order}
//         setDeleteWidgetModalState={setDeleteWidgetModalState}
//         setAddWidgetModalState={setAddWidgetModalState}
//         setEditWidgetModalState={setEditWidgetModalState}
//         selected={false}
//         saveBoard={saveBoard}
//         handleUpdateWidget={handleUpdateWidget}
//         handleClickWidget={handleClickWidget}
//       />
//     );
//     break;

const components = {
  [WIDGETS_ENUM.TITLE]: lazy(() => import("../../../components/widgets/title/W_Title")),
  [WIDGETS_ENUM.ADVANCED_BUTTON]: lazy(() => import("../../../components/widgets/W_AdvancedButton")),
  [WIDGETS_ENUM.COMMAND_BUTTON]: lazy(() => import("../../../components/widgets/W_CommandButton")),
  [WIDGETS_ENUM.DATABOX]: lazy(() => import("../../../components/widgets/W_DataBox")),
  [WIDGETS_ENUM.TIMER]: lazy(() => import("../../../components/widgets/W_Timer")),
  [WIDGETS_ENUM.DATETIME]: lazy(() => import("../../../components/widgets/W_DateTime")),
  [WIDGETS_ENUM.HISTORY_TABLE]: lazy(() => import("../../../components/widgets/W_HistoryTable")),
  [WIDGETS_ENUM.STATIC_TABLE]: lazy(() => import("../../../components/widgets/W_StaticTable")),
  [WIDGETS_ENUM.MONITOR_TABLE]: lazy(() => import("../../../components/widgets/W_MonitorTable")),
  [WIDGETS_ENUM.MONITOR_STATUS]: lazy(() => import("../../../components/widgets/W_MonitorStatusTable")),
  [WIDGETS_ENUM.DATACHART]: lazy(() => import("../../../components/widgets/chart/W_DataChart")),
  [WIDGETS_ENUM.DIAGRAM]: lazy(() => import("../../../components/widgets/W_DiagramChart")),
  [WIDGETS_ENUM.GEO_TIMER]: lazy(() => import("../../../components/widgets/geo-timer/GeoTimer")),
};

const WidgetWrap = (props: TProps) => {
  const WidgetComponent = components[props.object.schemaTags[3]];

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <WidgetComponent {...props} />
    </Suspense>
  );
};

export default WidgetWrap;
