import { useApolloClient } from "@apollo/client";
import { useDispatch } from "react-redux";
import {
  ACCESS_TOKEN_MUTATION,
  REFRESH_TOKEN_MUTATION,
  USERID_QUERY,
  USER_QUERY,
} from "../queries";
import { updateProfile } from "../store/profileSlice";
import { updateUser } from "../store/userSlice";

export const useAuth = () => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const getUserId = async () => {
    const result_userid = await client.query({
      query: USERID_QUERY,
      variables: {},
      fetchPolicy: "network-only",
    });

    return result_userid?.data.getUserId;
  };

  const loadUser = async () => {
    const id = await getUserId();

    const result_user = await client.query({
      query: USER_QUERY,
      variables: {
        id: id,
      },
      fetchPolicy: "network-only",
    });

    dispatch(updateUser(result_user.data.user));

    const profile = { id: result_user.data.user.userProfiles[0].profile.id };

    result_user.data.user.userProfiles[0].profile.objectProperties.forEach(
      (prop) => {
        profile[prop.key] = prop.value;
      }
    );

    dispatch(updateProfile(profile));
  }; //loadUser

  const loginFromApp = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    try {
      const result_access = await client.mutate({
        mutation: ACCESS_TOKEN_MUTATION,
        variables: {
          input: {
            userRefreshToken: refreshToken,
            accessTokenExpiration: 24 * 60,
            profileTags: ["application", "board", "user profile"],
          },
        },
        fetchPolicy: "no-cache",
      });

      const jwt = result_access.data.authAccessToken.jwtToken;

      if (jwt) {
        localStorage.setItem("authToken", jwt);
        await loadUser();
      } else {
        dispatch(updateUser(null));
        return Promise.reject(new Error("User does not exist"));
      }
    } catch (err) {
      dispatch(updateUser(null));
      return Promise.reject(new Error("Authorization failed"));
    } //catch
  }; //loginFromApp

  const logout = async () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("tokenId");
    localStorage.removeItem("linkToViewBoardId");
  };

  const login = async (input) => {
    const result_refresh = await client.mutate({
      mutation: REFRESH_TOKEN_MUTATION,
      variables: {
        input: input,
      },
    });

    const userRefreshToken =
      result_refresh.data.authRefreshToken.refreshToken.token;
    const tokenId = result_refresh.data.authRefreshToken.refreshToken.id;

    localStorage.setItem("tokenId", tokenId);
    localStorage.setItem("refreshToken", userRefreshToken);

    const result_access = await client.mutate({
      mutation: ACCESS_TOKEN_MUTATION,
      variables: {
        input: {
          userRefreshToken: userRefreshToken,
          accessTokenExpiration: 24 * 60,
          //            profileTags: ['admin'],
          profileTags: ["application", "board", "user profile"],
        },
      },
      fetchPolicy: "no-cache",
    });

    const jwt = result_access.data.authAccessToken.jwtToken;
    if (jwt) {
      localStorage.setItem("authToken", jwt);
    } else {
      return Promise.reject(new Error("User does not exist"));
    }

    await loadUser();
  }; //login

  return { login, logout, loginFromApp, loadUser, getUserId };
};
