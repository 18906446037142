import WebSocketCustomLink from "./WebSocketCustomLink";

export const wsLink = new WebSocketCustomLink({
  url: window.__pixelConfig.WS_API,
  on: {
    opened: (socket) => {},
    connected: () => {},
    connecting: () => {},
    closed: () => {},
    message: (msg) => {},
    ping: (msg) => {},
    pong: (msg) => {},
    error: (err) => {
      console.error(err);
    },
  },

  connectionParams: () => ({
    authorization: `Bearer ${localStorage.getItem("authToken")}`,
  }),
});
