import Grid from "@mui/material/Grid";
import DateRangePicker from "../../../../components/common/DateRangePicker";
import CustomInput from "../../../../components/CustomInput";

const DateIntervalItem = (props) => {
  return (
    <>
      <Grid item>
        <CustomInput
          name="name"
          label="Name"
          clearFieldIcon={true}
          value={props.item.title}
          onChange={props.onChangeName}
        />
      </Grid>

      <DateRangePicker helperText={props.helperText} selected={props.item.value} onChange={props.onChangeRange} />
    </>
  );
};

export default DateIntervalItem;
