import { gql, useMutation } from "@apollo/client";
import { Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import { cloneDeep } from "lodash";
import { useEffect, useReducer, useState } from "react";
import { create } from "react-modal-promise";
import { msg } from "../../messages";
import CommonModal from "../CommonModal";
import CustomAutocomplete from "../CustomAutocomplete";
import CustomSelect from "../CustomSelect";
import FormField from "../FormField";

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const ConditionModal = ({
  isEdit,
  properties,
  condition,
  conditionProperty,
  conditionIndex,
  schemaRawProperties,
  onResolve,
  onReject,
  isOpen,
}) => {
  const [value, setValue] = useState(condition?.value);
  const defaultValues = {};
  const [operator, setOperator] = useState(condition?.operator || "");
  const [property, setProperty] = useState({
    value: condition?.property || "",
    rawValue: {
      value: condition?.property || "",
      valueSet: null,
      valueRange: null,
      type: {
        name: "string",
      },
      ...schemaRawProperties.find((prop) => {
        const groupNameInner = condition?.property.split("/")[0];
        const propertyNameInner = condition?.property.split("/")[1];
        if (
          prop.groupName === groupNameInner &&
          prop.property === propertyNameInner
        ) {
          return true;
        }
      }),
      description: "Value",
    },
  });

  const [updateProperty, { loading }] = useMutation(UPDATE_PROPERTY);
  const [values, setValues] = useReducer(
    (prev, updated) => ({ ...prev, ...updated }),
    defaultValues
  );

  const submit = () => onResolve();

  const reject = () => onReject();

  const getProp = () => {
    if (property.value) {
      const groupNameInner = property.value.split("/")[0];
      const propertyNameInner = property.value.split("/")[1];

      return schemaRawProperties.find((prop) => {
        if (
          prop.groupName === groupNameInner &&
          prop.property === propertyNameInner
        ) {
          return true;
        }
      });
    }
  };

  useEffect(() => {
    if (isEdit && getProp()) {
      setValues({ [getProp().key]: value });
    }
  }, [conditionProperty]);

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;
    if (checked) value = checked;

    setValue(value);
    setValues({ [name]: value });
  };

  const handleEditProperty = (value) => {
    updateProperty({
      variables: {
        input: {
          id: conditionProperty.id,
          patch: {
            value: value,
          },
        },
      },
    }).then(() => {
      submit();
    });
  };
  return (
    <CommonModal
      key="ConditionModal"
      modalOpen={isOpen}
      title={isEdit ? "Edit condition" : "Add condition"}
      handleClose={reject}
      buttons={
        <>
          <Button onClick={reject}>{msg.default.cancel}</Button>
          <Button
            color="primary"
            onClick={async () => {
              const oldValues = cloneDeep(conditionProperty.value.conditions);

              if (isEdit) {
                oldValues.splice(conditionIndex, 1, {
                  operator,
                  property: property.value,
                  value,
                });
                let patch = {
                  ...conditionProperty.value,
                  filtering: true,
                  conditions: [...oldValues],
                };
                handleEditProperty(patch);
              } else {
                oldValues.push({
                  operator,
                  property: property.value,
                  value,
                });
                let patch = {
                  ...conditionProperty.value,
                  filtering: true,
                  conditions: [...oldValues],
                };
                handleEditProperty(patch);
              }
            }}
          >
            {loading ? (
              <CircularProgress size={23} />
            ) : isEdit ? (
              msg.default.save
            ) : (
              msg.default.add
            )}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <CustomAutocomplete
            name="schemaProperties"
            label="Property"
            list={properties}
            value={property.value}
            onChange={(e) => {
              setProperty({
                ...e.target,
                rawValue: {
                  value: condition?.property || "",
                  valueSet: null,
                  valueRange: null,
                  type: {
                    name: "string",
                  },
                  ...e.target.rawValue,
                  description: "Value",
                },
              });
            }}
          />
        </Grid>
        <Grid item>
          <CustomSelect
            name="operator"
            label="Sign"
            list={[
              { value: ">", title: ">" },
              { value: "<", title: "<" },
              { value: "=", title: "=" },
              { value: "==", title: "==" },
              { value: "!=", title: "!=" },
            ]}
            value={operator}
            onChange={(e) => {
              setOperator(e.target.value);
            }}
          />
        </Grid>
        {property.rawValue && (
          <Grid item>
            <FormField
              disabled={Boolean(!property.rawValue?.key)}
              values={values}
              field={property.rawValue}
              handleInputChange={handleInputChange}
            />
          </Grid>
        )}
      </Grid>
    </CommonModal>
  );
};

export default create(ConditionModal);
