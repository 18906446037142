import { Button } from "@mui/material";
import { create } from "react-modal-promise";
import { useDispatch } from "react-redux";
import { msg } from "../../messages";
import { setSettings } from "../../store/settingsSlice";
import ConfirmationModal from "../modals/ConfirmationModal";

const ConfirmExitEditMode = (props) => {
  const dispatch = useDispatch();

  const submit = () => props.onResolve();

  const reject = () => props.onReject();

  const handleClose = () => {
    dispatch(setSettings({ isEditMode: false }));
    reject();
  };

  const handleSave = () => {
    props.handleSave();
    dispatch(setSettings({ isEditMode: false }));
    submit();
  };

  return (
    <ConfirmationModal
      modalOpen={props.isOpen}
      title={msg.deleteGroupsModal.deleteGroups}
      handleClose={handleClose}
      buttons={
        <>
          <Button onClick={handleClose}>Exit</Button>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
        </>
      }
    >
      Save the latest changes or exit without saving?
    </ConfirmationModal>
  );
};

export default create(ConfirmExitEditMode);
