import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import CustomDate from "../components/CustomDate";
import CustomInput from "../components/CustomInput";
import CustomObjectProperty from "../components/CustomObjectProperty";
import CustomSchemaProperty from "../components/CustomSchemaProperty";
import CustomSelect from "../components/CustomSelect";
import CustomSelectIcon from "../components/CustomSelectIcon";
import CustomSwitch from "../components/CustomSwitch";
import SelectColor from "./forms/SelectColor";

const FormField = (props) => {
  const { values, field, handleInputChange } = props;

  const useStyles = makeStyles((theme) => ({
    obsoleted: {},
  }));

  const classes = useStyles();

  if (field?.valueSet?.component) {
    switch (field.valueSet.component) {
      case "select":
        return (
          <Grid item key={field.key}>
            <CustomSelect
              name={field.key}
              label={
                field.description + (!field.description ? `(${field.key})` : "")
              }
              list={field.valueSet.list.map((item) => {
                return { title: item.title, value: item.key };
              })}
              value={values[field.key] ?? ""}
              propType={field?.type?.name}
              onChange={handleInputChange}
              disabled={Boolean(props.disabled)}
            />
          </Grid>
        );
        break;

      case "selecticon":
        return (
          <Grid item key={field.key}>
            <CustomSelectIcon
              name={field.key}
              label={
                field.description + (!field.description ? `(${field.key})` : "")
              }
              value={values[field.key] ?? ""}
              filter={field.valueSet.filter}
              propType={field?.type?.name}
              onChange={handleInputChange}
              disabled={Boolean(props.disabled)}
            />
          </Grid>
        );
        break;

      case "selectop":
        return (
          <Grid item key={field.key}>
            <CustomObjectProperty
              name={field.key}
              label={
                field.description + (!field.description ? `(${field.key})` : "")
              }
              value={values[field.key] ?? ""}
              propType={field?.type?.name}
              onChange={handleInputChange}
              widgetId={props.widgetId}
              propId={props.propId}
              setButtonSaveDisabled={props.setButtonSaveDisabled}
              disabled={Boolean(props.disabled)}
            />
          </Grid>
        );
        break;

      case "selectsp":
        return (
          <Grid item key={field.key}>
            <CustomSchemaProperty
              name={field.key}
              label={
                field.description + (!field.description ? `(${field.key})` : "")
              }
              value={values[field.key] ?? ""}
              propType={field?.type?.name}
              onChange={handleInputChange}
              widgetId={props.widgetId}
              propId={props.propId}
              setButtonSaveDisabled={props.setButtonSaveDisabled}
              disabled={Boolean(props.disabled)}
            />
          </Grid>
        );
        break;

      case "date":
        return (
          <Grid item key={field.key}>
            {/*<MobileDatePicker*/}
            {/*  disableFuture*/}
            {/*  renderInput={(props) => (*/}
            {/*    <TextField*/}
            {/*      style={{ width: "100%" }}*/}
            {/*      helperText={null}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*  name={field.key}*/}
            {/*  format="yyyy-MM-dd"*/}
            {/*  variant="inline"*/}
            {/*  value={values[field.key] ?? ""}*/}
            {/*  onChange={handleInputChange}*/}
            {/*/>*/}
            <CustomDate
              name={field.key}
              label={
                field.description + (!field.description ? `(${field.key})` : "")
              }
              value={values[field.key] ?? ""}
              propType={field?.type?.name}
              onChange={handleInputChange}
              widgetId={props.widgetId}
              propId={props.propId}
              setButtonSaveDisabled={props.setButtonSaveDisabled}
              disabled={Boolean(props.disabled)}
            />
          </Grid>
        );
        break;

      case "select_color":
        return (
          <Grid item key={field.key}>
            <SelectColor
              name={field.key}
              label={
                field.description + (!field.description ? `(${field.key})` : "")
              }
              list={field.valueSet.list.map((item) => {
                return { ...item, title: item.title, value: item.key };
              })}
              value={values[field.key] ?? ""}
              propType={field?.type?.name}
              onChange={handleInputChange}
              disabled={Boolean(props.disabled)}
            ></SelectColor>
          </Grid>
        );

        break;

      case "text":
        return (
          <Grid item key={field.key}>
            <CustomInput
              name={field.key}
              label={
                field.description + (!field.description ? `(${field.key})` : "")
              }
              clearFieldIcon={true}
              value={values[field.key] ?? ""}
              propType={field?.type?.name}
              onChange={handleInputChange}
              disabled={Boolean(props.disabled)}
            />
          </Grid>
        );
        break;
      case "number":
        return (
          <Grid item key={field.key}>
            <CustomInput
              name={field.key}
              label={
                field.description + (!field.description ? `(${field.key})` : "")
              }
              clearFieldIcon={true}
              value={values[field.key] ?? ""}
              onChange={handleInputChange}
              type="number"
              propType={field?.type?.name}
              min={field.valueRange?.min}
              max={field.valueRange?.max}
              disabled={Boolean(props.disabled)}
            />
          </Grid>
        );
        break;
      case "textmulti":
        return (
          <Grid item key={field.key}>
            <CustomInput
              name={field.key}
              label={
                field.description + (!field.description ? `(${field.key})` : "")
              }
              clearFieldIcon={true}
              multiline
              rows={4}
              value={values[field.key] ?? ""}
              propType={field?.type?.name}
              onChange={handleInputChange}
              disabled={Boolean(props.disabled)}
            />
          </Grid>
        );
        break;
      case "switch":
        return (
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            key={field.key}
          >
            <CustomSwitch
              name={field.key}
              label={
                field.description + (!field.description ? `(${field.key})` : "")
              }
              value={values[field.key] ?? ""}
              propType={field?.type?.name}
              onChange={handleInputChange}
              disabled={Boolean(props.disabled)}
            />
          </Grid>
        );
        break;
      default:
    }
  } // if
  // old approach, based only on type.name (valueSet ignored)
  else {
    if (field?.type?.name === "bool")
      return (
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          key={field.key}
          className={classes.obsoleted}
        >
          <CustomSwitch
            name={field.key}
            label={
              field.description + (!field.description ? `(${field.key})` : "")
            }
            value={values[field.key] ?? ""}
            propType={field?.type?.name}
            onChange={handleInputChange}
          />
        </Grid>
      );
    else if (field?.type?.name === "string")
      return (
        <Grid item key={field.key} className={classes.obsoleted}>
          <CustomInput
            name={field.key}
            label={
              field.description + (!field.description ? `(${field.key})` : "")
            }
            clearFieldIcon={true}
            value={values[field.key] ?? ""}
            propType={field?.type?.name}
            disabled={Boolean(props.disabled)}
            onChange={handleInputChange}
          />
        </Grid>
      );
    //TODO: replace with powerful json editor instead of simple string editor
    else if (field?.type?.name === "json object")
      return (
        <Grid item key={field.key} className={classes.obsoleted}>
          <CustomInput
            name={field.key}
            label={
              field.description + (!field.description ? `(${field.key})` : "")
            }
            clearFieldIcon={true}
            value={values[field.key] ?? ""}
            propType={field?.type?.name}
            onChange={handleInputChange}
            disabled={Boolean(props.disabled)}
          />
        </Grid>
      );
    else if (field?.type?.name === "number")
      return (
        <Grid item key={field.key} className={classes.obsoleted}>
          <CustomInput
            name={field.key}
            label={
              field.description + (!field.description ? `(${field.key})` : "")
            }
            clearFieldIcon={true}
            value={values[field.key] ?? ""}
            type="number"
            propType={field?.type?.name}
            min={field.valueRange?.min}
            max={field.valueRange?.max}
            onChange={handleInputChange}
            disabled={Boolean(props.disabled)}
          />
        </Grid>
      );
    else {
    }
  } // else (old approach)
};

export default React.memo(FormField);
