export function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

export function onChangeDatePicker(date, setState) {
  if (isValidDate(date)) {
    setState(date);
  } else {
    setState("");
  }
}

export const HHmm = "HH:mm";
export const YYYYMMdd = "yyyy-MM-dd";
