// Usage: import { formatValue } from '../utils/misc.js';

export const castToType = (value, type) => {
  switch (type) {
    case "number":
      return Number(value);
    case "bool":
      return Boolean(value);
    default:
      if (value === null) {
        return null;
      } else {
        return String(value);
      }
  }
};

export const formatValue = (value) => {
  if (value === null) return "n/a";

  if (typeof value === "object") return JSON.stringify(value);

  return value;
};
