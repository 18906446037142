// eslint-disable-next-line import/no-extraneous-dependencies
import { parse, stringify } from "qs";

import { To, useNavigate, useSearchParams } from "react-router-dom";

const useCustomNavigate = () => {
  const history = useNavigate();
  const [searchParams] = useSearchParams();

  return (path: To, query = {}): void => {
    const localQuery = { ...parse(searchParams.toString()), ...parse(query) };

    if (!localQuery.token) {
      delete localQuery.mode;
    }

    delete localQuery.token;

    if (stringify(localQuery)) {
      return history(`${path}?${stringify(localQuery)}`);
    }

    return history(path);
  };
};

export default useCustomNavigate;
